import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { OfficeUpload } from "./OfficeUpload";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../shared/Utils";
import { WorkflowService } from "../shared/workflow.service";
import { Constants } from "../shared/Constants";
import { ApiService } from "../shared/api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-upload-doc",
  templateUrl: "./UploadFile.component.html",
  styleUrls: ["./UploadFile.component.css"],
})
export class UploadFileComponent implements OnInit {
  DocumentUploadStatus: string;
  DocumentUploadProgress: number = 0;
  uploadToolTip?: string;
  @ViewChild("uploadButton") uploadButton: ElementRef<HTMLButtonElement>;
  workflowOutputDocuments: { code: string }[] = [];
  UPLOAD_FILE_BUTTON = Constants.UPLOAD_FILE_BUTTON;
  PLACEHOLDER = Constants.NO_WORKFLOW_OUTPUT_DOC;
  form: FormGroup;
  gated: boolean = false;
  plansLink: string;
  manageTemplatesLink: string;
  uploadAttempted: boolean = false;

  get disabled() {
    return this.form?.invalid || this.gated;
  }

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService,
    private interview: WorkflowService,
    private fb: FormBuilder
  ) {
    this.buildForm();
    this.gated = this.interview.isOverTemplateLimit;
    const basePath = this.interview.loginPath.replace(/\/?$/, "/");
    this.plansLink = `${basePath}settings/billing`;
    this.manageTemplatesLink = `${basePath}files/templates`;
    this.uploadToolTip = this.gated ? undefined : Constants.UPLOAD_DOC_TOOLTIP;
    this.interview.workflowOutputDocuments.subscribe((outputDocs) => {
      this.workflowOutputDocuments = [];
      this.PLACEHOLDER =
        outputDocs.length == 0
          ? Constants.NO_WORKFLOW_OUTPUT_DOC
          : Constants.CHOOSE_WORKFLOW_OUTPUT_DOC;

      outputDocs.forEach((v, i) => {
        this.workflowOutputDocuments.push({ code: v });
      });
      /*this.selectedOutputDocument = this.workflowOutputDocuments[0];*/
    });
  }
  buildForm() {
    this.form = this.fb.group({
      selectedOutputDocument: this.fb.control(null, [Validators.required]),
    });
  }

  ngOnInit() {
    OfficeUpload.uploadStatus.subscribe((value) => {
      this.DocumentUploadStatus = value;
    });
    OfficeUpload.uploadProgress.subscribe((value) => {
      this.DocumentUploadProgress = value;
    });
  }

  uploadDocument() {
    OfficeUpload.uploadDocument(
      this.httpClient,
      this.apiService,
      this.form.controls["selectedOutputDocument"].value.code
    );
    Utils.blurButton(this.uploadButton);
  }
}
