import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SplashComponent } from "./splash/splash.component";
import { LoginComponent } from "./login/login.component";
import { EditorComponent } from "./editor/editor.component";
import { AppComponent } from "./app.component";

export const routes: Routes = [
  { path: "", component: AppComponent },
  { path: "splash", component: SplashComponent },
  { path: "login", component: LoginComponent },
  { path: "editor", component: EditorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
