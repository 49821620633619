import { Component, Input, OnInit } from "@angular/core";
import {
  WorkflowService,
  WorkflowServiceState,
} from "../shared/workflow.service";

import { ProgressBarMode } from "@angular/material/progress-bar";
@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.css"],
})
export class ProgressBarComponent implements OnInit {
  @Input() pbMode: ProgressBarMode;
  @Input() pbValue: number;

  constructor() {}

  ngOnInit(): void {}
}
