import { Constants } from "../../shared/Constants";
import { RegexConstants } from "../../shared/RegexConstants";

export class ParagraphCheck {
  static Check(paragraphs: Word.Paragraph[]): Map<Word.Paragraph, string[]> {
    let bad: Map<Word.Paragraph, string[]> = new Map<
      Word.Paragraph,
      string[]
    >();
    paragraphs.forEach((paragraph) => {
      //Check that it is on its own line (excluding whitespace)
      if (!RegexConstants.regexParaLineTest.test(paragraph.text)) {
        bad.set(paragraph, [Constants.PARAGRAPH_SYNTAX_ERROR]);
      }
    });

    return bad;
  }
}
