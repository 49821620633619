import { Component, Input } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { ComplexVariable } from "../../../shared/variableTypes/ComplexVariable";
import {
  ConditionalChild,
  ConditionalComparisonOption,
} from "../../models/conditional-child.model";
import { DropdownOption } from "../../../shared/Types";

@Component({
  selector: "app-conditional-multi-choice",
  templateUrl: "./conditional-multi-choice.component.html",
  styleUrls: ["./conditional-multi-choice.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConditionalMultiChoiceComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ConditionalMultiChoiceComponent,
    },
  ],
})
export class ConditionalMultiChoiceComponent
  implements ControlValueAccessor, Validator
{
  @Input() set selectedMultiChoiceVariable(newValue: ComplexVariable) {
    this.multipleChoiceItems = newValue.complexMembers;
    this.form.controls["SelectedMultipleChoice"].setValue(null);
  }

  public form: FormGroup;
  public onChange = (newValue) => {};
  public multipleChoiceItems: DropdownOption[];

  private conditionalChild: ConditionalChild = new ConditionalChild();
  constructor(private fb: FormBuilder) {
    this.buildForm();
    this.subscribeToFormValueChanges();
  }

  setDisabledState(isDisabled: boolean) {}

  registerOnTouched(fn: any) {}

  writeValue(obj: any) {}

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
    this.onChange(this.conditionalChild);
  }

  subscribeToFormValueChanges() {
    this.form.valueChanges.subscribe((newValue) => {
      this.updateOutput(newValue);
    });
  }

  updateOutput(newValue) {
    if (!newValue) return;
    this.conditionalChild.selectedMultipleChoice =
      newValue.SelectedMultipleChoice?.code;
    this.conditionalChild.optionGtLtEqValue =
      newValue.InputBoolean.optionGtLtEqValue;
    this.onChange(this.conditionalChild);
  }

  buildForm() {
    let conditionalChild = new ConditionalChild();
    conditionalChild.optionGtLtEqValue = ConditionalComparisonOption.is;
    this.form = this.fb.group(
      {
        SelectedMultipleChoice: this.fb.control(null),
        InputBoolean: this.fb.control(conditionalChild),
      },
      { validators: this.validateForm }
    );
  }

  validateForm(control: AbstractControl): ValidationErrors | null {
    let form = <FormGroup>control;
    if (
      form.controls["InputBoolean"].value &&
      form.controls["SelectedMultipleChoice"].value
    )
      return null;
    else return { FormContainsNull: true };
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    } else return { FormInvalid: true };
  }
}
