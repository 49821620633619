<p-dropdown
  #dropdown
  *ngIf="multiple == false"
  [placeholder]="placeholder"
  [options]="dropdownList"
  optionLabel="text"
  [formControl]="selectedDropdownOption"
  [editable]="customOptions"
  filter="true"
  [showClear]="clearable"
  [appendTo]="appendTo"
>
  <ng-template pTemplate="selectedItem">
    <div
      *ngIf="selectedDropdownOption"
      [pTooltip]="selectedDropdownOption.value?.text"
      tooltipPosition="top"
    >
      <div>
        {{ selectedDropdownOption.value?.text }}
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="item" let-variable>
    <div class="variable-item">
      <div>
        {{ variable.value.text }}
      </div>
    </div></ng-template
  >
</p-dropdown>

<p-multiSelect
  #multiSelect
  *ngIf="multiple == true"
  [options]="dropdownList"
  [formControl]="selectedDropdownOption"
  [defaultLabel]="placeholder"
  optionLabel="text"
  (onChange)="selectedMultiOptionChange($event)"
  filter="true"
  [appendTo]="appendTo"
>
  <ng-template pTemplate="selectedItem">
    <div
      *ngIf="selectedDropdownOption"
      [pTooltip]="selectedDropdownOption.value.text"
      tooltipPosition="top"
    >
      <div>
        {{ selectedDropdownOption.value.text }}
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="item" let-variable>
    <div class="variable-item">
      <div>
        {{ variable.value.text }}
      </div>
    </div></ng-template
  >
</p-multiSelect>
