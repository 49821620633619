import {
  ConditionalChild,
  ConditionalComparisonOption,
} from "../models/conditional-child.model";
import { ConditionalExpressionUtils } from "./ConditionalExpressionUtils";

export class ConditionalMultiSelectExpressionBuilder {
  public static Build(data: ConditionalChild): string {
    let expr;
    let var_expr = ConditionalExpressionUtils.VariableParser(
      data.selectedMultipleChoice.toString()
    );

    if (
      data.selectedMultipleChoice == "Blank" &&
      data.optionGtLtEqValue == ConditionalComparisonOption.is
    ) {
      expr = `${data.selectedWorkflowVariable.id} == []`;
    } else if (
      data.selectedMultipleChoice == "Blank" &&
      data.optionGtLtEqValue == ConditionalComparisonOption.false
    ) {
      expr = `${data.selectedWorkflowVariable.id} != []`;
    } else if (data.optionGtLtEqValue == ConditionalComparisonOption.is) {
      expr = `${data.selectedWorkflowVariable.id}[${var_expr}]`;
    } else {
      expr = `not ${data.selectedWorkflowVariable.id}[${var_expr}]`;
    }

    return expr;
  }
}
