import { Component, Inject } from "@angular/core";
import { SettingsService } from "../shared/settings.service";
import { ActivatedRoute, Router } from "@angular/router";
import { WorkflowService } from "../shared/workflow.service";
import { LoginSettings } from "../shared/LoginSettings";
import { Constants } from "../shared/Constants";
import { FormGroup } from "@angular/forms";
import { LoginViewModel } from "./LoginViewModel";
import { LoginSettingsModel } from "./LoginSettingsModel";
import { LoginResult } from "./LoginResult";
import { AuthorizationService } from "../shared/authorization.service";
import { FetchResult } from "../shared/FetchResult";
import { RollbarService } from "../shared/rollbarerrorhandler.service";
import Rollbar from "rollbar";
import {
  NotificationService,
  NotificationType,
} from "../shared/notification.service";
import { RegexConstants } from "../shared/RegexConstants";

/**
 * Handles logic for the Login page. Successful logins call the
 * /editor route.
 */
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent {
  loginViewModel: LoginViewModel;
  loginFormGroup: FormGroup;
  subDomainSuggestions: string[];

  /*
   * Used to retrieve saved login settings
   * and update saved login settings on a successful login.
   */
  loginSettings: LoginSettings;

  /**
   * For changing the CSS of
   * the notification that is displayed to the user.
   */
  alertMsgClass = "text-center bg-light-blue p-2";

  /**
   * Stores the help text for the API key.
   */
  apiKeyHelpMsg: string;

  /**
   * Flag for cancelling auto login when
   * user clicks cancel in the UI.
   */
  autoLoginCancelled: NodeJS.Timeout;

  /**
   * Flag for displaying the auto login message to
   * the user
   */
  hideAutoLoginMsg: boolean = true;

  /**
   * Message displayed to user when preparing to auto
   * login
   */

  autoLoginMsg: string = "";

  noAutoLogin: boolean = false;

  savedSettings: Map<string, LoginSettingsModel>;

  /**
   * @param workflowService
   * @param settings
   * @param router
   * @param route
   * @param authService
   * @param notificationService
   * @param rollbar
   */
  constructor(
    private workflowService: WorkflowService,
    private settings: SettingsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthorizationService,
    private notificationService: NotificationService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.notificationService.newNotification(
      Constants.LOGIN_NO_ACCOUNT,
      NotificationType.Info
    );
    this.route.queryParams.subscribe((params) => {
      console.debug(`login.componentparams`);
      if (params?.noAutoLogin) this.noAutoLogin = params?.noAutoLogin;
    });

    this.loginViewModel = new LoginViewModel();
    this.loginFormGroup = this.loginViewModel.FormGroup();

    this.savedSettings = new Map<string, LoginSettingsModel>();
    this.subDomainSuggestions = [];

    console.debug("Login view loaded, importing login settings...");
    this.migrateLoginSettings();
    this.importLoginSettings();
    if (!this.noAutoLogin) this.attemptLogin();
  }

  handleDropdown(event) {
    //event.query = current value in input field
    this.subDomainSuggestions = Array.from(this.savedSettings.keys());
  }

  refreshSettings(event) {
    let newSettings = this.savedSettings.get(event);
    this.loginViewModel.Settings = newSettings;
  }

  search(event) {
    if (event.query.trim() == "") {
      this.subDomainSuggestions = Array.from(this.savedSettings.keys());
      return;
    }
    this.subDomainSuggestions = Array.from(this.savedSettings.keys()).filter(
      (s) => {
        if (
          s
            .toLowerCase()
            .search(new RegExp(<string>event.query.toLowerCase())) != -1
        ) {
          return true;
        }
        return false;
      }
    );
  }

  migrateLoginSettings() {
    if (!this.settings.isAvailable()) {
      return;
    }

    if (this.settings.getSettings("__loginSettingsMigrated")?.migrated) {
      return;
    }

    /*This cancels the migration attempt if there are not LoginSettings to migrate
     * and does not attempt it again
     */
    if (this.settings.getSettings("LoginSettings") == null) {
      this.settings.setSettings("__loginSettingsMigrated", {
        migrated: true,
      });
      return;
    }
    let serverOptions = [
      { id: 0, text: "gavel.io" },
      { id: 1, text: "helpselflegal.com" },
      { id: 2, text: "documate.org" },
      { id: 3, text: "custom" },
    ];
    let savedSettings: LoginSettings;
    savedSettings = this.settings.getSettings("LoginSettings");

    let optionIndex =
      savedSettings?.serverOption == null ? 0 : savedSettings.serverOption;

    let newSavedSettings: LoginSettingsModel = new LoginSettingsModel(
      serverOptions[optionIndex].text,
      savedSettings.clientName,
      savedSettings.serverName,
      savedSettings.apiKey
    );

    this.settings.setSettings(Constants.LOGIN_SETTINGS_KEY, newSavedSettings);

    this.settings.setSettings("__loginSettingsMigrated", {
      migrated: true,
    });
    return;
  }

  /**
   * This method looks for saved login settings and populates
   * the data bound properties if any are found.
   */

  importLoginSettings() {
    if (!this.settings.isAvailable()) {
      return;
    }

    if (
      this.settings.getSettings(Constants.LOGIN_SETTINGS_MAP_KEY) !=
      Constants.SETTING_KEY_NOT_FOUND
    ) {
      this.savedSettings = new Map(
        this.settings.getSettings(Constants.LOGIN_SETTINGS_MAP_KEY)
      );

      this.loginViewModel.Settings = this.savedSettings.get(
        Array.from(this.savedSettings.keys())[0]
      );
      this.subDomainSuggestions = Array.from(this.savedSettings.keys());
    }
  }

  exportLoginSettings() {
    if (!this.settings.isAvailable()) {
      return;
    }

    /*
     * Update settings map by adding or updating domain
     * name, then persist the settings using the settings
     * service.
     */

    if (this.loginViewModel.Settings.selectedDomain !== "custom") {
      this.savedSettings.set(
        this.loginViewModel.Settings.subdomain,
        this.loginViewModel.Settings
      );
    } else {
      this.savedSettings.set(
        this.loginViewModel.Settings.customDomain,
        this.loginViewModel.Settings
      );
    }

    this.settings.setSettings(
      Constants.LOGIN_SETTINGS_MAP_KEY,
      Array.from(this.savedSettings)
    );

    this.settings.setSettings(
      Constants.LOGIN_SETTINGS_ACTIVE_KEY,
      this.loginViewModel.Settings
    );
  }

  /**
   * This function sets the text in the variable
   * that stores the text for the API key. Not
   * sure why it was done this way, could be
   * improved quite a bit.
   */
  showApiKeyHelpMsg() {
    this.apiKeyHelpMsg = Constants.LOGIN_APIKEY_HELPMSG;
  }

  /**
   * Attempts to log the user in. This is done by using
   * the server name and API key that the user enters to download
   * a list of interviews. Calls saveLoginSettings
   * followed by logUserIn on success, rejectAttempt on failure.
   */
  async attemptLogin() {
    let __ = this;

    this.setRollbarClientInfo();

    this.notificationService.newNotification(
      "Please wait, attempting to log you in...",
      NotificationType.Info
    );

    await __.authService
      .Login(
        this.loginViewModel?.LoginPath,
        this.loginViewModel?.Settings?.APIKey
      )
      .then((loginResult: FetchResult) => {
        this.workflowService.loginPath = this.loginViewModel?.LoginPath;
        console.info(`Logged into ${this.loginViewModel?.LoginPath}`);
        if ((<string[]>loginResult.value).length < 1) {
          /*Throw error, user has no workflows*/
          throw Error("No workflows");
        }

        this.notificationService.newNotification(
          "Welcome back, downloading your workflows...",
          NotificationType.Info
        );

        this.exportLoginSettings();
      })

      .then(() => this.workflowService.DownloadInitialWorkflow())
      .then((initialWorkflowResult) => {
        console.debug(Constants.INITIAL_WORKFLOW_SUCCESS);
        this.router.navigate(["/editor"], {
          queryParams: {
            selectedWorkflow: initialWorkflowResult.selectedWorkflow,
          },
        });
      })
      .catch((reason) => {
        this.rejectAttempt(reason);
      });
  }

  setRollbarClientInfo() {
    let path = this.loginViewModel?.LoginPath.toLowerCase();
    let key = this.loginViewModel?.Settings?.APIKey;
    if (key == null) {
      key = `API Key not given!`;
    } else {
      key = `****${key.substring(key.length - 5)}`;
    }
    this.rollbar.configure({
      payload: {
        person: { id: path, ["key"]: key },
      },
    });
  }

  /**
   * Called when a login attempt fails. Displays a msg to the user
   * and logs the status code that was returned.
   *
   * @param {LoginResult} loginResult When a login is rejected, it is
   * usually because of an API call that returns a bad HTTP status code.
   * This parameter is for that status code, and also the text description
   * of the error if one exists.
   */
  rejectAttempt(loginResult: LoginResult) {
    this.rollbar.warn(
      `Authorization attempt rejected: ${JSON.stringify(loginResult)}`
    );
    this.notificationService.newNotification(
      Constants.LOGIN_ERROR,
      NotificationType.Error
    );
  }

  isValidUrl(str: string): boolean {
    try {
      return !!new URL(str);
    } catch (err) {
      return false;
    }
  }

  isFormValid(): boolean {
    let valid = true;
    if (!this.loginFormGroup.controls["APIKey"].value) {
      valid = false;
    } else if (
      //Custom domain option is selected, and custom domain input field is either
      //null or not a valid domain.
      this.loginFormGroup.controls["selectedDomain"]?.value == "custom" &&
      (!this.loginFormGroup.controls["customDomain"].value ||
        !this.isValidUrl(this.loginFormGroup.controls["customDomain"].value))
    ) {
      valid = false;
    } else if (
      //Gavel domain option is selected, and subdomain input field is either
      //null or not a valid subdomain.
      this.loginFormGroup.controls["selectedDomain"]?.value != "custom" &&
      (<String>this.loginFormGroup.controls["subdomain"].value).length < 1
    ) {
      valid = false;
    }
    return valid;
  }
}
