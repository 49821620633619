import { SimpleVariable } from "../variableTypes/SimpleVariable";
import {
  ComplexVariableType,
  VariableDataType,
} from "../variableTypes/VariableType";

export class FilterDateVariables {
  public static Filter(variable: SimpleVariable): boolean {
    if (variable.variableDataType == VariableDataType.Date) {
      return true;
    }
    return false;
  }
}
