import { Component, Input } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

@Component({
  selector: "app-conditional-comparison",
  templateUrl: "./conditional-child-comparison.component.html",
  styleUrls: ["./conditional-child-comparison.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ConditionalChildComparisonComponent,
    },
  ],
})
export class ConditionalChildComparisonComponent
  implements ControlValueAccessor
{
  public uniqueId: string =
    this.constructor.name + new Date().getMilliseconds();
  public form: FormGroup = new FormGroup({
    selectedOption: new FormControl(">"),
  });

  @Input() displayValueOption: boolean = true;

  onChange: any = () => {};

  constructor() {
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges() {
    this.form.controls["selectedOption"].valueChanges.subscribe((newValue) => {
      this.onChange(newValue);
    });
  }
  setDisabledState(isDisabled: boolean) {}

  registerOnTouched(fn: (_: any) => void): void {}

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
    this.onChange(this.form.controls["selectedOption"].value);
  }

  writeValue(obj: any) {}

  selectedOptionChange(newValue: string) {
    this.form.controls["selectedOption"].setValue(newValue);
  }
}
