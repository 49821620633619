import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { VariableDataType } from "./VariableType";
import { ComplexVariable } from "./ComplexVariable";
import { SimpleVariable } from "./SimpleVariable";
import { SymbolTableEntryScope } from "../../syntax/AST/SymbolTableHelper";
import { DropdownOption } from "../Types";

export class ObjectComplexVariable extends ComplexVariable {
  public simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "default_repeat",
      text: "Standard",
    },
  ];

  private _repeatingMembers: SimpleVariable[] = [];
  private _complexMembers: DropdownOption[] = [];

  public symbolTableScopes = [SymbolTableEntryScope.Object];

  get complexMembers(): DropdownOption[] {
    return this._complexMembers;
  }

  private setComplexMembers() {
    this._complexMembers = [];
    this._repeatingMembers.forEach((m) => {
      this._complexMembers.push({ code: m.text, text: m.text });
    });
  }
  get repeatingMembers(): SimpleVariable[] {
    return this._repeatingMembers;
  }

  label: string;

  constructor(
    handle: string,
    display: string,
    label: string,
    repeating_vars?: SimpleVariable[],
    parent?: string
  ) {
    super(handle, display, VariableDataType.Object, parent);
    this.label = label;

    this._repeatingMembers = repeating_vars
      ? repeating_vars
      : new Array<SimpleVariable>();

    this.setComplexMembers();
  }
}
