<form [formGroup]="form">
  <div class="form-group form-group-isisnot">
    <div class="form-check form-check-inline form-check-inline-radio">
      <input
        formControlName="inputBoolean"
        class="form-check-input"
        type="radio"
        id="{{ uniqueID }}.is"
        value="IS"
        checked
      />
      <label
        (click)="onComparisonBooleanChange('IS')"
        class="form-check-label"
        for="{{ uniqueID }}.is"
        >is</label
      >
    </div>

    <div class="form-check form-check-inline form-check-inline-radio">
      <input
        formControlName="inputBoolean"
        class="form-check-input"
        type="radio"
        id="{{ uniqueID }}.false"
        value="false"
      />
      <label
        (click)="onComparisonBooleanChange('false')"
        class="form-check-label"
        for="{{ uniqueID }}.false"
        >is not</label
      >
    </div>
  </div>
</form>
