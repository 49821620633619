<form [formGroup]="form">
  <div class="form-group form-group-cond-repeat-options">
    <div class="form-check form-check-inline form-check-inline-radio">
      <input
        formControlName="selectedOption"
        class="form-check-input"
        type="radio"
        id="{{ uniqueId }}.gt"
        value=">"
      />
      <label
        (click)="selectedOptionChange('>')"
        class="form-check-label"
        for="{{ uniqueId }}.gt"
        >&gt;</label
      >
    </div>

    <div class="form-check form-check-inline form-check-inline-radio">
      <input
        formControlName="selectedOption"
        class="form-check-input"
        type="radio"
        id="{{ uniqueId }}.lt"
        value="<"
      />
      <label
        (click)="selectedOptionChange('<')"
        class="form-check-label"
        for="{{ uniqueId }}.lt"
        >&lt;</label
      >
    </div>

    <div class="form-check form-check-inline form-check-inline-radio">
      <input
        formControlName="selectedOption"
        class="form-check-input"
        type="radio"
        id="{{ uniqueId }}.eq"
        value="=="
      />
      <label
        (click)="selectedOptionChange('==')"
        class="form-check-label"
        for="{{ uniqueId }}.eq"
        >=</label
      >
    </div>
    <ng-container *ngIf="displayValueOption">
      <div class="form-check form-check-inline form-check-inline-radio">
        <input
          formControlName="selectedOption"
          class="form-check-input"
          type="radio"
          id="{{ uniqueId }}.value"
          value="value"
        />
        <label
          (click)="selectedOptionChange('value')"
          class="form-check-label"
          for="{{ uniqueId }}.value"
          >value</label
        >
      </div>
    </ng-container>
  </div>
</form>

<!-- TODO
<mat-radio-group
  formControlName="radioGroup"
  class="form-check form-check-inline"
>
  <mat-radio-button
    class="form-check-input"
    type="radio"
    name="{{ uniqueId }}"
    id="{{ uniqueId }}.gt"
    value=">"
    checked
  >&gt;</mat-radio-button
  >
  <mat-radio-button
    class="form-check-input"
    type="radio"
    name="{{ uniqueId }}"
    id="{{ uniqueId }}.lt"
    value="<"
  >&lt;</mat-radio-button
  >
  <mat-radio-button
    class="form-check-input"
    type="radio"
    name="{{ uniqueId }}"
    id="{{ uniqueId }}.eq"
    value="=="
  >=</mat-radio-button
  >
  <mat-radio-button
    class="form-check-input"
    type="radio"
    name="{{ uniqueId }}"
    id="{{ uniqueId }}.value"
    value="value"
  >value</mat-radio-button
  >
</mat-radio-group>-->
