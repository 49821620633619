import { SimpleVariable } from "../shared/variableTypes/SimpleVariable";
import { TextVariable } from "../shared/variableTypes/TextVariable";
import { ObjectComplexVariable } from "../shared/variableTypes/ObjectComplexVariable";
import { rollbarFactory } from "../shared/rollbarerrorhandler.service";

export class SimpleExpressionBuilder {
  static BuildSyntax(
    variableName: string,
    formatOption: string,
    decimalPlaces?: number
  ): string {
    let openingBracket: string = "{{ ";
    let closingBracket: string = " }}";

    let expression: string;

    //First set information inside brackets {{ }}
    switch (formatOption) {
      case "upper":
        expression = variableName + " | upper";
        break;

      case "first":
        expression = "capitalize(variable)".replace("variable", variableName);
        break;

      case "multi":
        expression = "variable | manual_line_breaks".replace(
          "variable",
          variableName
        );
        break;

      case "single":
        expression = "variable | single_paragraph".replace(
          "variable",
          variableName
        );
        break;

      case "mmddyy":
        expression = "format_date(variable, format='short')".replace(
          "variable",
          variableName
        );
        break;

      case "mmddyyyy":
        expression = "format_date(variable, format='MM/dd/yyyy')".replace(
          "variable",
          variableName
        );
        break;

      //TODO: Add to confluence page
      case "ddmmyyyy":
        expression = "format_date(variable, format='dd/MM/yyyy')".replace(
          "variable",
          variableName
        );
        break;

      case "monthyear":
        expression = "format_date(variable, format='MMMM ____ , yyyy')".replace(
          "variable",
          variableName
        );
        break;

      case "mmddyyyy_noslash":
        expression = "format_date(variable, format='MMddyyyy')".replace(
          "variable",
          variableName
        );
        break;

      case "doddom":
        expression =
          "format_date(variable, format='dd') }} day of {{ format_date(variable, format='MMMM yyyy')".replace(
            /variable/g,
            variableName
          );
        break;

      case "daytext":
        expression = `ordinal_number(${variableName}.day, use_word=True)`;
        break;
      case "dayordinal":
        expression = `ordinal_number(${variableName}.day, use_word=False)`;
        break;

      case "day1digit":
        expression = `format_date(${variableName}, format='d')`;
        break;

      case "day2digit":
        expression = `format_date(${variableName}, format='dd')`;
        break;

      case "monthtext":
        expression = `format_date(${variableName}, format='MMMM')`;
        break;

      case "month1digit":
        expression = `format_date(${variableName}, format='M')`;
        break;

      case "month2digit":
        expression = `format_date(${variableName}, format='MM')`;
        break;

      case "weekday":
        expression = `format_date(${variableName}, format='EEEE')`;
        break;

      case "year4digit":
        expression = `format_date(${variableName}, format='yyyy')`;
        break;

      case "yeartext":
        expression = `numbers_to_words(format_date(${variableName}, format='yyyy'))`;
        break;

      case "full":
        expression =
          "dow_of(variable, as_word=True) }}, the {{ day_of(variable) }} day of {{ month_of(variable, as_word=True) }} in the year {{ year_of(variable)".replace(
            /variable/g,
            variableName
          );
        break;

      case "decimal":
        expression = "format_decimal(variable, $dec)"
          .replace("$dec", decimalPlaces.toString())
          .replace("variable", variableName);
        break;

      case "decimalComma":
        expression = `format_decimal(${variableName}, ${decimalPlaces.toString()}, True)`;
        break;

      case "currency":
        expression = "currency(variable)".replace("variable", variableName);
        break;

      case "default_repeat":
        expression = "variable.number()".replace("variable", variableName);
        break;

      case "checked":
        expression = "variable.true_values()".replace("variable", variableName);
        break;

      case "word":
        expression = "numbers_to_words(variable)".replace(
          "variable",
          variableName
        );
        break;

      case "wordLower":
        expression = "numbers_to_words(variable)|lower".replace(
          "variable",
          variableName
        );
        break;

      case "wordUpper":
        expression = "numbers_to_words(variable)|upper".replace(
          "variable",
          variableName
        );
        break;

      case "ordinal":
        expression = `ordinal_number(${variableName})`;
        break;

      case "title":
        expression = `title_case(${variableName})`;
        break;
      default:
        if (variableName == "today()") {
          expression = "format_date(today())";
        } else {
          expression = variableName;
        }
        break;
    }

    //Then set highlighting and formatting options and finally wrap in {{ }}
    let text_expression =
      '<span style="background-color:yellow">$expression</span>'.replace(
        "$expression",
        expression
      );

    expression = openingBracket + text_expression + closingBracket;
    return expression;
  }

  static InsertAllVariables(
    variables: SimpleVariable[],
    repeatVariables: SimpleVariable[],
    nestedRepeatVariables: SimpleVariable[]
  ): string {
    let openingBracket: string = "<p>{{ ";
    let closingBracket: string = " }}</p>";
    let expr = "";

    /*strip out variables that should not be written
    to the document
     */
    variables = variables.filter((v) => {
      if (v == null) {
        rollbarFactory().warn(
          "Insert Questions called w/ empty Variable stack"
        );
        return false;
      }
      if (v.id == "user_info().session") {
        return false;
      } else if (v.id == "insertAll") {
        return false;
      } else if (v.id == "insertQuestions") {
        return false;
      } else if (v.id == "today()") {
        return false;
      }

      return true;
    });

    let x = 0;

    while (x < variables.length) {
      if (variables[x].id.indexOf(".number()") == -1) {
        expr =
          expr +
          '<p>{{ <span style="background-color:yellow">$VARIABLE</span> }}</p>'.replace(
            "$VARIABLE",
            variables[x].id
          );
      }
      x++;
    }

    expr +=
      "<br/>" +
      SimpleExpressionBuilder.BuildRepeatItemExpression(
        repeatVariables,
        false,
        nestedRepeatVariables
      );

    return expr;
  }

  static InsertQuestionsVariables(
    variables: SimpleVariable[],
    repeatVariables: SimpleVariable[],
    nestedRepeatVariables: SimpleVariable[]
  ): string {
    let expr = "";

    let question: string = "";

    /*strip out variables that should not be written
      to the document
       */
    variables = variables.filter((v) => {
      if (v == null) {
        rollbarFactory().warn(
          "Insert Questions called w/ empty Variable stack"
        );
        return false;
      }
      if (v.id == "user_info().session") {
        return false;
      } else if (v.id == "insertAll") {
        return false;
      } else if (v.id == "insertQuestions") {
        return false;
      } else if (v.id == "today()") {
        return false;
      }

      return true;
    });

    let x = 0;

    while (x < variables.length) {
      if (variables[x].id.indexOf(".number()") == -1) {
        if ((<TextVariable>variables[x]).label) {
          //Test for repeating item member WA-133
          if (/\['(\w+)'\]/.test((<TextVariable>variables[x]).label)) {
            question = SimpleExpressionBuilder.transformRepeatItemLabel(
              (<TextVariable>variables[x]).label
            );
          } else {
            question = (<TextVariable>variables[x]).label + ": ";
          }
        } else {
          question = "";
        }

        expr =
          expr +
          '<p><b>$QUESTION</b> {{ <span style="background-color:yellow">$VARIABLE</span> }}</p>'
            .replace("$VARIABLE", variables[x].id)
            .replace("$QUESTION", question);
      }
      x++;
    }

    expr +=
      "<br/>" +
      SimpleExpressionBuilder.BuildRepeatItemExpression(
        repeatVariables,
        true,
        nestedRepeatVariables
      );

    return expr;
  }

  //Insert MultiLine List
  static BuildRepeatItemExpression(
    data: SimpleVariable[],
    withLabel: boolean,
    nestedRepeatVariables: SimpleVariable[]
  ): string {
    let expr = "";

    for (let x = 0; x < data.length; x++) {
      const repeatObj = data[x];
      //Print header (Parent name)
      expr += `<p><b>${repeatObj.id}</b></p>`;
      expr += `{%p <span style="background-color:yellow">for item in ${repeatObj.id}</span> %}<br/>`;

      //Print Repeating items (L1 variables, top level workflow variables are the L0s)
      for (
        let y = 0;
        y < (<ObjectComplexVariable>repeatObj).repeatingMembers.length;
        y++
      ) {
        const child = (<ObjectComplexVariable>repeatObj).repeatingMembers[y];
        expr += this.BuildRepeatItemChildExpression(
          <TextVariable>child,
          withLabel
        );
        //Find any nested repeating items
        let nestedChildren = nestedRepeatVariables.filter((c) => {
          return c.itemParent == child.id;
        });

        if (nestedChildren.length > 0) {
          expr += `{%p <span style="background-color:yellow">for nesteditem in item.${child.id}</span> %}<br/>`;
          for (let z = 0; z < nestedChildren.length; z++) {
            const n = nestedChildren[z];
            expr += this.BuildRepeatItemChildExpression(
              <TextVariable>n,
              withLabel,
              true
            );
          }
          expr +=
            '<p>{%p <span style="background-color:yellow">endfor</span> %}</p>';
        }
      }
      expr +=
        '<p>{%p <span style="background-color:yellow">endfor</span> %}</p>';
    }

    return expr;
  }

  static BuildRepeatItemChildExpression(
    child: TextVariable,
    withLabel: boolean,
    isNested: boolean = false
  ): string {
    let memberName = child.id;
    let prefix = isNested ? "nesteditem" : "item";
    if (withLabel) {
      return `<p><b>${child.label}</b>: {{ <span style="background-color:yellow">${prefix}.${memberName}</span> }}</p>`;
    } else {
      return `<p>{{ <span style="background-color:yellow">${prefix}.${memberName}</span> }}<p/>`;
    }
  }

  private static transformRepeatItemLabel(label: string): string {
    let newLabel = "";

    //Parse label and grab relevant tokens
    if (/clio_matter/.test(label)) {
      newLabel = "Clio Matter -";
    } else {
      /(\w+)/
        .exec(label)[1]
        .split("_")
        .forEach((val) => {
          newLabel += val[0].toUpperCase() + val.substring(1) + " ";
        });

      newLabel += "-";
    }

    //Get words inside [' ']
    let desc = /\['(\w+)'\]/.exec(label)[1];

    desc.split("_").forEach((value) => {
      newLabel += " " + value[0].toUpperCase() + value.substring(1);
    });

    //Get word after first '.'
    if (label.split(".").length > 1) {
      label.split(".").forEach((value, index) => {
        if (index != 0) {
          newLabel += " -";
          value.split("_").forEach((val) => {
            newLabel += " " + val[0].toUpperCase() + val.substring(1);
          });
        }
      });
      //
    }

    return newLabel + ":";
  }
}
