import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { SimpleVariable } from "./SimpleVariable";
import { VariableDataType } from "./VariableType";
import { SymbolTableEntryScope } from "../../syntax/AST/SymbolTableHelper";

export class NumberVariable extends SimpleVariable {
  public simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "float",
      text: "Number",
    },
    {
      code: "decimal",
      text: "Decimal",
    },
    { code: "decimalComma", text: "Decimal with Commas" },
    {
      code: "currency",
      text: "Currency",
    },
    {
      code: "word",
      text: "Word - First Letter Capital",
    },
    {
      code: "wordLower",
      text: "Word - Lowercase",
    },
    {
      code: "wordUpper",
      text: "Word - Uppercase",
    },
    {
      code: "ordinal",
      text: "Ordinal Number",
    },
  ];

  label: string;

  constructor(
    handle: string,
    display: string,
    label?: string,
    itemparent?: string
  ) {
    super(handle, display, VariableDataType.Number, null, itemparent);
    this.symbolTableScopes = [SymbolTableEntryScope.Number];

    this.label = label;
  }
}
