import { Component, Input, OnInit } from "@angular/core";
import { SimpleVariable } from "../../shared/variableTypes/SimpleVariable";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { RepeatListType } from "../RepeatListDropdown";
import { RepeatComponentForm } from "../RepeatComponentForm";
import { DropdownOption } from "../../shared/Types";

@Component({
  selector: "app-repeat-child",
  templateUrl: "./repeat-child.component.html",
  styleUrls: ["./repeat-child.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RepeatChildComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: RepeatChildComponent,
    },
  ],
})
export class RepeatChildComponent
  implements OnInit, ControlValueAccessor, Validator
{
  @Input() Description: string;
  @Input() set Parent(parent: SimpleVariable) {
    this.formData.listVariable = parent.id;
  }
  @Input("L2Children") l2Children: SimpleVariable[];
  @Input() listType: RepeatListType;

  onChange = (newValue) => {};
  repeatListTypes = RepeatListType;
  repeatListDropdown: DropdownOption[] = [
    {
      code: RepeatListType.List,
      text: "List",
    },
    {
      code: RepeatListType.MultiLine,
      text: "Multi-line List",
    },
  ];
  formData: RepeatComponentForm = new RepeatComponentForm();

  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  buildForm() {
    this.form = this.fb.group({
      selectedItems: this.fb.control(null, [Validators.required]),
      listType: this.fb.control((<any>this.repeatListDropdown[0]).code, [
        Validators.required,
      ]),
      itemNumber: this.fb.control(null),
      listSeparator: this.fb.control(null),
    });
  }

  ngOnInit() {
    this.buildForm();
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges() {
    this.form.valueChanges.subscribe((value) => {
      this.formData.listChildren = value.selectedItems
        ? value.selectedItems
        : [];
      this.formData.listType = value.listType;
      this.formData.listSeparator = value.listSeparator;
      this.formData.itemNumber = value.itemNumber;
      this.onChange(this.formData);
    });
    this.form.controls["itemNumber"].valueChanges.subscribe((v) => {
      if (v.itemNumber < 1) {
        this.form.controls["itemNumber"].setValue(1);
      }
    });
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
    this.onChange(this.formData);
  }
  registerOnTouched(fn: any) {}
  writeValue(obj: any) {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.invalid) return { FormInvalid: true };
    else {
      return null;
    }
  }
}
