import { SimpleVariable } from "../variableTypes/SimpleVariable";
import { VariableDataType } from "../variableTypes/VariableType";
import { ObjectComplexVariable } from "../variableTypes/ObjectComplexVariable";

export class FilterNumberVariables {
  public static Filter(variable: SimpleVariable): boolean {
    if (variable.variableDataType == VariableDataType.Number) {
      return true;
    }
    // else if (variable.variableDataType == VariableDataType.Object) {
    //   return true;
    // }
    return false;
  }

  //TODO: call this method after the objects have child members
  private static isObjectContainingNumber(variable: SimpleVariable): boolean {
    if (variable.variableDataType == VariableDataType.Object) {
      let obj = <ObjectComplexVariable>variable;

      obj.repeatingMembers.forEach((child: SimpleVariable) => {
        if (child.variableDataType == VariableDataType.Number) {
          return true;
        }
      });
    }

    return false;
  }
}
