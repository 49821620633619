import { DropdownOption } from "../shared/Types";

export enum RepeatListType {
  List,
  MultiLine,
  Comma,
  Oxford,
  Semicolon,
  Custom,
  Table,
  NextItem,
  ItemNumber,
  GenerateMultiple,
}
export const RepeatListDropdown: DropdownOption[] = [
  {
    code: RepeatListType.List,
    text: "List",
  },
  {
    code: RepeatListType.MultiLine,
    text: "Multi-line List",
  },
  {
    code: RepeatListType.Comma,
    text: "List - Commas",
  },
  {
    code: RepeatListType.Oxford,
    text: "List - Oxford Comma",
  },
  {
    code: RepeatListType.Semicolon,
    text: "List - Semicolon",
  },
  {
    code: RepeatListType.Custom,
    text: "List - Custom Separator",
  },
  {
    code: RepeatListType.NextItem,
    text: "Refer To Next Item",
  },
  {
    code: RepeatListType.Table,
    text: "Table",
  },
  {
    code: RepeatListType.ItemNumber,
    text: "Item Number",
  },
  {
    code: RepeatListType.GenerateMultiple,
    text: "Generate Multiple Documents",
  },
];
