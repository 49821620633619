<form
  [formGroup]="form"
  class="ml-1 mb-4"
  style="border-left: #cccccc 2px solid; padding-left: 6px"
>
  <label
    for="selectedItems"
    class="form-label"
    [innerHTML]="Description"
  ></label>
  <app-variable-dropdown
    id="selectedItems"
    formControlName="selectedItems"
    [appendTo]="'body'"
    [multiple]="true"
    [options]="l2Children"
    [clearable]="false"
  >
  </app-variable-dropdown
  ><br />
  <label for="listType" class="form-label">Format</label>
  <p-dropdown
    id="listType"
    formControlName="listType"
    [appendTo]="'body'"
    optionLabel="text"
    [options]="repeatListDropdown"
    [showClear]="false"
  ></p-dropdown>
  <ng-container *ngIf="this.listType == repeatListTypes.ItemNumber">
    <div class="form-group">
      <div class="row no-gutters">
        <div class="col-8">
          <input
            type="text"
            class="form-control form-control-sm disabled rounded-left"
            style="border-radius: 0"
            value="Select Item Number:"
            disabled
          />
        </div>
        <div class="col-4">
          <input
            formControlName="itemNumber"
            id="id-simple-variable-decimal-places"
            type="number"
            [min]="1"
            class="form-control form-control-sm rounded-right"
            style="border-radius: 0"
          />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="this.listType == repeatListTypes.Custom">
    <div>
      <label for="id-custom-separator">Separated by a</label>
      <input
        formControlName="listSeparator"
        id="id-custom-separator"
        class="form-control"
        type="text"
      />
    </div>
  </ng-container>
</form>
