import { RegexConstants } from "../shared/RegexConstants";
import { SyntaxContainer } from "../shared/Types";

export class SyntaxHighlighter {
  static Highlight(range: SyntaxContainer, context: Word.RequestContext) {
    /*Assuming it would be cumbersome to
     * check if syntax is highlighted before
     * highlighting, we will just highlight it :)*/
    const text = range.text;
    if (RegexConstants.regexStatementSyntax.test(text)) {
      this.HighlightStatement(range, context);
    } else if (RegexConstants.regexParagraphSyntax.test(text)) {
      this.HighlightParagraph(range, context);
    } else if (RegexConstants.regexPhraseSyntax.test(text)) {
      this.HighlightConditional(range, context);
    } else if (RegexConstants.regexTableSyntax.test(text)) {
      this.HighlightTable(range, context);
    }
  }

  static async HighlightStatement(
    range: SyntaxContainer,
    context: Word.RequestContext
  ) {
    const expression = range.text
      .match(RegexConstants.regexStatementSyntax)[1]
      .trim();
    const highlightedSyntax = `{{ <span style=\"background-color:yellow\">${expression}</span> }}`;
    this.apiHighlight(range, highlightedSyntax, context);
  }

  static async HighlightParagraph(
    range: SyntaxContainer,
    context: Word.RequestContext
  ) {
    const expression = range.text
      .match(RegexConstants.regexParagraphSyntax)[1]
      .trim();
    const highlightedSyntax = `{%p <span style=\"background-color:#00FF00\">${expression}</span> %}`;
    this.apiHighlight(range, highlightedSyntax, context);
  }

  static async HighlightConditional(
    range: SyntaxContainer,
    context: Word.RequestContext
  ) {
    const expression = range.text
      .match(RegexConstants.regexPhraseSyntax)[1]
      .trim();
    const highlightedSyntax = `{% <span style=\"background-color:#00FFFF\">${expression}</span> %}`;
    this.apiHighlight(range, highlightedSyntax, context);
  }

  static async HighlightTable(
    range: SyntaxContainer,
    context: Word.RequestContext
  ) {
    const expression = range.text
      .match(RegexConstants.regexTableSyntax)[1]
      .trim();
    const highlightedSyntax = `{%tr <span style=\"background-color:yellow\">${expression}</span> %}`;
    this.apiHighlight(range, highlightedSyntax, context);
  }

  private static async apiHighlight(
    range: SyntaxContainer,
    highlightedSyntax: string,
    context: Word.RequestContext
  ): Promise<void> {
    const font = range.font;
    //range.untrack();
    let newRange = range
      .insertHtml(highlightedSyntax, Word.InsertLocation.replace)
      .load("font");
    newRange.font.set(font);
  }
}
