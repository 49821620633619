import { SimpleVariable } from "../variableTypes/SimpleVariable";
import {
  VariableDataType,
} from "../variableTypes/VariableType";

export class FilterSignatureVariables {
  public static Filter(variable: SimpleVariable): boolean {
    return variable.variableDataType === VariableDataType.Signature;
  }
}
