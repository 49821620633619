import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { WorkflowService } from "../shared/workflow.service";
import { SimpleVariable } from "../shared/variableTypes/SimpleVariable";
import { SimpleFormattingOptions } from "../shared/SimpleFormattingOptions";
import { SimpleExpressionBuilder } from "./SimpleExpressionBuilder";
import { TextVariable } from "../shared/variableTypes/TextVariable";
import { Utils } from "../shared/Utils";
import { RollbarService } from "../shared/rollbarerrorhandler.service";
import Rollbar from "rollbar";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-simple-variable",
  templateUrl: "./simple.component.html",
  styleUrls: ["./simple.component.css"],
})
export class SimpleComponent {
  repeatVariables: SimpleVariable[];
  simpleVariables: SimpleVariable[];
  availableFormats: SimpleFormattingOptions[];

  insertAll = new TextVariable("insertAll", "Insert All Variables", "");
  insertQuestions = new TextVariable(
    "insertQuestions",
    "Insert All Questions and Variables",
    ""
  );
  insertSessionID = new TextVariable(
    "user_info().session",
    "Unique Session ID",
    ""
  );

  @ViewChild("insertBtn") insertBtn: ElementRef<HTMLButtonElement>;

  public form: FormGroup;
  private repeatVariablesChildren: SimpleVariable[];

  constructor(
    private fb: FormBuilder,
    private interview: WorkflowService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.buildForm();
    this.subscribeToWorkflowVariables();
    this.subscribeToSelectedVariable();
  }

  buildForm() {
    this.form = this.fb.group({
      controlSelectedVariable: this.fb.control(null, [
        Validators.required,
        this.validateControlSelectedVariable,
      ]),
      controlSelectedFormat: this.fb.control("", [Validators.required]),
      decimalPlaces: this.fb.control(2),
    });
  }

  subscribeToSelectedVariable() {
    this.form.controls["controlSelectedVariable"].valueChanges.subscribe(() => {
      this.updateFormattingOptions();
    });
  }

  subscribeToWorkflowVariables() {
    this.interview.workflowVariablesRepeat.subscribe((x) => {
      this.repeatVariables = x;
    });
    this.interview.workflowVariablesRepeatChildren.subscribe((x) => {
      this.repeatVariablesChildren = x;
    });
    this.interview.workflowVariablesSimple.subscribe((x) => {
      if (x.length > 0) {
        this.simpleVariables = x;

        if (this.simpleVariables[0].id != this.insertQuestions.id) {
          this.simpleVariables.unshift(this.insertQuestions, this.insertAll);
          this.simpleVariables.push(this.insertSessionID);
        }
      } else {
        this.simpleVariables = new Array();
      }
    });
  }

  updateFormattingOptions() {
    this.availableFormats =
      this.form.controls["controlSelectedVariable"].value?.simpleFormatOptions;
    if (this.availableFormats) {
      this.form.controls["controlSelectedFormat"].setValue(
        this.availableFormats[0]
      );
    }
  }

  async run() {
    const variableId: string =
      this.form.controls["controlSelectedVariable"].value.id;
    const formatOption: string =
      this.form.controls["controlSelectedFormat"].value.code;
    let expr = "";

    if (variableId == "insertAll") {
      expr = SimpleExpressionBuilder.InsertAllVariables(
        this.simpleVariables,
        this.repeatVariables,
        this.repeatVariablesChildren
      );
    } else if (variableId == "insertQuestions") {
      expr = SimpleExpressionBuilder.InsertQuestionsVariables(
        this.simpleVariables,
        this.repeatVariables,
        this.repeatVariablesChildren
      );
    } else {
      expr = SimpleExpressionBuilder.BuildSyntax(
        variableId,
        formatOption,
        this.form.controls["decimalPlaces"].value
      );
    }

    console.debug("html: " + expr);
    return Word.run(async (context) => {
      // insert a paragraph at the end of the document.
      console.debug("inserting html into document....");
      let range = context.document.getSelection();
      range.load("font");

      await context.sync();
      let fontName = range.font.name;
      let fontSize = range.font.size;

      range.insertHtml(expr, Word.InsertLocation.replace);

      range.font.name = fontName;
      range.font.size = fontSize;

      console.debug("syncing....");
      await context.sync();

      console.debug("done.");
    })
      .catch((error) => {
        this.rollbar.warn("Error during insert simple syntax", error);
      })
      .then(() => {
        Utils.blurButton(this.insertBtn);
      });
  }
  validateControlSelectedVariable(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return { forbiddenNull: { value: control.value } };
    };
  }
}
