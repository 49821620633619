<form [formGroup]="form">
  <div class="form-group">
    <ng-container *ngIf="displayMode1">
      <ng-select
        [placeholder]="TYPE_TO_INPUT"
        formControlName="SelectedNumberVariable1"
        [virtualScroll]="true"
        [appendTo]="'body'"
        [addTag]="addCustomNumber"
        [items]="numberVariables"
        bindLabel="text"
        [clearable]="false"
      ></ng-select>
    </ng-container>
  </div>
  <ng-container *ngIf="!displayFirstChildOnly">
    <div class="form-group">
      <p-dropdown
        formControlName="OperandId"
        [options]="operandGroup"
        [appendTo]="'body'"
        [showClear]="false"
        optionLabel="code"
      ></p-dropdown>
    </div>
    <div class="form-group">
      <ng-select
        formControlName="SelectedNumberVariable2"
        [appendTo]="'body'"
        [addTag]="addCustomNumber"
        bindLabel="text"
        [items]="numberVariables2"
        [clearable]="false"
        [placeholder]="TYPE_TO_INPUT"
      ></ng-select>
    </div>
  </ng-container>
</form>
