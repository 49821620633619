<form [formGroup]="form">
  <div>
    <app-conditional-true-false
      formControlName="InputBoolean"
    ></app-conditional-true-false>
    <app-variable-dropdown
      formControlName="SelectedMultipleChoice"
      [options]="multipleChoiceItems"
      [clearable]="false"
      [appendTo]="'body'"
    >
    </app-variable-dropdown>
  </div>
</form>
