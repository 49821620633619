import { Component, OnInit } from "@angular/core";
import { environment } from "../../environments/environment";
import { SettingsService } from "../shared/settings.service";
import { Router } from "@angular/router";

/**
 * Main splash screen for the add in. First
 * page that loads. This should just become a static
 * HTML file. It would probably load faster, even if
 * only on a microscopic level.
 */
@Component({
  selector: "app-splash",
  templateUrl: "./splash.component.html",
  styleUrls: ["./splash.component.css"],
})
export class SplashComponent implements OnInit {
  /**
   * Default constructor.
   */
  constructor(
    private settingsService: SettingsService,
    private router: Router
  ) {}

  /**
   * Pulls the version of the add-in from AppComponent
   * and displays it on the splash page
   */
  software_version: string = environment.appVersion;

  /**
   * Default ngOnInit
   */
  ngOnInit(): void {}

  skipSplash() {
    console.info("Splash page skipped!");
    this.settingsService.setSettings("SplashPage", { SkipSplash: true });
    this.router.navigate(["/login"]);
  }
}
