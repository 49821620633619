import { version } from "../../package.json";

export const environment = {
  appVersion: version,
  production: true,
  rollbarConfig: {
    accessToken: "5cdb5283a27b4fc891b0e30deafe8d3a",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: "Production",
      client: {
        javascript: {
          source_map_enabled: true, // true by default

          // -- Add this into your configuration ---
          code_version: version,
          // ---------------------------------------

          // Optionally have Rollbar guess which frames the error was
          // thrown from when the browser does not provide line
          // and column numbers.
          guess_uncaught_frames: true,
        },
      },
    },
  },
};
