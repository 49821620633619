<!-- Date Calculation -->
<form [formGroup]="form">
  <div class="form-group">
    <label style="color: gray">Calculate dates below</label>

    <p-dropdown
      [showClear]="false"
      [options]="dateOperations"
      optionLabel="text"
      formControlName="dateOperation"
    >
      <ng-template pTemplate="selectedItem">
        <div
          [pTooltip]="form.controls['dateOperation'].value.text"
          tooltipPosition="top"
        >
          <div>
            {{ form.controls["dateOperation"].value.text }}
          </div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <!-- Miniform - length of time between two dates-->
  <ng-container *ngIf="form.controls['dateOperation'].value.code == 0">
    <div class="form-group">
      <div class="form-group">
        <app-variable-dropdown
          [clearable]="false"
          [appendTo]="'body'"
          [options]="dateVariables"
          formControlName="dateCompare1"
        >
        </app-variable-dropdown>

        <label class="px-2" style="color: gray">and</label>

        <app-variable-dropdown
          [clearable]="false"
          [appendTo]="'body'"
          [options]="dateVariables"
          formControlName="dateCompare2"
        >
        </app-variable-dropdown>
      </div>
      <div class="form-group">
        <label class="form-check-label">Display value in</label>

        <p-dropdown
          [showClear]="false"
          formControlName="dateDisplayType"
          [options]="dateDisplayTypes"
          optionLabel="altText"
          [appendTo]="'body'"
        >
        </p-dropdown>
      </div>
    </div>
  </ng-container>

  <!--End Miniform - length of time between two dates-->

  <!--Begin subform for add or subtract time from date -->
  <ng-container *ngIf="form.controls['dateOperation'].value.code == 1">
    <div
      class="form-group container-fluid"
      style="padding-right: 0px; padding-left: 0px"
    >
      <div class="form-check form-check-inline form-check-inline-radio">
        <input
          class="form-check-input form-control"
          checked="checked"
          type="radio"
          id="date-diffaddtime-addsub.radio1"
          value="+"
          formControlName="datediff_defaultChoice"
        />
        <label class="form-check-label" for="date-diffaddtime-addsub.radio1"
          >+</label
        >
      </div>

      <div class="form-check form-check-inline form-check-inline-radio">
        <input
          class="form-check-input form-control"
          type="radio"
          id="date-diffaddtime-addsub.radio2"
          value="-"
          formControlName="datediff_defaultChoice"
        />
        <label class="form-check-label" for="date-diffaddtime-addsub.radio2"
          >-</label
        >
      </div>
      <div
        class="form-check form-check-inline"
        style="width: 150px; margin-right: 0px"
      >
        <input
          type="number"
          id="id-date-diffaddtime-quantity"
          class="form-control"
          formControlName="dateAddQuantity"
        />
      </div>
      <div class="form-inline align-items-center">
        <p-dropdown
          style="width: 100%"
          [appendTo]="'body'"
          [showClear]="false"
          formControlName="dateDisplayType"
          [options]="dateDisplayTypes"
          optionLabel="text"
        >
        </p-dropdown>
        <app-variable-dropdown
          style="width: 100%; margin-top: 5px"
          [appendTo]="'body'"
          [options]="dateVariables"
          [clearable]="false"
          formControlName="diffAddDate"
        ></app-variable-dropdown>
      </div>
    </div>
  </ng-container>
  <!--End subform for add or subtract time from date -->
  <div class="form-group">
    <button
      #insertBtn
      (click)="insertDateCalculation()"
      class="btn btn-custom py-1"
      [disabled]="form.invalid"
    >
      Insert Date
    </button>
  </div>
</form>
