import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { VariableDataType } from "../../shared/variableTypes/VariableType";
import { ComplexVariable } from "../../shared/variableTypes/ComplexVariable";
import { SimpleConditionalVariable } from "src/app/shared/variableTypes/SimpleConditionalVariable";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ConditionalChild } from "../models/conditional-child.model";
import { ObjectComplexVariable } from "../../shared/variableTypes/ObjectComplexVariable";
import { SimpleVariable } from "../../shared/variableTypes/SimpleVariable";

@Component({
  selector: "conditional-child",
  templateUrl: "./conditional-child.component.html",
  styleUrls: ["./conditional-child.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConditionalChildComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ConditionalChildComponent,
    },
  ],
})
export class ConditionalChildComponent
  implements ControlValueAccessor, Validator
{
  @Input("header") firstElement: boolean = false;
  @Input() soloChild: boolean = true;
  @Input() set workflowVariablesConditional(x: SimpleVariable[]) {
    this.workflowVariables = x;
    if (this.workflowVariables.length > 0) {
      this.sortNewVariableStack();
    }

    //Set to empty array to erase previous values
    else {
      this.workflowVariables = [];
    }
  }

  @ViewChild("complexVariableSelect") complexVariableSelect: NgSelectComponent;

  VariableDataType = VariableDataType;

  public form: FormGroup;

  public onChange = (newVal) => {};
  public onValidationChange: () => void;

  //The currently selected complex variable and it's associated id
  public selectedWorkflowVariable: ComplexVariable | ObjectComplexVariable =
    new SimpleConditionalVariable("Empty", "Empty", "Empty");

  private conditionalChildExpression: ConditionalChild = new ConditionalChild();

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {
    this.buildFormGroup();
    this.subscribeToFormValueChanges();
    this.subscribeToJoiningBooleanValueChanges();
    this.subscribeToSelectedWorkflowVariable();
    this.initializeOutput();
  }

  writeValue(obj: any) {}

  setDisabledState(isDisabled: boolean) {}

  registerOnTouched(fn: any) {}

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
    this.onChange(this.conditionalChildExpression);
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
  initializeOutput() {
    this.conditionalChildExpression.joiningBoolean = "and";
  }

  //List of complex workflow variables
  workflowVariables: SimpleVariable[] | ComplexVariable[] = [];

  showAppRepeatConditional() {
    return (
      this.form.controls["SelectedL0Variable"].value?.variableDataType ==
      VariableDataType.Object
    );
  }

  showAppDateConditional() {
    return (
      this.form.controls["SelectedL0Variable"].value?.variableDataType ==
      VariableDataType.Date
    );
  }

  showAppNumberConditional() {
    return (
      this.form.controls["SelectedL0Variable"].value?.variableDataType ==
      VariableDataType.Number
    );
  }

  showAppMultiChoiceConditional() {
    switch (this.form.controls["SelectedL0Variable"].value?.variableDataType) {
      case VariableDataType.Dropdown:
      case VariableDataType.Boolean:
      case VariableDataType.Checkboxes:
      case VariableDataType.Radio:
      case VariableDataType.SimpleConditional:
        return true;
      default:
        return false;
    }
  }

  showJoiningBoolean(): boolean {
    return !this.firstElement;
  }

  sortNewVariableStack() {
    let aArray = new Array<ComplexVariable | SimpleVariable>();
    let bArray = new Array<ComplexVariable | SimpleVariable>();
    let cArray = this.workflowVariables;

    for (let i = 0; i < cArray.length; i++) {
      if (cArray[i].variableDataType == VariableDataType.SimpleConditional) {
        bArray.push(cArray[i]);
      } else {
        aArray.push(cArray[i]);
      }
    }

    this.workflowVariables = aArray;
    for (let i = 0; i < bArray.length; i++) {
      this.workflowVariables.push(bArray[i]);
    }
  }

  buildFormGroup() {
    this.form = this.fb.group(
      {
        JoiningBoolean: this.fb.control("and"),
        SelectedL0Variable: this.fb.control(null, [Validators.required]),
        ConditionalObjectChildExpression: this.fb.control(null),
        ConditionalDateChildExpression: this.fb.control(null),
        ConditionalNumberChildExpression: this.fb.control(null),
        ConditionalMultiChoiceChildExpression: this.fb.control(null),
      },
      { validators: this.validateForm }
    );
  }
  subscribeToFormValueChanges() {
    this.form.valueChanges.subscribe((newValue) => {
      if (this.showAppRepeatConditional()) {
        this.getConditionalChildSubExpression(
          newValue.ConditionalObjectChildExpression
        );
      } else if (this.showAppDateConditional()) {
        this.getConditionalChildSubExpression(
          newValue.ConditionalDateChildExpression
        );
      } else if (this.showAppNumberConditional()) {
        this.getConditionalChildSubExpression(
          newValue.ConditionalNumberChildExpression
        );
      } else if (this.showAppMultiChoiceConditional()) {
        this.getConditionalChildSubExpression(
          newValue.ConditionalMultiChoiceChildExpression
        );
      }
    });
  }

  subscribeToJoiningBooleanValueChanges() {
    this.form.controls["JoiningBoolean"].valueChanges.subscribe((newValue) => {
      this.conditionalChildExpression.joiningBoolean = newValue;
      this.onChange(this.conditionalChildExpression);
    });
  }

  subscribeToSelectedWorkflowVariable() {
    this.form.controls["SelectedL0Variable"].valueChanges.subscribe(
      (newValue) => {
        this.selectedWorkflowVariable = newValue;
        this.conditionalChildExpression.selectedWorkflowVariable = newValue;
        this.onChange(this.conditionalChildExpression);
        this.cd.detectChanges();
      }
    );
  }

  getConditionalChildSubExpression(newValue) {
    if (!newValue) return;
    let clone = require("lodash.clone");
    let expr: ConditionalChild = clone(newValue);
    this.conditionalChildExpression.inputChildMemberComparison =
      expr?.inputChildMemberComparison;
    this.conditionalChildExpression.optionGtLtEqValue = expr.optionGtLtEqValue;
    this.conditionalChildExpression.selectedMultipleChoice =
      expr.selectedMultipleChoice;
    this.conditionalChildExpression.selectedWorkflowVariable =
      this.selectedWorkflowVariable;
    this.conditionalChildExpression.inputGtLtEq = expr.inputGtLtEq;

    this.onChange(this.conditionalChildExpression);
  }
  validateForm(control: AbstractControl): ValidationErrors | null {
    let formGroup: FormGroup = <FormGroup>control;
    /*Check validity of joining boolean, selected
     * L0, and visible child component.*/
    if (!formGroup.controls["JoiningBoolean"].value) {
      return { JoiningBooleanNotSet: true };
    }
    return null;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.invalid) {
      return { invalid: true };
    }
    return null;
  }
}
