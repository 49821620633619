import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { SimpleVariable } from "./SimpleVariable";
import { VariableDataType } from "./VariableType";

export class DocusignField extends SimpleVariable {
  public readonly simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "signHere",
      text: "Signature",
    },
    {
      code: "dateSigned",
      text: "Date",
    },
    {
      code: "initialHere",
      text: "Initial",
    },
    {
      code: "approve",
      text: "Approval",
    },
  ];

  constructor(handle: string, display: string, parent?: string) {
    super(handle, display, VariableDataType.Docusign, null, parent);
  }
}
