<!-- Simple Variable -->
<div class="doc-bg-title"></div>
<form [formGroup]="form" id="toggle1" class="pt-2" data-parent="#accordion">
  <div class="form-group">
    <app-variable-dropdown
      formControlName="controlSelectedVariable"
      [appendTo]="'body'"
      [options]="simpleVariables"
    ></app-variable-dropdown>
  </div>
  <div class="form-group">
    <div class="d-table w-100">
      <div class="d-table-cell" style="width: 90px">
        <label class="pr-2">Format as</label>
      </div>
      <div class="d-table-cell">
        <p-dropdown
          [options]="availableFormats"
          [appendTo]="'body'"
          [showClear]="false"
          optionLabel="text"
          formControlName="controlSelectedFormat"
        >
        </p-dropdown>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="
      form.controls['controlSelectedFormat'].value.code == 'decimal' ||
      form.controls['controlSelectedFormat'].value.code == 'decimalComma'
    "
  >
    <div class="form-group">
      <div class="row no-gutters">
        <div class="col-10">
          <input
            id="client-name-append"
            type="text"
            class="form-control form-control-sm disabled rounded-left"
            style="border-radius: 0"
            value="No. of decimal places:"
            disabled
          />
        </div>
        <div class="col-2">
          <input
            id="id-simple-variable-decimal-places"
            type="number"
            formControlName="decimalPlaces"
            class="form-control form-control-sm rounded-right"
            style="border-radius: 0"
          />
        </div>
      </div>
    </div>
  </ng-container>
  <div class="form-group">
    <button
      #insertBtn
      [disabled]="form.invalid"
      (click)="run()"
      class="btn btn-custom py-1"
    >
      Insert variable
    </button>
  </div>
</form>
