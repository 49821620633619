// is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.
import Rollbar from "rollbar";
import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
} from "@angular/core";
import { environment } from "../../environments/environment";

const rollbarConfig = environment.rollbarConfig;

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>("rollbar", {
  factory: rollbarFactory,
});
