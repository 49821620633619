import { VariableDataType } from "../../shared/variableTypes/VariableType";
import {
  ConditionalChild,
  ConditionalComparisonOption,
} from "../models/conditional-child.model";
import { ConditionalObjectExpressionBuilder } from "./ConditionalObjectExpressionBuilder";
import { ConditionalBooleanExpressionBuilder } from "./ConditionalBooleanExpressionBuilder";
import { ConditionalMultiSelectExpressionBuilder } from "./ConditionalMultiSelectExpressionBuilder";
import { ConditionalSingleSelectExpressionBuilder } from "./ConditionalSingleSelectExpressionBuilder";
import { ConditionalSimpleExpressionBuilder } from "./ConditionalSimpleExpressionBuilder";
import { ConditionalNumberExpressionBuilder } from "./ConditionalNumberExpressionBuilder";
import {
  ConditionalExpression,
  ConditionalExpressionUtils,
  ConditionalType,
} from "./ConditionalExpressionUtils";

/**
 * This class is responsible for taking the options
 * selected by the user in the UI when the
 * user selects conditional phrase or paragraph
 * and generating the formatted text that will
 * be inserted into the document
 */
export class ConditionalExpressionBuilder {
  /**
   * The public facing method for the class. Builds the expression piece
   * by piece, calls sub methods for expression-specific syntax.
   * @param condType
   * @param conditionalChildren
   * @param fontFamily
   * @param fontSize
   * @constructor
   */
  static Build(
    condType: ConditionalType,
    conditionalChildren: ConditionalChild[],
    fontFamily: string,
    fontSize: string
  ): ConditionalExpression {
    let preamble = ConditionalExpressionUtils.BuildPreamble(
      condType,
      conditionalChildren[0],
      fontFamily,
      fontSize
    );

    //Build sub expression by combining child expressions
    let expression =
      ConditionalExpressionBuilder.BuildChildExpressions(conditionalChildren);

    let expressioncap = "</span> %}";

    expression = preamble + expression + expressioncap;

    //Build end cap expression
    let endcap = ConditionalExpressionUtils.BuildEndCap(
      condType,
      conditionalChildren[0].optionGtLtEqValue,
      fontFamily,
      fontSize
    );

    return new ConditionalExpression(expression, endcap);
  }

  private static BuildChildExpressions(
    conditionalChildren: ConditionalChild[]
  ): string {
    let expression = "";
    conditionalChildren.forEach((child, i) => {
      if (i != 0) {
        expression =
          expression + " $JOIN ".replace("$JOIN", String(child.joiningBoolean));
      }

      expression =
        expression + ConditionalExpressionBuilder.FindExpressionType(child);
    });
    return expression;
  }

  /*
   * Builds the "meat" of the expression by running
   * a case switch on the data type, and calling the
   * necessary sub-method.
   */
  private static FindExpressionType(data: ConditionalChild) {
    if (data.optionGtLtEqValue == ConditionalComparisonOption.Value) {
      return ConditionalObjectExpressionBuilder.BuildObjectValueExpression(
        data
      );
    }
    //gt lt eq
    else if (
      data.selectedWorkflowVariable.variableDataType == VariableDataType.Object
    ) {
      return ConditionalObjectExpressionBuilder.buildObjectNumberExpression(
        data
      );
    } /*else if (data.selectedMultipleChoice == "Blank") {
      //TODO No longer needed
      return ConditionalSimpleExpressionBuilder.Build(data);
    }*/

    //Standard complex type
    switch (data.selectedWorkflowVariable.variableDataType) {
      case VariableDataType.Boolean:
        return ConditionalBooleanExpressionBuilder.Build(data);

      case VariableDataType.Checkboxes:
        return ConditionalMultiSelectExpressionBuilder.Build(data);

      case VariableDataType.Radio:
      case VariableDataType.Dropdown:
        return ConditionalSingleSelectExpressionBuilder.Build(data);

      case VariableDataType.SimpleConditional:
        return ConditionalSimpleExpressionBuilder.Build(data);

      case VariableDataType.Date:
      case VariableDataType.Number:
        return ConditionalNumberExpressionBuilder.Build(data);
    }
  }
}
