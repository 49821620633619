import { NumberChildOutputData } from "./number-child/NumberChildOutputData";

export class CalculationSyntaxBuilder {
  static BuildSyntax: (
    calcData: NumberChildOutputData[],
    format: CalculationFormatOption,
    decimalPlaces?: number
  ) => string = function BuildSyntax(
    calcData: NumberChildOutputData[],
    format: CalculationFormatOption,
    decimalPlaces?: number
  ): string {
    let expression = "";

    let openingBracket: string = "<p>{{ ";
    let closingBracket: string = " }}</p>";

    let variableName = CalculationSyntaxBuilder.BuildVariable(calcData);
    let isChild: boolean = calcData[0]?.childItem;
    let parentName = calcData[0].parentName;
    let childName: string = calcData[0].variable;

    switch (format) {
      case CalculationFormatOption.Decimal:
        if (isChild) {
          expression = `format_decimal(repeating_item_sum(${parentName}, "item.${childName}"), ${decimalPlaces}, False)`;
          break;
        }
        expression = `format_decimal(${variableName}, ${decimalPlaces}, False)`;

        break;

      case CalculationFormatOption.DecimalComma:
        if (isChild) {
          expression = `format_decimal(repeating_item_sum(${parentName}, "item.${childName}"), ${decimalPlaces})`;
          break;
        }
        expression = `format_decimal(${variableName}, ${decimalPlaces})`;

        break;

      case CalculationFormatOption.Money:
        if (isChild) {
          expression = `currency(repeating_item_sum(${parentName}, "item.${childName}"))`;
          break;
        }
        expression = `currency(${variableName})`;

        break;

      case CalculationFormatOption.Number:
        if (isChild) {
          expression = `repeating_item_sum(${parentName}, "item.${childName}")`;
          break;
        } else if (calcData[0]) {
          expression = `(${variableName}) | float`;
        }
        break;

      case CalculationFormatOption.Word:
        if (isChild) {
          expression = `numbers_to_words(repeating_item_sum(${parentName}, "item.${childName}"))`;
          break;
        }
        expression = `numbers_to_words(${variableName})`;
        break;

      case CalculationFormatOption.Ordinal:
        if (isChild) {
          expression = `ordinal_number(repeating_item_sum(${parentName}, "item.${childName}"))`;
          break;
        }
        expression = `ordinal_number(${variableName})`;
        break;
    }

    //Then set highlighting and formatting options and finally wrap in {{ }}
    let text_expression =
      '<span style="background-color:yellow">$expression</span>'.replace(
        "$expression",
        expression
      );

    expression = openingBracket + text_expression + closingBracket;

    return expression;
  };

  static BuildVariable: (data: NumberChildOutputData[]) => string = function (
    data: NumberChildOutputData[]
  ): string {
    let expr = "";

    let x = 0;
    while (x < data.length) {
      if (x == 0) {
        expr = data[x].variable;
      }
      expr = expr + data[x].joiningOperand + data[x].variablen;
      x++;
    }

    return expr;
  };
}

export enum CalculationFormatOption {
  Number,
  Money,
  Decimal,
  DecimalComma,
  Word,
  Ordinal,
}
