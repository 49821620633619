import { ConditionalChild } from "../models/conditional-child.model";
import { RegexConstants } from "../../shared/RegexConstants";
import { VariableDataType } from "../../shared/variableTypes/VariableType";
import { ConditionalSimpleExpressionBuilder } from "./ConditionalSimpleExpressionBuilder";

export class ConditionalNumberExpressionBuilder {
  public static Build(data: ConditionalChild): string {
    /*Conditional Date can be tested for blank, in
     * that case it is like a simple conditional*/
    if (
      RegexConstants.REGEX_CONDITIONAL_BLANK.test(
        data.inputGtLtEq.toString()
      ) &&
      data.selectedWorkflowVariable.variableDataType == VariableDataType.Date
    ) {
      return ConditionalSimpleExpressionBuilder.Build(data);
    } else
      return `${data.selectedWorkflowVariable.id} ${data.optionGtLtEqValue} ${data.inputGtLtEq}`;
  }
}
