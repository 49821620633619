import { ISymbolTableEntry } from "./ISymbolTableEntry";
import { SymbolTableEntryScope } from "./SymbolTableHelper";

export const BuiltInVariables: ISymbolTableEntry[] = [
  { id: "key", scopes: [SymbolTableEntryScope.Text] },
  { id: "value", scopes: [SymbolTableEntryScope.Text] },
  { id: "item", scopes: [SymbolTableEntryScope.Text] },
  { id: "nesteditem", scopes: [SymbolTableEntryScope.Text] },
  { id: "ItemName", scopes: [SymbolTableEntryScope.Text] },
  { id: "ItemAttributeNumber", scopes: [SymbolTableEntryScope.Number] },
  { id: "loop", scopes: [SymbolTableEntryScope.Number] },
  { id: "i", scopes: [SymbolTableEntryScope.Number] },
];

export const BuiltInFilters: string[] = [
  "upper",
  "lower",
  "sort",
  "format",
  "length",
  "ItemAttribute",
  "true_values",
  "manual_line_breaks",
  "selectattr",
  "list",
  "verb_present",
  "session",
];

export const BuiltInNumberFilters: string[] = [
  "float",
  "int",
  "sum",
  "number",
  "round",
];
export const BuiltInDateFilters: string[] = [
  "day",
  "week",
  "month",
  "year",
  "days",
  "weeks",
  "months",
  "years",
];

export const BuiltInNumberFunctions: string[] = [
  "Decimals0Commas",
  "Decimals0NoCommas",
  "Decimals2Commas",
  "number_iterator",
  "number_iterator_ordinal",
  "number_iterator_numeric",
  "number_iterator_roman",
  "format_number_eu",
  "number_custom",
  "ordinal_number",
  "format_decimal",
  "currency",
  "repeating_item_sum",
];

/*String names of current Documate functions
 */
export const BuiltInFunctions: string[] = [
  "no_period",
  "indefinite_article",
  "quantity_noun",
  "commalist",
  "commalistoxford",
  "nextitemlist",
  "customseparatorlist",
  "semicolonlist",
  "numbers_to_words",
  "currency",
  "resolve_reference",
  "items",
  "fix_punctuation",
  "generate_docusign_anchor",
];

export const BuiltInTextFunctions: string[] = [
  "title_case",
  "capitalize",
  "replace",
  "user_info",
];

export const BuiltInDateFunctions: string[] = [
  "today",
  "format_date",
  "date_difference",
  "next_business_day",
  "prior_business_day",
  "date_interval",
  "last_day_of_month",
  "relative_date_difference",
];
