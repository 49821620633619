import { SimpleVariable } from "../../shared/variableTypes/SimpleVariable";
import { ComplexVariable } from "../../shared/variableTypes/ComplexVariable";
import { DateVariable } from "../../shared/variableTypes/DateVariable";

export class ConditionalChild {
  joiningBoolean: string;
  selectedWorkflowVariable: SimpleVariable | ComplexVariable;
  optionGtLtEqValue: ConditionalComparisonOption;
  inputGtLtEq: number | string | DateVariable;
  selectedMultipleChoice: string | number | SimpleVariable | ComplexVariable;
  inputChildMemberComparison: string;
}

export enum ConditionalComparisonOption {
  gt = ">",
  lt = "<",
  eq = "==",
  Value = "value",
  is = "IS",
  false = "false",
}
