import { SimpleVariable } from "./SimpleVariable";
import { VariableDataType, ComplexVariableType } from "./VariableType";
import { IComplexMember } from "../IComplexMember";
import { Dropdown } from "primeng";
import { DropdownOption } from "../Types";
import { SymbolTableEntryScope } from "../../syntax/AST/SymbolTableHelper";

export abstract class ComplexVariable
  extends SimpleVariable
  implements IComplexMember
{
  abstract complexMembers: DropdownOption[];

  addComplexMember(member: string) {
    this.complexMembers.push({ code: member, text: member });
  }

  constructor(
    id: string,
    text: string,
    variableDataType: VariableDataType,
    parent?: string
  ) {
    super(id, text, variableDataType, ComplexVariableType.Complex, parent);
  }
}
