import { Component, Input } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { Utils } from "../../../shared/Utils";
import {
  ConditionalChild,
  ConditionalComparisonOption,
} from "../../models/conditional-child.model";
import { ObjectComplexVariable } from "../../../shared/variableTypes/ObjectComplexVariable";
import { SimpleVariable } from "../../../shared/variableTypes/SimpleVariable";
import { VariableDataType } from "../../../shared/variableTypes/VariableType";
import { WorkflowService } from "../../../shared/workflow.service";
import { DropdownOption } from "../../../shared/Types";

@Component({
  selector: "app-repeat-conditional",
  templateUrl: "./conditional-repeat.component.html",
  styleUrls: ["./conditional-repeat.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConditionalRepeatComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ConditionalRepeatComponent,
    },
  ],
})
export class ConditionalRepeatComponent
  implements ControlValueAccessor, Validator
{
  @Input() soloChild: boolean = true;
  @Input() set selectedObjectVariable(variable: ObjectComplexVariable) {
    this._selectedObjectVariable = variable;
    //Filter out Object variables from the choice,
    //We have no business logic for them at this point.
    //Because this is a read only property, we have to make a new array.
    this.selectedObjectVariableRepeatingMembers =
      this._selectedObjectVariable.repeatingMembers.filter((v) => {
        return v.variableDataType != VariableDataType.Object;
      });
  }

  selectedObjectVariableRepeatingMembers: SimpleVariable[];

  workflowVariablesChildren: SimpleVariable[] = [];

  get selectedWorkflowVariable(): ObjectComplexVariable {
    return this._selectedObjectVariable;
  }

  public conditionalChild: ConditionalChild = new ConditionalChild();
  public form: FormGroup;

  public onChange: any = () => {};

  private _selectedObjectVariable: ObjectComplexVariable;

  constructor(private interview: WorkflowService, private fb: FormBuilder) {
    this.buildForm(this.fb);
    this.subscribeToFormChanges();
    this.subscribeToWorkflowVariables();
  }

  writeValue(obj: any) {}
  registerOnTouched(fn: any) {}
  setDisabledState(isDisabled: boolean) {}

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
    this.onChange(this.conditionalChild);
  }

  isComplexVariable(value: any) {
    return Utils.isComplexDataType(value?.variableDataType);
  }

  public get displayMode1(): boolean {
    return (
      this.conditionalChild.optionGtLtEqValue !==
      ConditionalComparisonOption.Value
    );
  }

  //Controls the subform for the value option.
  //Display modes 3,4,& 5 are subforms of this.
  public get displayMode2(): boolean {
    return (
      this.form.controls["OptionGtLtEqValue"].value ===
      ConditionalComparisonOption.Value
    );
  }
  //Tests the type of the selected variable to determine
  //If it has a multiple choice member. Displays dropdown
  //box to user for input.
  public get displayMode3(): boolean {
    if (!this.form.controls["SelectedRepeatingMember"].value) return false;
    return (
      "complexMembers" in
      <any>this.form.controls["SelectedRepeatingMember"].value
    );
  }

  //Tests the type of the selected variable to determine
  //If it's a non number complex variable. Displays text
  //box to user for input.
  public get displayMode4(): boolean {
    return (
      !this.isComplexVariable(
        this.form.controls["SelectedRepeatingMember"].value
      ) &&
      (<SimpleVariable>this.form.controls["SelectedRepeatingMember"].value)
        ?.variableDataType != VariableDataType.Number
    );
  }
  //Tests the type of the selected variable to determine
  //If it's a number complex variable. Displays number
  //box to user for input.
  public get displayMode5(): boolean {
    return (
      (<SimpleVariable>this.form.controls["SelectedRepeatingMember"].value)
        ?.variableDataType == VariableDataType.Number
    );
  }

  buildForm(fb: FormBuilder) {
    this.form = this.fb.group(
      {
        OptionGtLtEqValue: new FormControl(">"),
        InputGtLtEq: new FormControl(1),
        SelectedRepeatingMember: new FormControl(),
        InputChildMemberComparison: new FormControl(""),
        InputChildNumberComparison: new FormControl(1),
        InputChildComplexMemberComparison: new FormControl(),
      },
      { validators: this.validateForm }
    );
  }

  subscribeToFormChanges() {
    this.form.valueChanges.subscribe((value) => {
      this.conditionalChild.optionGtLtEqValue = value.OptionGtLtEqValue;
      this.conditionalChild.inputGtLtEq = value.InputGtLtEq;

      this.conditionalChild.selectedMultipleChoice =
        value.SelectedRepeatingMember;
      /*Assign value of inputChildMemberComparison based on displayMode. Because
       * we have form validation, the correct form value should not be null, except where
       * allowed. Becuase of initial conditions, the selection of the dropdown will be
       * null. This is by design.*/
      if (this.displayMode3) {
        this.conditionalChild.inputChildMemberComparison =
          value.InputChildComplexMemberComparison?.code;
      } else if (this.displayMode4) {
        this.conditionalChild.inputChildMemberComparison =
          value.InputChildMemberComparison;
      } else if (this.displayMode5) {
        this.conditionalChild.inputChildMemberComparison =
          value.InputChildNumberComparison;
      }
      this.onChange(this.conditionalChild);
    });

    this.form.controls["SelectedRepeatingMember"].valueChanges.subscribe(
      /*Null the value of the dropdown during change detection window.*/
      (newValue) => {
        this.form.controls["InputChildComplexMemberComparison"].setValue(null);
      }
    );
    this.form.controls["InputGtLtEq"].valueChanges.subscribe((newValue) => {
      //Null, negative, and zero check
      if (!newValue || newValue < 1) {
        this.form.controls["InputGtLtEq"].setValue(1);
      }
    });

    this.form.controls["InputChildNumberComparison"].valueChanges.subscribe(
      (newValue) => {
        //Null, negative, and zero check
        if (!newValue || newValue < 1) {
          this.form.controls["InputChildNumberComparison"].setValue(1);
        }
      }
    );
  }

  getSelectedRepeatingMemberOptions(): DropdownOption[] {
    return this.form.controls["SelectedRepeatingMember"].value.complexMembers;
  }
  subscribeToWorkflowVariables() {
    this.interview.workflowVariablesRepeatChildren.subscribe((x) => {
      this.workflowVariablesChildren = [];
      this.workflowVariablesChildren = x;
    });
  }

  validateForm(control: AbstractControl): ValidationErrors | null {
    let formGroup = <FormGroup>control;

    /*Validate according to display mode*/
    /*For display mode 1, ensure that gt,lt, or eq has a value
     * and that there is a number to compare to*/
    if (
      formGroup.controls["OptionGtLtEqValue"].value !==
      ConditionalComparisonOption.Value
    ) {
      if (
        !formGroup.controls["OptionGtLtEqValue"].value ||
        !formGroup.controls["InputGtLtEq"].value
      ) {
        return { DisplayMode1ValueRequired: true };
      }
      /*Validation passed*/
      return null;
    }

    /*DisplayMode2*/
    if (
      formGroup.controls["OptionGtLtEqValue"].value ===
        ConditionalComparisonOption.Value &&
      !formGroup.controls["SelectedRepeatingMember"].value
    ) {
      return { DisplayMode2NoSelectedRepeatingMember: true };
    }

    /*DisplayMode3*/
    if (
      formGroup.controls["OptionGtLtEqValue"].value ===
        ConditionalComparisonOption.Value &&
      "complexMembers" in
        <any>formGroup.controls["SelectedRepeatingMember"].value &&
      !formGroup.controls["InputChildComplexMemberComparison"].value
    ) {
      return { NoInputChildComplexMemberComparison: true };
    }
    /*Validation passed*/
    return null;
  }

  /*The validate method passes the status of the form
   * to parent components. The actual validation is performed
   * in validateForm.*/
  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.invalid) {
      return { invalid: true };
    }
    return null;
  }

  public readonly Utils = Utils;
}
