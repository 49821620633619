import { Component, Input } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { SimpleVariable } from "../../../shared/variableTypes/SimpleVariable";
import { WorkflowService } from "../../../shared/workflow.service";
import {
  ConditionalChild,
  ConditionalComparisonOption,
} from "../../models/conditional-child.model";
import { DateVariable } from "../../../shared/variableTypes/DateVariable";
import { SimpleConditionalVariable } from "../../../shared/variableTypes/SimpleConditionalVariable";

@Component({
  selector: "app-conditional-date",
  templateUrl: "./conditional-date.component.html",
  styleUrls: ["./conditional-date.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConditionalDateComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ConditionalDateComponent,
    },
  ],
})
export class ConditionalDateComponent
  implements ControlValueAccessor, Validator
{
  dateVariables: SimpleVariable[];
  ngDateVariables: SimpleVariable[];
  @Input() set selectedDateVariable(newValue: DateVariable) {
    this._selectedDateVariable = newValue;
    this.loadNgDateVariables();
  }
  public onChange = (newValue) => {};
  public form: FormGroup;
  public displayMode1: boolean = false;

  private _selectedDateVariable: DateVariable;
  private conditionalChild: ConditionalChild = new ConditionalChild();

  constructor(
    private fb: FormBuilder,
    private workflowService: WorkflowService
  ) {
    this.buildForm();
    this.subscribeToFormValueChanges();
    this.subscribeToWorkflowVariables();

    this.initializeOutput();
  }

  setDisabledState(isDisabled: boolean) {}

  registerOnTouched(fn: any) {}

  writeValue(obj: any) {}

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
    this.onChange(this.conditionalChild);
  }

  initializeOutput() {
    this.loadNgDateVariables();
    this.form.controls["OptionGtLtEq"].setValue(">");
  }

  loadNgDateVariables() {
    this.ngDateVariables = this.dateVariables.filter((v) => {
      return v.id != this._selectedDateVariable?.id;
    });
  }

  subscribeToFormValueChanges() {
    this.form.controls["OptionGtLtEq"].valueChanges.subscribe((newValue) => {
      this.conditionalChild.optionGtLtEqValue = newValue;
      this.onChange(this.conditionalChild);
    });

    this.form.controls["InputBoolean"].valueChanges.subscribe((newValue) => {
      this.conditionalChild.optionGtLtEqValue = newValue.optionGtLtEqValue;
      this.onChange(this.conditionalChild);
    });

    this.form.controls["selectedDateComparisonVariable"].valueChanges.subscribe(
      (newValue) => {
        this.conditionalChild.inputGtLtEq = newValue?.id;
        this.conditionalChild.selectedMultipleChoice = newValue?.id;
        this.displayMode1 = newValue?.id == "Blank";

        this.conditionalChild.optionGtLtEqValue =
          this.displayMode1 == true
            ? ConditionalComparisonOption.is
            : ConditionalComparisonOption.gt;
        this.onChange(this.conditionalChild);
      }
    );
  }

  subscribeToWorkflowVariables() {
    this.workflowService.workflowVariablesDate.subscribe((value) => {
      this.dateVariables = value;
      if (this.dateVariables[this.dateVariables.length - 1].id != "Blank") {
        this.dateVariables.push(
          new SimpleConditionalVariable("Blank", "Blank", "Blank")
        );
      }
    });
  }
  buildForm() {
    this.form = this.fb.group({
      InputBoolean: new FormControl(),
      OptionGtLtEq: new FormControl(),
      selectedDateComparisonVariable: new FormControl(),
    });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (
      this.form.controls["OptionGtLtEq"].value &&
      this.form.controls["selectedDateComparisonVariable"].value
    )
      return null;
    else return { FormContainsNull: true };
  }
}
