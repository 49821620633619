<form [formGroup]="form">
  <div>
    <app-conditional-child-join
      id="divJoiningBoolean"
      *ngIf="showJoiningBoolean()"
      formControlName="JoiningBoolean"
    ></app-conditional-child-join>

    <div class="form-group">
      <app-variable-dropdown
        [options]="workflowVariables"
        formControlName="SelectedL0Variable"
        [appendTo]="'body'"
      ></app-variable-dropdown>
    </div>

    <app-repeat-conditional
      id="subviewObjectSelected"
      *ngIf="showAppRepeatConditional()"
      formControlName="ConditionalObjectChildExpression"
      [soloChild]="soloChild"
      [selectedObjectVariable]="form.controls['SelectedL0Variable'].value"
    ></app-repeat-conditional>

    <app-conditional-date
      id="subviewDateSelected"
      *ngIf="showAppDateConditional()"
      formControlName="ConditionalDateChildExpression"
      [selectedDateVariable]="form.controls['SelectedL0Variable'].value"
    ></app-conditional-date>

    <app-conditional-number
      id="subviewNumberSelected"
      *ngIf="showAppNumberConditional()"
      formControlName="ConditionalNumberChildExpression"
    ></app-conditional-number>

    <app-conditional-multi-choice
      id="subviewMultiChoiceSelected"
      *ngIf="showAppMultiChoiceConditional()"
      formControlName="ConditionalMultiChoiceChildExpression"
      [selectedMultiChoiceVariable]="form.controls['SelectedL0Variable'].value"
    ></app-conditional-multi-choice>
  </div>
</form>
