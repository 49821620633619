import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { ComplexVariableType, VariableDataType } from "./VariableType";
import { SymbolTableEntryScope } from "../../syntax/AST/SymbolTableHelper";
import { ISimpleFormatOptions } from "../ISimpleFormatOptions";

export abstract class SimpleVariable implements ISimpleFormatOptions {
  public id: string;
  public text: string;
  abstract simpleFormatOptions: SimpleFormattingOptions[];
  private __complexVarType: ComplexVariableType;
  private __variableDataType: VariableDataType;
  public itemParent: string;
  public itemChild: boolean = false;
  public symbolTableScopes: SymbolTableEntryScope[] = [
    SymbolTableEntryScope.Text,
  ];

  get variableDataType() {
    return this.__variableDataType;
  }

  get complexVarType() {
    return this.__complexVarType;
  }

  constructor(
    id: string,
    text: string,
    variableDataType: VariableDataType,
    complexVarType: ComplexVariableType = ComplexVariableType.Simple,
    itemParent?: string
  ) {
    this.id = id;
    this.text = text;
    this.__variableDataType = variableDataType;

    this.__complexVarType = complexVarType;

    if (itemParent) {
      this.itemChild = true;
      this.itemParent = itemParent;
    }
  }
}
