import { LoginSettings } from "../shared/LoginSettings";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SettingsService } from "../shared/settings.service";
import { Subject } from "rxjs";
import { ApiService } from "../shared/api.service";
import { rollbarFactory } from "../shared/rollbarerrorhandler.service";

export class OfficeUpload {
  public static uploadStatus: Subject<string> = new Subject<string>();
  public static uploadProgress: Subject<number> = new Subject<number>();

  static wait(ms: number): Promise<any> {
    return new Promise((r) => setTimeout(r, ms));
  }

  static retryOperation<T>(operation, delay, retries): Promise<T> {
    return new Promise((resolve, reject) => {
      return operation()
        .then(resolve)
        .catch((reason) => {
          if (retries > 0) {
            return OfficeUpload.wait(delay)
              .then(
                OfficeUpload.retryOperation.bind(
                  null,
                  operation,
                  delay,
                  retries - 1
                )
              )
              .then(resolve)
              .catch(reject);
          }
          return reject(reason);
        });
    });
  }
  public static uploadDocument(
    httpClient: HttpClient,
    apiService: ApiService,
    fileName: string
  ) {
    /*Get the file for uploading. Comes in 'slices' of
     * specified size, and those slices must be reassembled
     * before uploading.*/
    OfficeUpload.retryOperation<Office.File>(
      OfficeUpload.GetOfficeFile,
      500,
      10
    )
      .catch((reason) => {
        rollbarFactory().warning(
          `There was an error when attempting to open the office file. ${reason}`
        );

        this.uploadStatus.next("Could not open file, please retry");
        this.uploadProgress.next(0);
        this.wait(5000).then(() => {
          OfficeUpload.uploadStatus.next("");
        });
        return;
      })
      .then((file: Office.File) => OfficeUpload.GetOfficeFileSlices(file))
      .then((slices) => OfficeUpload.concatSlices(slices))
      .then((file) => {
        OfficeUpload.SendFileToDocumate(file, httpClient, apiService, fileName);
      })
      .catch((error) => {
        rollbarFactory().error(`Error during file upload: ${error}`);
      });
  }

  static GetOfficeFileName(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      /*Get file properties so that we can use
       * the file name when uploading.*/
      Office.context.document.getFilePropertiesAsync((result) => {
        if (result.status == Office.AsyncResultStatus.Succeeded) {
          let fileName = result.value.url.split("\\").pop().split("/").pop();
          resolve(fileName);
        } else {
          reject();
        }
      });
    });
  }

  static GetOfficeFile(context: Word.RequestContext): Promise<Office.File> {
    OfficeUpload.uploadStatus.next("Opening file");
    return new Promise((resolve, reject) => {
      console.debug("GetOfficeFile(): Inside promise");
      console.debug("Calling getFileAsync");
      Office.context.document.getFileAsync(
        Office.FileType.Compressed,
        { sliceSize: 4194304 /*4 MB*/ },
        (result) => {
          switch (result.status.toString()) {
            case "failed":
              rollbarFactory().warning(
                "GetOfficeFile: Get open file returned an error -  " +
                  JSON.stringify(result.error)
              );
              reject(result.error);
              break;
            case "succeeded":
              OfficeUpload.uploadProgress.next(33);
              resolve(result.value);
              break;
          }
        }
      );
    });
  }

  /*Wrapper method that calls getSliceAsync once for each
   * file slice, in order so that the file can later be
   * reconstructed and uploaded to the backend.*/
  static GetOfficeFileSlices(file: Office.File): Promise<Uint8Array[]> {
    let sliceArray = new Array<Uint8Array>(file.sliceCount);
    OfficeUpload.uploadStatus.next("Saving file");

    return [...Array<Uint8Array[]>(file.sliceCount)].reduce<
      Promise<Uint8Array[]>
    >(
      (p, sliceArray, i) =>
        p.then(
          (_) =>
            new Promise((resolve, reject) => {
              console.debug("GetAllSlices: " + i);
              file.getSliceAsync(i, (result) => {
                if (result.status.toString() == "succeeded") {
                  _[i] = Uint8Array.from(result.value.data);
                  if (i == file.sliceCount - 1) {
                    //Close file, we are done
                    console.debug("All slices retrieved, closing file");
                    file.closeAsync();
                    OfficeUpload.uploadProgress.next(66);
                  }

                  resolve(_);
                } else {
                  rollbarFactory().warn(
                    `GetOfficeFileSlices.GetAllSlices error: ${result}`
                  );
                  file.closeAsync();
                  reject(result.error);
                }
              });
            })
        ),
      Promise.resolve(sliceArray)
    );
  }

  static concatSlices(slices: Uint8Array[]): Promise<Uint8Array> {
    let file: Uint8Array = new Uint8Array();

    slices.forEach((slice, sliceIndex) => {
      if (sliceIndex === 0) {
        file = Uint8Array.from(slice);
      } else {
        let newArray = new Uint8Array(file.length + slice.length);
        newArray.set(file);
        newArray.set(slice, file.length);
        file = newArray;
      }
    });

    return Promise.resolve(file);
  }

  static SendFileToDocumate(
    file: Uint8Array,
    httpClient: HttpClient,
    apiService: ApiService,
    fileName: string
  ) {
    OfficeUpload.uploadStatus.next("Uploading file");
    OfficeUpload.uploadProgress.next(80);

    console.debug("UploadFile: Creating Blob......");
    let blob = new Blob([file.buffer], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    console.debug("Done.");

    const formData = new FormData();
    formData.append("file", blob, fileName);
    formData.append("folder", "templates");

    console.debug("UploadFile: Uploading....");

    apiService.UploadDocument(formData).then((pResult) => {
      if (!pResult.isError) {
        OfficeUpload.uploadStatus.next("Done!");
        OfficeUpload.uploadProgress.next(100);

        this.wait(3000).then(() => {
          OfficeUpload.uploadStatus.next("");
          OfficeUpload.uploadProgress.next(0);
        });

        console.info("SendFileToGavel: " + fileName + " uploaded to Gavel!");
        console.debug(fileName + " " + file.length + " bytes");
      } else {
        rollbarFactory().warn(
          `Error while uploading file to Gavel: ${pResult}`
        );
        OfficeUpload.uploadStatus.next(
          "There was an error uploading your file"
        );
        OfficeUpload.uploadProgress.next(100);

        this.wait(3000).then(() => {
          OfficeUpload.uploadStatus.next("");
          OfficeUpload.uploadProgress.next(0);
        });
      }
    });
  }
}
