<div class="text-center pb-0 mb-2 container-fluid">
  <form [formGroup]="form">
    <div *ngIf="gated" class="text-center small p-2 my-2" style="background-color: #d4c5fc">
      You have exceeded your template limit. Manage your <a href={{manageTemplatesLink}}>existing templates</a> or learn about our <a href={{plansLink}}>plans</a>.
    </div>
    <p-dropdown
      [virtualScroll]="true"
      [appendTo]="'body'"
      [options]="workflowOutputDocuments"
      [showClear]="false"
      formControlName="selectedOutputDocument"
      optionLabel="code"
      filter="true"
      [placeholder]="PLACEHOLDER"
    >
    </p-dropdown>
    <button
      #uploadButton
      (click)="uploadDocument()"
      class="btn btn-custom border btn-block py-2 pl-3 pr-0 mr-0"
      [disabled]="disabled"
    >
      {{ UPLOAD_FILE_BUTTON
      }}<mat-icon
        style="font-size: medium"
        class="pl-0 ml-0"
        [matTooltip]="uploadToolTip"
        >help</mat-icon
      >
    </button>
    <mat-progress-bar
      class="btn-custom"
      [hidden]="false"
      [value]="DocumentUploadProgress"
    ></mat-progress-bar>
    <p class="text-green" [innerHTML]="DocumentUploadStatus"></p>
  </form>
</div>
