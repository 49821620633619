export class LoginSettingsModel {
  constructor(
    /**
     * The currently selected value from the
     * LoginViewModel.DomainChoices array.
     */
    public selectedDomain: string,

    /**
     * The current value for the
     * subdomain the user will be logging into.
     */
    public subdomain: string,

    /**
     * The custom domain if the
     * user enters one
     */
    public customDomain: string,

    /**
     * The API Key provided by the user.
     */
    public APIKey: string
  ) {}
}
