export class Constants {
  static UPLOAD_DOC_TOOLTIP =
    "The first time you add a document, you must upload it into the workflow’s Output Documents tab. After the first time, you can use this button to update and replace that document automatically.";
  static CHOOSE_WORKFLOW_OUTPUT_DOC = "Select a document to replace";
  static NO_WORKFLOW_OUTPUT_DOC = "No output documents in workflow";
  static UPLOAD_FILE_BUTTON = "Update in Gavel";
  static TYPE_TO_SEARCH = "Select or type to search";
  static TYPE_TO_INPUT = "Select or enter value";
  static PARAGRAPH_SYNTAX_ERROR = `<li>Paragraph Conditions must be on their own line. Please skip a line so that this conditional syntax is not on the same line as any text.</li>`;
  static TABLE_STATEMENT_SYNTAX_ERROR =
    "<li>{%tr %} syntax must be inside a table. Please check that this syntax is contained inside the table and not before or after the table.</li>";

  static TABLE_ROW_SYNTAX_ERROR =
    "<li>Table Row Conditions must be on their own line. Please skip a row so that this conditional syntax is not in the same table row as any text.</li>";

  static NESTED_ATTRIBUTE_OUTSIDE_NESTED_FOR_LOOP_ERROR =
    "<li>nested attribute outside nested for loop</li>";
  static NESTED_LOOP_OUTSIDE_FOR_LOOP_ERROR =
    "<li>nested loop outside for loop</li>";
  static IF_STATEMENT_MATCH_ERROR =
    "<li>if statement without matching endif</li>";
  static FOR_STATEMENT_MATCH_ERROR =
    "<li>For statement without matching endfor</li>";
  static ENDIF_STATEMENT_MATCH_ERROR =
    "<li>endif statement without matching if</li>";
  static ENDFOR_STATEMENT_MATCH_ERROR =
    "<li>endfor statement without matching for</li>";
  static SET_STATEMENT_MATCH_ERROR =
    "<li>set statement without matching endset</li>";
  static ENDSET_STATEMENT_MATCH_ERROR =
    "<li>endset statement without matching set</li>";

  static ITEM_STATEMENT_MATCH_ERROR =
    "<li>Repeating item must be wrapped in {% for item in ItemName %}</li>";

  static MISSING_BRACES_SYNTAX_ERROR = `<li>Missing opening or closing braces</li>`;

  static VARIABLE_OR_FUNCTION_NOT_IN_WORKFLOW =
    "is not a variable or function in this workflow. You can still run the workflow, but you will need to add this variable to capture the value you want inserted in this location.";

  static SYNTAX_INVALID = "is not valid syntax.";

  static SYNTAX_COND_INVALID = "Conditions must use the delimiter {%..%}";

  static SYNTAX_EXPR_INVALID = "Expressions must use the delimiter {{..}}";

  static LOGIN_ERROR =
    "We could not log you in. Please \
  check your login info or sign up for an account at Gavel.io!";

  static LOGIN_NO_ACCOUNT =
    'Don\'t have a Gavel account?<br />Get a free trial at  <a href="https://www.gavel.io/">gavel.io</a>';

  static LOGIN_APIKEY_HELPMSG =
    '<a href="https://help.documate.org/articles/no-code-word-add-in">How to find your API key</a>';

  static CHECK_FOR_ERRORS = `Check For Errors`;

  static LOGIN_SETTINGS_KEY = "__loginSettings";

  static LOGIN_SETTINGS_ACTIVE_KEY = "__loginSettings";

  static LOGIN_SETTINGS_MAP_KEY = "__loginSettingsMap";

  static INITIAL_WORKFLOW_SUCCESS = "Workflow downloaded. Navigating to editor";

  static SETTING_KEY_NOT_FOUND = "Settings key not found";

  static INITIAL_WORKFLOW_FAIL = "Could not download initial workflow. ";

  static MISMATCH_DATE_SCOPE =
    "Mismatched datatype: Please change variable to a date question or remove this syntax.";

  static MISMATCH_NUMBER_SCOPE =
    "Mismatched datatype: Please change variable to a number question or remove this syntax.";

  static MISMATCH_TEXT_SCOPE =
    "Mismatched datatype: Please change variable to a text question or remove this syntax.";

  static PERCENT_SIGN_IN_LOGIC =
    "Percent sign is not supported in logic. Please remove the “%” from your conditional statement.";

  static NEWLINE_IN_LOGIC =
    "Newline character is not supported in logic. Please remove the “\n” from your conditional statement.";

  static PARENTHESIS_SYNTAX_ERROR = `<li>Missing opening or closing parenthesis</li>`;

  static NOTIFICATION_CSS_ERROR = "alert-danger alert";
  static NOTIFICATION_CSS_INFO = "text-center notification-info p-2";
  static NOTIFICATION_CSS_WARNING = "notification-warning alert";
  static FUNCTION_MISSING_REQUIRED_COMPONENT =
    "This function is missing a required component. Please delete and re-insert this syntax, ensuring a selection is made in each field.";

  static MATH_DIVIDE = "/";
  static MATH_ADD = "+";
  static MATH_SUBTRACT = "-";
  static MATH_MULTIPLY = "*";
  static CALCULATE_TOOLTIP =
    "Click in each field to select a variable or type a number.";
}
