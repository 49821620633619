export class RegexConstants {
  static REGEX_CONDITIONAL_BLANK = RegExp(/^$|Blank/);
  static REGEX_MULTICHOICE_OR_CLIO = RegExp(
    /\w+\[([‘'"“’”][^‘'"“’”]*[‘'"“’”])\]/
  );
  static regexParaLineTest = RegExp(/^[\r\n\f\v ]*{%p[^%]+%}[\r\n\f\v ]*$/);
  static regexOpenPhrase = RegExp(/^{%(\s|[\x00-\x1F])*if.*%}$/);
  static regexClosePhrase = RegExp(
    /^{%(\s|[\x00-\x1F])*endif(\s|[\x00-\x1F])*%}$/
  );
  static regexParenthesisTest = RegExp(/[()]/);

  static regexOpenPara = RegExp(/^{%p(\s|[\x00-\x1F])*if.*%}$/);
  static regexClosePara = RegExp(
    /^{%p(\s|[\x00-\x1F])*endif(\s|[\x00-\x1F])*%}$/
  );

  static regexOpenForPara = RegExp(/^{%p(\s|[\x00-\x1F])*for.*%}$/);
  static regexCloseForPara = RegExp(
    /^{%p(\s|[\x00-\x1F])*endfor(\s|[\x00-\x1F])*%}$/
  );

  static regexOpenForPhrase = RegExp(/^{%(tr)?(\s|[\x00-\x1F])*for.*%}$/);
  static regexCloseForPhrase = RegExp(
    /^{%(tr)?(\s|[\x00-\x1F])*endfor(\s|[\x00-\x1F])*%}$/
  );

  static regexTableRow = RegExp(/{%tr/);

  static regexTableRowExclusive = RegExp(/^{%tr[^%]+%}$/);

  static regexOpenSetPhrase = RegExp(/^{%(\s|[\x00-\x1F])*set.*%}$/);
  static regexCloseSetPhrase = RegExp(
    /^{%(\s|[\x00-\x1F])*endset(\s|[\x00-\x1F])*%}$/
  );

  static regexOpenSetPara = RegExp(/^{%p(\s|[\x00-\x1F])*set.*%}$/);
  static regexCloseSetPara = RegExp(
    /^{%p(\s|[\x00-\x1F])*endset(\s|[\x00-\x1F])*%}$/
  );

  static regexItemStatement = RegExp(
    /^{{(\s|[\x00-\x1F])*item(\.(\w+))?(\s|[\x00-\x1F])*}}$/
  );

  static regexChildItem = RegExp(/\[i\]/);

  static regexNestedItemStatement = RegExp(
    /^{{(\s|[\x00-\x1F])*nesteditem(\.(\w+))?(\s|[\x00-\x1F])*}}$/
  );

  static regexForNestedItemLoop = RegExp(
    /^{%(p|tr)?(\s|[\x00-\x1F])*for(\s|[\x00-\x1F])*nesteditem.*%}$/
  );

  static regexForCustomDomain = RegExp(
    "^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$"
  );

  static regexClioIdentifier = RegExp(
    /^[\w+-_]+\['[^\[\]]+'\](?:\[i\])?(?:\.\w+(?:\[i\])?)*/
  );
  static regexStatementSyntax = RegExp(/\{{1,}([^%}]*)\}{1,}/);

  static regexParagraphSyntax = RegExp(/{%p([^%}]*)%}/);

  static regexPhraseSyntax = RegExp(/{%(?=[^p])(?=[^tr])([^%]+)%}/);

  static regexTableSyntax = RegExp(/{%tr(.*)%}/);
}
