<div id="toggle1" class="pt-2" data-parent="#accordion">
  <div class="form-group">
    <app-variable-dropdown
      [formControl]="selectedVariableControl"
      [appendTo]="'body'"
      [options]="simpleVariables"
    ></app-variable-dropdown>
  </div>
  <div class="form-group" [hidden]="selectedVariable?.variableDataType !== VariableDataType.Docusign">
    <div class="d-table w-100">
      <div class="d-table-cell" style="width: 90px">
        <label class="pr-2">For Recipient</label>
      </div>
      <div class="d-table-cell">
        <p-dropdown
          [options]="availableRecipients"
          [appendTo]="'body'"
          [showClear]="false"
          [(ngModel)]="selectedRecipientValue"
        >
        </p-dropdown>
      </div>
    </div>
  </div>
  <div class="form-group" [hidden]="!selectedVariable">
    <div class="d-table w-100">
      <div class="d-table-cell" style="width: 90px">
        <label class="pr-2">Format as</label>
      </div>
      <div class="d-table-cell">
        <p-dropdown
          [options]="availableFormats"
          [appendTo]="'body'"
          [showClear]="false"
          optionLabel="text"
          [(ngModel)]="selectedFormatId"
        >
        </p-dropdown>
      </div>
    </div>
  </div>
  <div class="form-group">
    <button #insertBtn (click)="run()" class="btn btn-custom py-1">
      Insert
    </button>
  </div>
</div>
