import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ConditionalExpressionBuilder } from "../helpers/ConditionalExpressionBuilder";
import { Utils } from "../../shared/Utils";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from "@angular/forms";
import {
  ConditionalChild,
  ConditionalComparisonOption,
} from "../models/conditional-child.model";
import { ComplexVariable } from "../../shared/variableTypes/ComplexVariable";
import { WorkflowService } from "../../shared/workflow.service";
import Rollbar from "rollbar";
import { RollbarService } from "../../shared/rollbarerrorhandler.service";
import { ConditionalType } from "../helpers/ConditionalExpressionUtils";

@Component({
  selector: "app-conditional",
  templateUrl: "./conditional-parent.component.html",
  styleUrls: ["./conditional-parent.component.css"],
})
export class ConditionalParentComponent implements OnInit {
  @Input("type") conditionalType: ConditionalType;
  @ViewChild("insertBtn") insertBtn: ElementRef<HTMLButtonElement>;
  public workflowVariablesConditional: ComplexVariable[] = [];

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private interview: WorkflowService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private cd: ChangeDetectorRef
  ) {
    this.subscribeToWorkflowVariables();
  }

  ngOnInit() {
    this.buildForm();
  }

  subscribeToWorkflowVariables() {
    this.interview.workflowVariablesConditional.subscribe(
      (value: ComplexVariable[]) => {
        this.workflowVariablesConditional = value;
      }
    );
  }

  get children(): FormArray {
    return this.form.get("children") as FormArray;
  }

  buildForm() {
    this.form = this.fb.group({
      children: this.fb.array([]),
    });
    this.addConditionalChild();
  }

  showAdd(): boolean {
    let firstChild = <ConditionalChild>this.children.controls[0].value;
    if (firstChild?.optionGtLtEqValue == ConditionalComparisonOption.Value) {
      return false;
    } else {
      return true;
    }
  }

  showRemove(): boolean {
    if (this.children.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  isSoloChild(): boolean {
    return this.children.length == 1;
  }

  addConditionalChild() {
    let child = this.fb.control({
      header: false,
      soloChild: false,
      workflowVariablesConditional: this.workflowVariablesConditional,
    });

    this.children.push(child);

    this.cd.detectChanges();
  }

  removeConditionalChild() {
    this.children.removeAt(this.children.length - 1);
  }

  async insertConditionalSyntax() {
    let childExpressions: ConditionalChild[] = [];
    this.children.controls.forEach((child) => {
      childExpressions.push(child.value);
    });
    return Word.run(async (context) => {
      // insert a phrase, wrapping the current selection
      console.debug("inserting html into document....");

      let range = context.document.getSelection();

      range.load("font, paragraphs, lists, lineSpacing");

      await context.sync();

      let fontName = range.font.name;
      let fontSize = range.font.size;
      let lineSpacingFirstParagraph = range.paragraphs.items[0].lineSpacing;
      let lineSpacingLastParagraph =
        range.paragraphs.items[range.paragraphs.items.length - 1].lineSpacing;

      let expr: any = ConditionalExpressionBuilder.Build(
        this.conditionalType,
        childExpressions,
        fontName,
        fontSize.toString()
      );

      if (this.conditionalType == ConditionalType.Phrase) {
        range.insertHtml(expr.openingExpression, Word.InsertLocation.start);
        range.insertHtml(expr.closingExpression, Word.InsertLocation.end);

        range.font.name = fontName;
        range.font.size = fontSize;

        await context.sync();
      } else {
        //ConditionalType.Paragraph

        //First we insert a paragraph object for the opening
        // and closing expressions,
        // taking care to preserve font type and sizing
        let paragraph1 = range.insertParagraph("", Word.InsertLocation.before);
        let paragraph2 = range.insertParagraph("", Word.InsertLocation.after);

        paragraph1.load("style, font, lineSpacing");
        paragraph2.load("style, font, lineSpacing");

        // Set the paragraph style = normal to avoid
        // inserting syntax into a list
        paragraph1.styleBuiltIn = "Normal";
        paragraph1.lineSpacing = lineSpacingFirstParagraph;

        paragraph2.styleBuiltIn = "Normal";
        paragraph2.lineSpacing = lineSpacingLastParagraph;

        //Lastly we insert our html into the paragraph
        //we just inserted
        paragraph1.insertHtml(
          expr.openingExpression,
          Word.InsertLocation.start
        );

        paragraph2.insertHtml(
          expr.closingExpression,
          Word.InsertLocation.start
        );

        paragraph1.font.name = fontName;
        paragraph1.font.size = fontSize;

        paragraph2.font.name = fontName;
        paragraph2.font.size = fontSize;
        await context.sync();
      }
    })
      .catch((error) => {
        this.rollbar.warn("Error during InsertConditionalSyntax", error);
      })
      .finally(() => {
        Utils.blurButton(this.insertBtn);
      });
  }
}
