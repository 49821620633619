import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { VariableDataType } from "./VariableType";
import { ComplexVariable } from "./ComplexVariable";
import { DropdownOption } from "../Types";
import { SymbolTableEntryScope } from "../../syntax/AST/SymbolTableHelper";

export class CheckboxVariable extends ComplexVariable {
  public complexMembers: DropdownOption[] = [];

  public simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "default",
      text: "Standard",
    },
    {
      code: "checked",
      text: "Checked Values",
    },
    {
      code: "upper",
      text: "Uppercase",
    },
    {
      code: "first",
      text: "First Letter Capital",
    },
  ];
  public symbolTableScopes: SymbolTableEntryScope[] = [
    SymbolTableEntryScope.Text,
  ];

  itemParent: string;
  itemChild: boolean = false;
  label: string;
  constructor(
    handle: string,
    display: string,
    label: string,
    complexMembers: string[],
    itemParent?: string
  ) {
    super(handle, display, VariableDataType.Checkboxes, itemParent);

    this.label = label;
    complexMembers.forEach((m) => {
      this.addComplexMember(m);
    });
    this.addComplexMember("Blank");
  }
}
