import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { VariableDataType } from "./VariableType";
import { ComplexVariable } from "./ComplexVariable";
import { DropdownOption } from "../Types";

export class BooleanVariable extends ComplexVariable {
  public simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "default",
      text: "Standard",
    },
  ];
  public complexMembers: DropdownOption[] = [];

  itemParent: string;
  itemChild: boolean = false;
  label: string;

  constructor(
    handle: string,
    display: string,
    label: string,
    itemParent?: string
  ) {
    super(handle, display, VariableDataType.Boolean, itemParent);
    this.label = label;
    this.complexMembers.push(
      { code: "yes", text: "yes" },
      { code: "no", text: "no" },
      { code: "Blank", text: "Blank" }
    );
  }
}
