export class DateOutputData {
  public dateValue1: string;
  public dateValue2?: string;
  public dateOperation: DateOperation;
  public mathOperation: string;
  public displayValue: DateDisplayValue;
  public dateDiffQuantity: number;
}

export enum DateDisplayValue {
  Days,
  Weeks,
  Years,
}

export enum DateOperation {
  Length,
  Difference,
}
