import { ConditionalChild } from "../models/conditional-child.model";

export class ConditionalBooleanExpressionBuilder {
  public static Build(data: ConditionalChild): string {
    let expr;

    if (
      data.optionGtLtEqValue == "IS" &&
      data.selectedMultipleChoice == "yes"
    ) {
      expr = data.selectedWorkflowVariable.id;
    } else if (
      data.optionGtLtEqValue == "false" &&
      data.selectedMultipleChoice == "no"
    ) {
      expr = data.selectedWorkflowVariable.id;
    } else if (
      data.optionGtLtEqValue == "false" &&
      data.selectedMultipleChoice == "Blank"
    ) {
      expr = data.selectedWorkflowVariable.id;
    } else if (
      data.optionGtLtEqValue == "IS" &&
      data.selectedMultipleChoice == "Blank"
    ) {
      expr = ` not ${data.selectedWorkflowVariable.id}`;
    } else {
      expr = data.selectedWorkflowVariable.id + " == False";
    }

    return expr;
  }
}
