import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { TextVariable } from "./variableTypes/TextVariable";
import { SimpleVariable } from "./variableTypes/SimpleVariable";
import { NumberVariable } from "./variableTypes/NumberVariable";
import { CheckboxVariable } from "./variableTypes/CheckboxVariable";
import { BooleanVariable } from "./variableTypes/BooleanVariable";
import { DropdownVariable } from "./variableTypes/DropdownVariable";
import { DateVariable } from "./variableTypes/DateVariable";
import { FileVariable } from "./variableTypes/FileVariable";
import { TextAreaVariable } from "./variableTypes/TextAreaVariable";
import { RadioVariable } from "./variableTypes/RadioVariable";
import { NoteVariable } from "./variableTypes/NoteVariable";
import { ButtonVariable } from "./variableTypes/ButtonVariable";
import { FileObjectVariable } from "./variableTypes/FileObjectVariable";
import { ObjectComplexVariable } from "./variableTypes/ObjectComplexVariable";
import { SimpleConditionalVariable } from "./variableTypes/SimpleConditionalVariable";
import { FilterSimpleVariables } from "./filters/FilterSimpleVariables";
import { FilterConditionalVariables } from "./filters/FilterConditionalVariables";
import { FilterDateVariables } from "./filters/FilterDateVariables";
import { FilterNumberVariables } from "./filters/FilterNumberVariables";
import { FilterRepeatVariables } from "./filters/FilterRepeatVariables";
import { FilterSignatureVariables } from "./filters/FilterSignatureVariables";
import { Constants } from "./Constants";
import until from "async/until";
import { InitialWorkflowResult } from "./InitialWorkflowResult";
import { FetchResult } from "./FetchResult";
import { ApiService } from "./api.service";
import { FetchWorkflowVariableResult } from "./FetchWorkflowVariableResult";
import { RollbarService } from "./rollbarerrorhandler.service";
import { NotificationService, NotificationType } from "./notification.service";
import Rollbar from "rollbar";
import { SignatureVariable } from "./variableTypes/SignatureVariable";
import { DocusignConfig } from "../signature/signature.component";

export enum WorkflowServiceState {
  READY,
  BUSY,
}

@Injectable({
  providedIn: "root",
})
export class WorkflowService {
  serviceState = new BehaviorSubject<WorkflowServiceState>(
    WorkflowServiceState.READY
  );

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  private workflowList: string[]; // = new Array();

  private __repeatingChildren: SimpleVariable[] = new Array<SimpleVariable>();

  private __workflowVariablesSimple = new Array<SimpleVariable>();
  workflowVariablesSimple = new ReplaySubject<SimpleVariable[]>(1);

  private __workflowVariablesConditional = new Array<SimpleVariable>();
  workflowVariablesConditional = new ReplaySubject<SimpleVariable[]>(1);

  private __workflowVariablesNumber = new Array<SimpleVariable>();
  workflowVariablesNumber = new ReplaySubject<SimpleVariable[]>(1);

  private __workflowVariablesDate = new Array<SimpleVariable>();
  workflowVariablesDate = new ReplaySubject<SimpleVariable[]>(1);

  private __workflowVariablesRepeat = new Array<SimpleVariable>();
  workflowVariablesRepeat = new ReplaySubject<SimpleVariable[]>(1);

  private __workflowVariablesRepeatChildren = new Array<SimpleVariable>();
  workflowVariablesRepeatChildren = new ReplaySubject<SimpleVariable[]>(1);

  private __workflowVariablesSignature = new Array<SimpleVariable>();
  workflowVariablesSignature = new ReplaySubject<SimpleVariable[]>(1);

  private __workflowVariablesAll = new Array<SimpleVariable>();
  workflowVariablesAll = new ReplaySubject<SimpleVariable[]>(1);

  private __workflowVariablesMapById = new Map<string, SimpleVariable>();
  workflowVariablesMapById = new ReplaySubject<Map<string, SimpleVariable>>();

  private __workflowOutputDocuments = new Array<string>();
  workflowOutputDocuments = new ReplaySubject<string[]>(1);

  docusignConfig: DocusignConfig | undefined;

  isOverTemplateLimit: boolean = false;

  loginPath: string;

  public workflowVariableCount(): number {
    return this.__workflowVariablesAll?.length;
  }

  public selectedWorkflow: string = "";

  public get WorkflowList(): string[] {
    return this.workflowList;
  }

  private setWorkflowList(list) {
    this.workflowList = list;
  }

  public async FetchWorkflows(): Promise<FetchResult> {
    let __ = this;
    let fResult: FetchResult;

    await __.apiService
      .FetchWorkflows()
      .then(
        (f: FetchResult) => {
          fResult = f;
          if (!fResult.isError) {
            __.setWorkflowList(
              (<string[]>fResult.value).filter(
                /*Filter out workflows with empty names*/
                (workflow) => workflow != ""
              )
            );
            console.info(`Retrieved ${__.WorkflowList.length} workflows`);

            this.updateInterviewDisplayNames();
          }
        },
        (f: FetchResult) => {
          this.rollbar.warning(
            `Error retrieving workflows ${f?.errorDescription}`
          );
        }
      )
      .catch((e) => {
        this.rollbar.warning(`Error retrieving workflows ${e}`);
        Promise.reject(e);
      });

    return !fResult?.isError
      ? Promise.resolve(fResult)
      : Promise.reject(fResult);
  }

  private updateInterviewDisplayNames() {
    for (let x = 0; x < this.WorkflowList.length; x++) {
      this.WorkflowList[x] = this.WorkflowList[x].split(".yml")[0];
    }
  }

  public async DownloadInitialWorkflow(): Promise<InitialWorkflowResult> {
    let __ = this;
    let initialWorkflowResult: InitialWorkflowResult =
      new InitialWorkflowResult();

    this.clearAllWorkflowVariables();

    await this.FetchWorkflows();

    let x = 0;
    let w: string = __.workflowList[x];
    console.debug(`Attempting to fetch variables for ${w}`);
    await this.FetchWorkflowVariables(w);
    //Adjust setTimeout length in case
    // we need to show a warning to the user
    let msgTimeout = 0;
    return new Promise((resolve, reject) =>
      until(
        () => {
          let wrkflwCount = this.workflowVariableCount();
          if (wrkflwCount < 1) {
            let warningMsg = `Unable to load variables from ${__.WorkflowList[x]}. The workflow may contain errors. Try running the workflow and addressing any errors.`;
            if (x + 1 <= __.WorkflowList.length) {
              warningMsg = warningMsg.concat(
                `<br/><br/>Attempting to download next workflow "${
                  __.WorkflowList[x + 1]
                }"`
              );
            }
            console.warn(warningMsg);
            this.notificationService.newNotification(
              warningMsg,
              NotificationType.Warning
            );
            msgTimeout = 5000;
          }
          return (
            this.workflowVariableCount() > 0 || x >= __.WorkflowList.length
          );
        },
        async (callback) => {
          w = __.WorkflowList[++x];
          console.debug(`Attempting to fetch variables for ${w}`);
          await this.FetchWorkflowVariables(w);
          setTimeout(() => {
            return callback();
          }, msgTimeout);
        },
        (err) => {
          if (err) {
            this.rollbar.warning(`Error fetching variables for ${w}: ${err}`);
            initialWorkflowResult.isError = true;
            initialWorkflowResult.errorDescription = JSON.stringify(err);
            return reject(initialWorkflowResult);
          }
          initialWorkflowResult.isError = false;
          initialWorkflowResult.selectedWorkflow = w;
          return resolve(initialWorkflowResult);
        }
      )
    );
  }

  public async FetchWorkflowVariables(workflowName: string) {
    let __ = this;

    this.serviceState.next(WorkflowServiceState.BUSY);

    await __.apiService.FetchWorkflowVariables(workflowName).then(
      (response: FetchWorkflowVariableResult) => {
        if (!response?.isError && response?.value != null) {
          __.processRawInterviewResponse(response?.value);
          __.selectedWorkflow = workflowName;
        } else {
          this.rollbar.warning(
            `Error in fetching variables for workflow ${workflowName}: ${response?.errorDescription}`
          );
        }
      },
      (reason) => {
        this.rollbar.warning(
          `Error in fetching variables for workflow ${workflowName} (promise rejected): ${reason.errorDescription}`
        );
      }
    );
    this.serviceState.next(WorkflowServiceState.READY);
  }

  private processRawInterviewResponse(data: any): number {
    this.clearAllWorkflowVariables();
    this.emitAllWorkflowVariables();

    this.setWorkflowOutputDocuments(data.attachments);
    this.docusignConfig = data.docusign_config;
    this.isOverTemplateLimit = data.is_over_template_limit;
    let __response = data?.types_json?.types_list;

    //aggregate variable types
    for (let k in __response) {
      if (__response[k].hasOwnProperty("variable")) {
        if (
          //Filter out data that is not a workflow variable
          __response[k].variable.indexOf("fetch_clio") == -1 &&
          __response[k].variable.indexOf("hsl_reviewed") == -1 &&
          __response[k].variable.indexOf("hsl_complete") == -1 &&
          __response[k].variable.indexOf("hsl_field") == -1 &&
          __response[k].variable.indexOf("page_condition") == -1 &&
          __response[k].variable.indexOf("_table") == -1 &&
          __response[k].variable.indexOf("clio_question_auth") == -1 &&
          __response[k].variable.indexOf("user_email_address") == -1 &&
          __response[k].variable.indexOf("save_resume_template") == -1 &&
          __response[k].variable.indexOf("submitform") == -1 &&
          __response[k].variable.indexOf("my_email") == -1 &&
          !__response[k].variable.endsWith(".there_are_any") &&
          !__response[k].variable.endsWith(".there_is_another") &&
          !__response[k].variable.endsWith(".reviewed") &&
          __response[k].datatype != null
        ) {
          //Fix radio type vars from having data type of 'text'!
          if (__response[k].inputtype == "radio") {
            __response[k].datatype = "radio";
          }
          //Fix area type vars from having data type of 'text'!
          if (__response[k].inputtype == "area") {
            __response[k].datatype = "area";
          }
          if (__response[k].datatype == "repeating") {
            __response[k].datatype = "text";
          }
          if (__response[k].inputtype == "combobox") {
            __response[k].datatype = "dropdown";
          }
          this.createWorkflowVariables(__response[k]);
        }
      }
    }

    this.addRepeatingMembersToParent();
    /*Add Repeating members to __workflowVariablesAll now that
    the parents and children have been mapped
     */
    this.__workflowVariablesAll = this.__workflowVariablesAll
      .concat(this.__workflowVariablesRepeat)
      .concat(this.__workflowVariablesRepeatChildren);

    this.insertToday();
    this.emitAllWorkflowVariables();
    return this.__workflowVariablesAll.length;
  }

  private setWorkflowOutputDocuments(attachments: any) {
    if (attachments.length > 0) {
      attachments.forEach((item: any) => {
        this.__workflowOutputDocuments.push(item.filename);
      });
    } else {
      this.__workflowOutputDocuments = [];
    }
    this.workflowOutputDocuments.next(this.__workflowOutputDocuments);
  }

  insertToday() {
    let today = new DateVariable("today()", "Today");
    this.__workflowVariablesDate.push(today);
    this.__workflowVariablesSimple.push(today);
  }

  createWorkflowVariables(v: any) {
    //If parent property is not null, add to repeating children array.
    //We will go through this array at the end to ensure all parents have
    //Been created first.

    if (this.isRepeatObjectChild_v2(v) || this.isRepeatObjectChild(v)) {
      let member = this.createRepeatingMembers(v);

      this.__workflowVariablesRepeatChildren.push(member);
      this.__workflowVariablesMapById.set(member.id, member);

      if (FilterRepeatVariables.Filter(member)) {
        this.__workflowVariablesRepeat.push(member);
      } else {
        this.__repeatingChildren.push(member);
      }
      return;
    }

    //This is an array, it should be iterated through and each member filtered and pushed onto the
    // appropriate interview variables list.
    let workflowVars = this.__createWorkflowVariables(v);

    workflowVars.forEach((workflowVar) => {
      /*Add to all workflow variables member if
       *not* a repeating variable. Those will be added
       * After their children have been re-attached.
       */

      if (!FilterRepeatVariables.Filter(workflowVar)) {
        this.__workflowVariablesAll.push(workflowVar);
      }
      //Pass through filters
      if (FilterSimpleVariables.Filter(workflowVar)) {
        this.__workflowVariablesSimple.push(workflowVar);
      }

      if (FilterConditionalVariables.Filter(workflowVar)) {
        this.__workflowVariablesConditional.push(workflowVar);
      }

      if (FilterNumberVariables.Filter(workflowVar)) {
        this.__workflowVariablesNumber.push(workflowVar);
      }

      if (FilterDateVariables.Filter(workflowVar)) {
        this.__workflowVariablesDate.push(workflowVar);
      }

      if (FilterRepeatVariables.Filter(workflowVar)) {
        this.__workflowVariablesRepeat.push(workflowVar);
      }

      if (FilterSignatureVariables.Filter(workflowVar)) {
        this.__workflowVariablesSignature.push(workflowVar);
      }

      this.__workflowVariablesMapById.set(workflowVar.id, workflowVar);
    });
  }

  __createWorkflowVariables: (
    raw_variable: any //response from API call
  ) => SimpleVariable[] = function __createWorkflowVariables(
    raw_variable
  ): SimpleVariable[] {
    let newVars = new Array<SimpleVariable>();

    var raw_variable_type = raw_variable.datatype;
    switch (raw_variable_type) {
      case "text":
        newVars.push(
          new TextVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          ),
          new SimpleConditionalVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          )
        );
        break;

      case "number":
        newVars.push(
          new NumberVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          )
        );
        break;

      case "checkboxes":
        newVars.push(
          new CheckboxVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label,
            raw_variable.selections
          ),
          new NumberVariable(
            `${raw_variable.variable}.true_values().number()`,
            `Number of selected items in ${raw_variable.variable}`
          )
        );
        break;

      case "email":
        newVars.push(
          new TextVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          )
        );
        break;

      case "boolean":
      case "yesnoradio":
        newVars.push(
          new BooleanVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          )
        );
        break;

      case "dropdown":
        newVars.push(
          new DropdownVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label,
            raw_variable.selections ?? new Array()
          )
        );
        break;

      case "date":
        newVars.push(
          new DateVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          )
        );
        break;

      case "file":
        newVars.push(
          new FileVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          )
        );
        break;

      case "area":
        newVars.push(
          new TextAreaVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          ),
          new SimpleConditionalVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          )
        );

        break;

      case "integer":
        newVars.push(
          new NumberVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          )
        );
        break;

      case "note":
        newVars.push(
          new NoteVariable(raw_variable.variable, raw_variable.variable)
        );
        break;

      case "button":
        newVars.push(
          new ButtonVariable(raw_variable.variable, raw_variable.variable)
        );
        break;

      case "object":
        newVars.push(
          new NumberVariable(
            raw_variable.variable + ".number()",
            "Number of " + raw_variable.variable,
            "Number of " + raw_variable.variable
          )
        );
        newVars.push(
          new ObjectComplexVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          )
        );

        break;

      case "object_from_file":
        newVars.push(
          new FileObjectVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label
          )
        );

        break;

      case "radio":
      case "yesnoradio":
        newVars.push(
          new RadioVariable(
            raw_variable.variable,
            raw_variable.variable,
            raw_variable.label,
            raw_variable.selections ?? new Array()
          )
        );

        break;
      case "signature":
        newVars.push(
          new SignatureVariable(raw_variable.variable, raw_variable.variable)
        );
        break;
      default:
        break;
    }

    return newVars;
  };

  isRepeatObjectChild(v: any) {
    let obj_name = v.variable.split(".")[0];

    if (obj_name.indexOf("[i]") !== -1) {
      return true;
    } else {
      return false;
    }
  }

  isRepeatObjectChild_v2(v: any) {
    //if (v.variable.indexOf("[i].") !== -1) {
    if (v.parent) {
      return true;
    } else {
      return false;
    }
  }

  //This has to be changed to support child members more than two levels deep.
  addRepeatingMembersToParent() {
    this.__workflowVariablesRepeat.forEach((parent) => {
      this.__repeatingChildren.forEach((child) => {
        if (child) {
          let parent_name = child.itemParent;

          if (parent.id == parent_name) {
            (<ObjectComplexVariable>parent).repeatingMembers.push(child);
          }
        }
      });
      //If parent is the child of another object, add it to it's parent now.
      //It has all of it's children assigned to it.
      if (parent.itemParent) {
        this.__workflowVariablesRepeat.forEach((grandparent) => {
          if (parent.itemParent == grandparent.id) {
            (<ObjectComplexVariable>grandparent).repeatingMembers.push(parent);
          }
        });
      }
    });
    this.__workflowVariablesRepeat = this.__workflowVariablesRepeat.filter(
      (value) => value.itemChild == false
    );
  }

  getParentName(id: string): string {
    let childLevel = new RegExp(/([\w|-|_]+(\['.+'\])?)\[[a-z]\]/, "g");
    let results: Array<any>;
    let parent: string;
    while ((results = childLevel.exec(id)) !== null) {
      parent = results[1];
    }
    return parent;
  }

  createRepeatingMembers(n: any): SimpleVariable {
    let raw_variable_type = n.datatype;
    let parentName = this.getParentName(n.variable);
    let displayName = n.label;
    let id = n.variable.split(/\[[a-z]\]\./).pop();

    switch (raw_variable_type) {
      case "text":
        return new TextVariable(id, displayName, displayName, parentName);

      case "number":
      case "integer":
        //Commenting out per WA-264
        this.__workflowVariablesNumber.push(
          new NumberVariable(
            id,
            "Sum of " + id,
            displayName,

            parentName
          )
        );
        return new NumberVariable(id, displayName, displayName, parentName);

      case "checkboxes":
        return new CheckboxVariable(
          id,
          displayName,
          displayName,
          n.selections,
          parentName
        );

      case "email":
        return new TextVariable(id, displayName, displayName, parentName);

      case "boolean":
        return new BooleanVariable(id, displayName, displayName, parentName);

      case "dropdown":
        return new DropdownVariable(
          id,
          displayName,
          displayName,
          n.selections,
          parentName
        );

      case "date":
        return new DateVariable(id, displayName, displayName, parentName);

      case "file":
        return new FileVariable(id, displayName, displayName, parentName);

      case "area":
        return new TextAreaVariable(id, displayName, displayName, parentName);

      case "object_from_file":
        return new FileObjectVariable(id, displayName, displayName, parentName);

      case "radio":
      case "yesnoradio":
        return new RadioVariable(
          id,
          displayName,
          displayName,
          n.selections ?? new Array(),
          parentName
        );

      case "object":
        return new ObjectComplexVariable(id, id, id, [], parentName);

      default:
        break;
    }
  }

  clearAllWorkflowVariables() {
    this.__workflowVariablesSimple = new Array();
    this.__workflowVariablesConditional = new Array();
    this.__workflowVariablesDate = new Array();
    this.__workflowVariablesNumber = new Array();
    this.__workflowVariablesRepeat = new Array();
    this.__workflowVariablesRepeatChildren = new Array();
    this.__workflowVariablesSignature = new Array();
    this.__workflowVariablesAll = new Array();
    this.__workflowVariablesMapById = new Map<string, SimpleVariable>();

    this.__repeatingChildren = new Array();
    this.__workflowOutputDocuments = new Array();
  }

  emitAllWorkflowVariables() {
    this.workflowVariablesSimple.next(this.__workflowVariablesSimple);
    this.workflowVariablesConditional.next(this.__workflowVariablesConditional);
    this.workflowVariablesDate.next(this.__workflowVariablesDate);
    this.workflowVariablesNumber.next(this.__workflowVariablesNumber);
    this.workflowVariablesRepeat.next(this.__workflowVariablesRepeat);
    this.workflowVariablesRepeatChildren.next(
      this.__workflowVariablesRepeatChildren
    );
    this.workflowVariablesSignature.next(this.__workflowVariablesSignature);
    this.workflowVariablesAll.next(this.__workflowVariablesAll);
    this.workflowOutputDocuments.next(this.__workflowOutputDocuments);
    this.workflowVariablesMapById.next(this.__workflowVariablesMapById);
  }
}
