import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { SimpleVariable } from "./SimpleVariable";
import { VariableDataType } from "./VariableType";

export class TextVariable extends SimpleVariable {
  public label: string;
  public simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "default",
      text: "Standard",
    },
    {
      code: "upper",
      text: "Uppercase",
    },
    {
      code: "first",
      text: "First Letter Capital",
    },
    {
      code: "title",
      text: "Title Case",
    },
  ];

  constructor(handle: string, display: string, label: string, parent?: string) {
    super(handle, display, VariableDataType.Text, null, parent);

    this.label = label;
  }
}
