import { DocumentSyntax } from "../DocumentSyntax";
import { Constants } from "../../shared/Constants";
import { SyntaxContainer, SyntaxReport } from "../../shared/Types";
import { RegexConstants } from "../../shared/RegexConstants";

export class StatementMatcher {
  private static orphans = new Array<SyntaxContainer>();
  private static phraseStack = new Array<SyntaxContainer>();
  private static paraStack = new Array<SyntaxContainer>();

  private static phraseForStack = new Array<SyntaxContainer>();
  private static paraForStack = new Array<SyntaxContainer>();

  private static paraNestedForStack = new Array<SyntaxContainer>();
  private static phraseNestedForStack = new Array<SyntaxContainer>();

  private static phraseSetStack = new Array<SyntaxContainer>();
  private static paraSetStack = new Array<SyntaxContainer>();

  static Match(documentSyntax: DocumentSyntax): SyntaxReport {
    /*For each expression if it is not a statement, break. Next, test against known valid expressons,
     and either push onto orphaned stack, or opening if expression, push
       onto the respective phrase, paragraph stack. If the expression
       is a closing if expression, pop a range off of the respective
       stack. If there is no range to pop off the stack, add the
       closing expression to the MatchResult object and set the result
       to failed.*/

    this.paraForStack = [];
    this.phraseForStack = [];
    this.paraNestedForStack = [];
    this.phraseNestedForStack = [];
    this.paraSetStack = [];
    this.phraseSetStack = [];
    this.phraseStack = [];
    this.paraStack = [];
    this.orphans = [];

    let ranges = documentSyntax.ranges;
    let body = documentSyntax.body;
    let tableStatements = documentSyntax.rows;

    ranges.forEach((range) => {
      let expression = range.text;

      if (RegexConstants.regexForNestedItemLoop.test(expression)) {
        this.CheckForOrphanedItemStatement(range);
      }
      if (RegexConstants.regexOpenPhrase.test(expression)) {
        this.AddToStack(this.phraseStack, range);
      } else if (RegexConstants.regexClosePhrase.test(expression)) {
        this.RemoveFromStack(this.phraseStack, range);
      } else if (RegexConstants.regexOpenPara.test(expression)) {
        this.AddToStack(this.paraStack, range);
      } else if (RegexConstants.regexClosePara.test(expression)) {
        this.RemoveFromStack(this.paraStack, range);
      } else if (RegexConstants.regexOpenForPara.test(expression)) {
        this.AddToStack(this.paraForStack, range);
      } else if (RegexConstants.regexCloseForPara.test(expression)) {
        this.RemoveFromStack(this.paraForStack, range);
      } else if (RegexConstants.regexOpenForPhrase.test(expression)) {
        this.AddToStack(this.phraseForStack, range);
      } else if (RegexConstants.regexCloseForPhrase.test(expression)) {
        this.RemoveFromStack(this.phraseForStack, range);
      } else if (RegexConstants.regexOpenSetPara.test(expression)) {
        this.AddToStack(this.paraSetStack, range);
      } else if (RegexConstants.regexCloseSetPara.test(expression)) {
        this.RemoveFromStack(this.paraSetStack, range);
      } else if (RegexConstants.regexOpenSetPhrase.test(expression)) {
        this.AddToStack(this.phraseSetStack, range);
      } else if (RegexConstants.regexCloseSetPhrase.test(expression)) {
        this.RemoveFromStack(this.phraseSetStack, range);
      } else if (RegexConstants.regexItemStatement.test(expression)) {
        this.CheckForOrphanedItemStatement(range);
      } else if (RegexConstants.regexNestedItemStatement.test(expression)) {
        this.CheckForOrphanedItemStatement(range, true);
      }
    });

    /*The loop is complete. If there are any opening expressions
        left on a stack, add them to the MatchResult object and set the
        result to failed. */
    let stackArray = [
      this.phraseStack,
      this.paraStack,
      this.phraseForStack,
      this.paraForStack,
      this.phraseSetStack,
      this.paraSetStack,
    ];
    stackArray.forEach((stack) => {
      if (stack.length > 0) {
        StatementMatcher.orphans.push(...stack);
      }
    });

    let tableErrors = this.CheckTableSyntaxOutsideOfTable(
      body,
      tableStatements
    );
    let orphanErrors = this.AddErrorsToOrphans();
    return new Map<Word.Range | Word.Paragraph, string[]>([
      ...orphanErrors,
      ...tableErrors,
    ]);
  }

  static AddToStack(stack: SyntaxContainer[], expression: SyntaxContainer) {
    stack.push(expression);
  }

  static RemoveFromStack(
    stack: SyntaxContainer[],
    expression: SyntaxContainer
  ) {
    if (stack.length >= 1) {
      stack.pop();
    } else {
      this.orphans.push(expression);
    }
  }

  private static CheckForOrphanedItemStatement(
    statement: SyntaxContainer,
    nested?: boolean
  ) {
    if (!nested) {
      if (this.paraForStack.length > 0 || this.phraseForStack.length > 0) {
        return;
      } else {
        this.orphans.push(statement);
      }
    } else {
      /*Search both for stacks for a nested item for loop*/
      let isOrphan: boolean = true;
      this.paraForStack.forEach((p) => {
        if (RegexConstants.regexForNestedItemLoop.test(p.text)) {
          isOrphan = false;
          return;
        }
      });
      this.phraseForStack.forEach((p) => {
        if (RegexConstants.regexForNestedItemLoop.test(p.text)) {
          isOrphan = false;
          return;
        }
      });
      if (isOrphan) this.orphans.push(statement);
    }
  }

  private static AddErrorsToOrphans(): SyntaxReport {
    let orphanedRanges: SyntaxReport = new Map<SyntaxContainer, string[]>();
    this.orphans.forEach((orphan) => {
      if (RegexConstants.regexForNestedItemLoop.test(orphan.text)) {
        orphanedRanges.set(orphan, [
          Constants.NESTED_LOOP_OUTSIDE_FOR_LOOP_ERROR,
        ]);
      } else if (RegexConstants.regexNestedItemStatement.test(orphan.text)) {
        orphanedRanges.set(orphan, [
          Constants.NESTED_ATTRIBUTE_OUTSIDE_NESTED_FOR_LOOP_ERROR,
        ]);
      } else if (
        RegexConstants.regexOpenPhrase.test(orphan.text) ||
        RegexConstants.regexOpenPara.test(orphan.text)
      ) {
        orphanedRanges.set(orphan, [Constants.IF_STATEMENT_MATCH_ERROR]);
      } else if (
        RegexConstants.regexOpenForPhrase.test(orphan.text) ||
        RegexConstants.regexOpenForPara.test(orphan.text)
      ) {
        orphanedRanges.set(orphan, [Constants.FOR_STATEMENT_MATCH_ERROR]);
      } else if (
        RegexConstants.regexClosePara.test(orphan.text) ||
        RegexConstants.regexClosePhrase.test(orphan.text)
      ) {
        orphanedRanges.set(orphan, [Constants.ENDIF_STATEMENT_MATCH_ERROR]);
      } else if (
        RegexConstants.regexCloseForPara.test(orphan.text) ||
        RegexConstants.regexCloseForPhrase.test(orphan.text)
      ) {
        orphanedRanges.set(orphan, [Constants.ENDFOR_STATEMENT_MATCH_ERROR]);
      } else if (
        RegexConstants.regexOpenSetPara.test(orphan.text) ||
        RegexConstants.regexOpenSetPhrase.test(orphan.text)
      ) {
        orphanedRanges.set(orphan, [Constants.SET_STATEMENT_MATCH_ERROR]);
      } else if (
        RegexConstants.regexCloseSetPara.test(orphan.text) ||
        RegexConstants.regexCloseSetPhrase.test(orphan.text)
      ) {
        orphanedRanges.set(orphan, [Constants.ENDSET_STATEMENT_MATCH_ERROR]);
      } else if (RegexConstants.regexItemStatement.test(orphan.text)) {
        orphanedRanges.set(orphan, [Constants.ITEM_STATEMENT_MATCH_ERROR]);
      }
    });
    return orphanedRanges;
  }

  private static CheckTableSyntaxOutsideOfTable(
    body: string,
    ranges: SyntaxContainer[]
  ): SyntaxReport {
    //If the body has no table statements, we can
    // exit.
    let regexSimpleSearch = /{%tr[^%]*%}/;
    if (!regexSimpleSearch.test(body)) {
      return new Map<Word.Range | Word.Paragraph, string[]>();
    }

    //Since we get the body as OOXML, the full search will always
    // return *some* results, even if it's only the xml tags.
    let regexFullSearch = /(<w:[^>]*>|<\/w:[^>]*>|{%tr[^%]*%})/g;
    let searchResults = body.match(regexFullSearch)?.splice(1);

    let tableStack = new Array<number>();
    let trCounter = 0;
    let offenderIndexes = new Array<number>();

    searchResults.forEach((result) => {
      switch (result) {
        case "<w:tbl>":
          tableStack.push(1);
          break;
        case "</w:tbl>":
          tableStack.pop();
          break;
        default:
          if (result.match(/{%tr[^%]*%}/)) {
            //Verify we are inside a table
            if (tableStack.length < 1) {
              //Not inside table, push
              //Index onto list to send error message at end
              offenderIndexes.push(trCounter);
            }
            //Increment counter
            trCounter++;
          }
      }
    });

    //If there are no Indexes with bad syntax,
    // return a new map

    if (offenderIndexes.length == 0) {
      return new Map<Word.Range | Word.Paragraph, string[]>();
    }

    // Otherwise, create an error message for the offenders here
    let report = new Map<Word.Range | Word.Paragraph, string[]>();

    offenderIndexes.forEach((index) => {
      report.set(ranges[index], [Constants.TABLE_STATEMENT_SYNTAX_ERROR]);
    });

    return report;
  }
}
