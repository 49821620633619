<div class="container-fluid footer-top fixed-bottom py-1 border-top bg-white">
  <div class="align-items-top text-green text-center">
    <h4>
      <img
        src="/assets/img/gavel-formerly_violet_and_black_85_26.png"
        alt="Gavel Logo"
      />
      Template Builder
      <ng-container *ngIf="showLogout">
        <mat-icon
          style="
            text-align: right;
            margin-right: 0px;
            padding-right: 0px;
            padding-left: 55px;
            cursor: pointer;
          "
          class="align-bottom logout"
          (click)="logout()"
          matTooltip="Logout"
          >exit_to_app
        </mat-icon></ng-container
      >
    </h4>
  </div>
</div>
