import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { SimpleVariable } from "./SimpleVariable";
import { VariableDataType } from "./VariableType";

export class SignatureVariable extends SimpleVariable {
  public readonly simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "default",
      text: "Standard",
    },
  ];

  constructor(handle: string, display: string, parent?: string) {
    super(handle, display, VariableDataType.Signature, null, parent);
  }
}
