import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { Constants } from "../Constants";
import { FormBuilder, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SimpleVariable } from "../variableTypes/SimpleVariable";
import { ComplexVariable } from "../variableTypes/ComplexVariable";
import { DropdownOption } from "../Types";
import { expand } from "rxjs/operators";
import { AutoComplete, Dropdown } from "primeng";
import { NumberVariable } from "../variableTypes/NumberVariable";

@Component({
  selector: "app-variable-dropdown",
  templateUrl: "./variable-dropdown.component.html",
  styleUrls: ["./variable-dropdown.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: VariableDropdownComponent,
      multi: true,
    },
  ],
})
export class VariableDropdownComponent {
  public dropdownList: SimpleVariable[] | ComplexVariable[] | DropdownOption[] =
    [];
  @Input("options") set setDropdownList(
    newList: SimpleVariable[] | ComplexVariable[] | DropdownOption[]
  ) {
    this.dropdownList = newList;
    this.selectedDropdownOption.setValue(null);
    this.suggestions = (<SimpleVariable[]>this.dropdownList).map((v) => {
      return v.id;
    });
  }
  @Input("editable") customOptions: boolean = false;
  @Input() clearable: boolean = false;
  @Input() noPlaceholder: boolean = false;
  @Input() placeholder: string = Constants.TYPE_TO_SEARCH;
  @Input() appendTo: string = "";

  @Input() multiple: boolean = false;

  @Output("add") MultiselectItemAdded = new EventEmitter<SimpleVariable>();
  @Output("remove") MultiselectItemRemoved = new EventEmitter<SimpleVariable>();

  @ViewChild("dropdown") dropdownElement: AutoComplete;

  public onChange = (newVal) => {};

  public selectedDropdownOption: FormControl = new FormControl("");
  public suggestions: string[];

  constructor(private fb: FormBuilder) {
    this.subscribeToSelectedDropdown();
  }

  writeValue(obj: any) {
    this.selectedDropdownOption.setValue(obj);
  }

  subscribeToSelectedDropdown() {
    this.selectedDropdownOption.valueChanges.subscribe((newVal) => {
      this.onChange(newVal);
    });
  }

  setDisabledState(isDisabled: boolean) {}

  registerOnTouched(fn: any) {}

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
    this.onChange(this.selectedDropdownOption.value);
  }

  selectedMultiOptionChange(event: any) {
    let items: any[] = event.value;
    let toggledItem: any = event.itemValue;

    if (items.includes(toggledItem)) {
      this.MultiselectItemAdded.emit(toggledItem);
    } else {
      this.MultiselectItemRemoved.emit(toggledItem);
    }

    this.selectedDropdownOption.setValue(event.value);
  }

  collapseDropdown(event: any): void {
    console.log(`collapseDropdown: ${JSON.stringify(event)}`);
    this.dropdownElement.overlayVisible = false;
  }

  expandDropdown(event: any): void {
    console.log(`expandDropdown: ${JSON.stringify(event)}`);
    this.dropdownElement.overlayVisible = true;
    /*this.dropdownElement.filterValue.toString();*/
    /* el.nativeElement.dispatchEvent(
      new MouseEvent("mousedown", { button: 0 })
    );*/
  }

  search(event: any): void {
    let q = event.query;

    this.suggestions = (<SimpleVariable[]>this.dropdownList).map((v) => {
      return v.id;
    });

    if (q.trim() == "") {
      return;
    }

    this.suggestions = this.suggestions.filter((s) => {
      if (s.toLowerCase().search(new RegExp(<string>q.toLowerCase())) != -1) {
        return true;
      }
      return false;
    });

    //Test event.query to see if it's a number
    if (/\d+/.test(q)) {
      //Add query to list of suggestions
      this.suggestions.push(q);
    }
  }
}
