import { SyntaxContainer } from "../shared/Types";

export class DocumentSyntax {
  ranges: SyntaxContainer[];
  paragraphs: Word.Paragraph[];
  body: string;
  rows: SyntaxContainer[];
  orphans: Word.Paragraph[];
  tableRows: TableRowSyntax[] | null;

  constructor(
    ranges: SyntaxContainer[],
    paragraphs: Word.Paragraph[],
    body: string,
    tableStatements: SyntaxContainer[],
    orphans: Word.Paragraph[],
    tables: TableRowSyntax[]
  ) {
    this.ranges = ranges;
    this.paragraphs = paragraphs;
    this.body = body;
    this.rows = tableStatements;
    this.orphans = orphans;
    this.tableRows = tables;
  }
}

export class TableRowSyntax {
  row: Word.TableRow;
  range: Word.Range;

  constructor(row: Word.TableRow, range: Word.Range) {
    this.row = row;
    this.range = range;
  }
}
