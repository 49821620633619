import { Component, Inject, Input } from "@angular/core";
import { ReportBuilder } from "./ReportBuilder/ReportBuilder";
import {
  WorkflowService,
  WorkflowServiceState,
} from "../shared/workflow.service";
import { SimpleVariable } from "../shared/variableTypes/SimpleVariable";
import { RollbarService } from "../shared/rollbarerrorhandler.service";
import { DocumentScanner } from "./DocumentScanner";
import { SyntaxContainer, SyntaxReport } from "../shared/Types";
import { Constants } from "../shared/Constants";
import Rollbar from "rollbar";
import { SyntaxHighlighter } from "./SyntaxHighlighter";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ProgressBarMode } from "@angular/material/progress-bar";

@Component({
  selector: "app-syntax-check",
  templateUrl: "./syntax.component.html",
  styleUrls: ["./syntax.component.css"],
})
export class SyntaxComponent {
  interviewVariables: SimpleVariable[] = [];

  disableCheckSyntax: boolean = false;
  disableHighlightSyntax: boolean = false;

  selectedMalformedVariableError: string[] = [];
  selectedMalformedVariableText: string;

  invalidSyntaxMap: SyntaxReport = new Map<
    Word.Range | Word.Paragraph,
    string[]
  >();
  invalidSyntaxKeys: SyntaxContainer[] = [];
  invalidSyntaxKeyIndex: number = 0;

  displaySyntaxCheckPassed: boolean = false;

  isOfficeOnline: boolean = this.detectOfficeOnline();

  buttonText: string = Constants.CHECK_FOR_ERRORS;
  form: FormGroup;

  @Input() pbMode: ProgressBarMode;

  constructor(
    private fb: FormBuilder,
    private interview: WorkflowService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.buildForm();
    this.interview.workflowVariablesAll.subscribe((x) => {
      if (x.length > 0) {
        this.interviewVariables = x;
      } else {
        this.interviewVariables = new Array();
      }
    });

    this.interview.serviceState.subscribe((value) => {
      if (value == WorkflowServiceState.BUSY) {
        this.disableCheckSyntax = true;
      } else {
        this.disableCheckSyntax = false;
      }
    });
  }

  buildForm() {
    this.form = this.fb.group({ highlightSyntax: this.fb.control(false) });
  }

  async highlightSyntax() {
    this.pbMode = "indeterminate";
    this.disableHighlightSyntax = true;
    let batch = 0;

    await Word.run(null, (context) =>
      DocumentScanner.ScanDocument(context)
        .then((syntax) => {
          for (let range of syntax.ranges) {
            SyntaxHighlighter.Highlight(range, context);
          }
        })
        .then(async () => {
          await context.sync().catch((reason) => {
            console.error(`Exception caught: ${JSON.stringify(reason)}`);
          });
        })
        .then(() => {
          setTimeout(() => {
            this.disableHighlightSyntax = false;
            this.pbMode = "determinate";
          }, 5000);
        })
    );
  }

  async syntaxNavigate(direction: string) {
    switch (direction) {
      case "next":
        this.invalidSyntaxKeyIndex++;
        break;
      case "prev":
        if (this.invalidSyntaxKeyIndex > 0) this.invalidSyntaxKeyIndex--;
        break;
    }

    this.selectedMalformedVariableError =
      this.invalidSyntaxMap.get(
        this.invalidSyntaxKeys[this.invalidSyntaxKeyIndex]
      ) || [];

    await this.selectRange(this.invalidSyntaxKeys[this.invalidSyntaxKeyIndex]);
  }

  async selectRange(range: SyntaxContainer) {
    OfficeExtension.config.extendedErrorLogging = true;
    await Word.run(range, async (context) => {
      range.select(Word.SelectionMode.select);
      await context.sync();
      range.track();

      this.invalidSyntaxMap.delete(
        this.invalidSyntaxKeys[this.invalidSyntaxKeyIndex]
      );

      this.invalidSyntaxKeys[this.invalidSyntaxKeyIndex] = range;

      this.invalidSyntaxMap.set(range, this.selectedMalformedVariableError);
    }).catch((error) => {
      if (error instanceof OfficeExtension.Error) {
        this.rollbar.warn(
          `Office API error while checking syntax: ${error.debugInfo}`
        );
      }
    });
  }

  async searchAndRescue(
    context: Word.RequestContext
  ): Promise<Map<SyntaxContainer, string[]>> {
    return DocumentScanner.ScanDocument(context)
      .then((resultsCollection) => {
        return ReportBuilder.BuildReport(
          resultsCollection,
          this.interviewVariables
        );
      })
      .catch((error) => {
        this.rollbar.warn(
          "Exception was thrown while scanning document and building report: " +
            typeof error +
            " - " +
            error.message
        );
        return Promise.reject(error);
      });
  }

  async checkSyntax() {
    if (this.form.controls["highlightSyntax"].value[0] == "highlight") {
      this.highlightSyntax();
    }
    //Disable check syntax button while the operation is running
    this.disableCheckSyntax = true;

    //Untrack the prev collection of invalid ranges
    this.invalidSyntaxKeys.forEach((range) => range.untrack());
    Word.run<SyntaxReport>(null, (context) => this.searchAndRescue(context))
      .then((report) => {
        this.displaySyntaxCheckResults(report);
      })
      .catch((error) => {
        this.rollbar.warn("Exception thrown while dislaying results", error);
      })
      .finally(() => {
        //Re-enable check syntax button
        console.debug("checkSyntax() -> Finally");
        this.disableCheckSyntax = false;
      });
  }

  detectOfficeOnline(): boolean {
    try {
      if (
        Office?.context?.diagnostics?.platform ==
        Office.PlatformType.OfficeOnline
      ) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      this.rollbar.warn("Exception when detecting Office platform: " + e);
      /*TODO: Warn the user that we could not detect their
       Platform, and undesired loss of functionality may occur.*/
      return false;
    }
  }

  displaySyntaxCheckResults(results: SyntaxReport) {
    if (results.size == 0) {
      this.invalidSyntaxMap.clear();
      this.invalidSyntaxKeys.splice(0);
      this.displaySyntaxCheckPassed = true;
      setTimeout(() => (this.displaySyntaxCheckPassed = false), 5000);
      return;
    } else {
      console.debug(`${results.size} variables with invalid syntax`);

      this.invalidSyntaxMap = results;
      this.invalidSyntaxKeys = Array.from(this.invalidSyntaxMap.keys());
      this.invalidSyntaxKeyIndex = 0;

      this.selectedMalformedVariableText =
        this.invalidSyntaxKeys[this.invalidSyntaxKeyIndex].text;
      this.selectedMalformedVariableError =
        this.invalidSyntaxMap.get(
          this.invalidSyntaxKeys[this.invalidSyntaxKeyIndex]
        ) || [];
      this.selectRange(this.invalidSyntaxKeys[this.invalidSyntaxKeyIndex]);
    }
  }
}
