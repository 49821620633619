import { Token } from "../../shared/Types";

export abstract class ASTNode {
  public _token: Token;

  protected constructor(token: Token) {
    this._token = token;
  }

  public hasLeft(): boolean {
    return false;
  }

  public hasRight(): boolean {
    return false;
  }
}

export class NumberASTNode extends ASTNode {
  public value: number = Number.parseInt(this._token.value);
  constructor(token: Token) {
    super(token);
  }
}

export class BooleanASTNode extends ASTNode {
  constructor(token: Token) {
    super(token);
  }
}

export class StringASTNode extends ASTNode {
  public value: string = this._token.value;
  constructor(token: Token) {
    super(token);
  }
}

export class VariableASTNode extends ASTNode {
  public value: string = this._token.value;
  constructor(token: Token) {
    super(token);
  }
}

export class PropertyASTNode extends ASTNode {
  public value: string = this._token.value;
  constructor(token: Token) {
    super(token);
  }
}

export class DateArgumentASTNode extends ASTNode {
  constructor(token: Token) {
    super(token);
  }
}

export class EndConditionalASTNode extends ASTNode {
  public value: string = this._token.value;
  constructor(token: Token) {
    super(token);
  }
}

export class ElseConditionalASTNode extends ASTNode {
  public value: string = this._token.value;
  constructor(token: Token) {
    super(token);
  }
}

export class EndSetASTNode extends ASTNode {
  public value: string = this._token.value;
  constructor(token: Token) {
    super(token);
  }
}

export class EndControlASTNode extends ASTNode {
  public value: string = this._token.value;
  constructor(token: Token) {
    super(token);
  }
}

export abstract class UnaryOperatorASTNode extends ASTNode {
  public Left: ASTNode;
  protected constructor(token: Token, left: ASTNode) {
    super(token);
    this.Left = left;
  }
  public hasLeft(): boolean {
    if (this.Left == null || this.Left == undefined) {
      return false;
    }
    return true;
  }
}

export abstract class BinaryOperatorASTNode extends UnaryOperatorASTNode {
  public Right: ASTNode;

  protected constructor(token: Token, left: ASTNode, right: ASTNode) {
    super(token, left);
    this.Left = left;
    this.Right = right;
  }

  public hasRight(): boolean {
    return this.Right != null;
  }
}

export class FilterASTNode extends UnaryOperatorASTNode {
  public value: string = this._token.value;
  private _hasLeft: boolean = false;
  constructor(op: Token, left?: ASTNode) {
    super(op, left);
    if (left) {
      this._hasLeft = true;
    }
  }
  public hasLeft(): boolean {
    return this._hasLeft;
  }
}

export class NotOperatorASTNode extends UnaryOperatorASTNode {
  constructor(token: Token, left: ASTNode) {
    super(token, left);
  }
}

export class ControlOperatorASTNode extends BinaryOperatorASTNode {
  private _hasRight: boolean = false;
  constructor(token: Token, left: ASTNode, right?: ASTNode) {
    super(token, left, right);
    if (right) {
      this._hasRight = true;
    }
  }
  public hasRight(): boolean {
    return this._hasRight;
  }
}

export class ArgumentOperatorASTNode extends BinaryOperatorASTNode {
  constructor(token: Token, left: ASTNode, right: ASTNode) {
    super(token, left, right);
  }
}

export class MathBinaryOperatorASTNode extends BinaryOperatorASTNode {
  constructor(token: Token, left: ASTNode, right: ASTNode) {
    super(token, left, right);
  }
}

export class ComparisonBinaryOperatorASTNode extends BinaryOperatorASTNode {
  constructor(token: Token, left: ASTNode, right: ASTNode) {
    super(token, left, right);
  }
}

export class LogicBinaryOperatorASTNode extends BinaryOperatorASTNode {
  constructor(token: Token, left: ASTNode, right: ASTNode) {
    super(token, left, right);
  }
}

export class FilterBinaryOperatorASTNode extends BinaryOperatorASTNode {
  constructor(token: Token, left: ASTNode, right: ASTNode) {
    super(token, left, right);
  }
}
export class ControlBinaryOperatorASTNode extends BinaryOperatorASTNode {
  constructor(token: Token, left: ASTNode, right: ASTNode) {
    super(token, left, right);
  }
}

export class FunctionArgumentASTNode extends UnaryOperatorASTNode {
  constructor(token: Token, left: ASTNode) {
    super(token, left);
  }
}

export class FunctionASTNode extends ASTNode {
  public value: string = this._token.value;
  public args: Array<ASTNode>;
  public Left: UnaryOperatorASTNode;
  constructor(token: Token, args?: Array<ASTNode>) {
    super(token);
    this.args = args;
    args?.forEach((arg) => {
      this.Left = new FunctionArgumentASTNode(arg._token, this.Left);
    });
  }
  public hasLeft(): boolean {
    if (this.Left == null || this.Left == undefined) {
      return false;
    }
    return true;
  }
}
