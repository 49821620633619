import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NumberChildOutputData } from "./NumberChildOutputData";
import { WorkflowService } from "src/app/shared/workflow.service";
import { NumberVariable } from "src/app/shared/variableTypes/NumberVariable";
import { SimpleVariable } from "src/app/shared/variableTypes/SimpleVariable";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from "@angular/forms";
import { Constants } from "../../shared/Constants";
import { DropdownOption } from "../../shared/Types";

@Component({
  selector: "number-child",
  templateUrl: "./number-child.component.html",
  styleUrls: ["./number-child.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumberChildComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: NumberChildComponent,
    },
  ],
})
export class NumberChildComponent
  implements ControlValueAccessor, Validator, OnInit
{
  /*DisplayMode1 shows the first number input control if this component is the
   * first child control of the parent. */
  @Input("header") displayMode1: boolean;

  /*Display mode 2 displays the operand control and the second number
   * select control. This is based on business logic that says if
   * the child of a repeating item is selected then the second dropdown should
   * not show.*/
  @Input("displayFirstChildOnly") set displayMode2(x: boolean) {
    this.displayFirstChildOnly = x;
    this.form?.controls["SelectedNumberVariable2"].setValue("");
  }
  /*All workflow variables*/
  @Input() workflowVariables: Map<string, SimpleVariable> = new Map<
    string,
    SimpleVariable
  >();
  /*All workflow number variables*/
  @Input() set workflowNumberVariables(
    newWorkflowNumberVariables: NumberVariable[]
  ) {
    this.addNewNumberVariables(newWorkflowNumberVariables);
  }
  displayFirstChildOnly: boolean;
  numberVariables: NumberVariable[] = new Array<NumberVariable>();
  numberVariables2: NumberVariable[] = new Array<NumberVariable>();

  outputData: NumberChildOutputData = new NumberChildOutputData();
  operandGroup: DropdownOption[] = [
    { code: Constants.MATH_ADD },
    { code: Constants.MATH_SUBTRACT },
    { code: Constants.MATH_MULTIPLY },
    { code: Constants.MATH_DIVIDE },
  ];

  TYPE_TO_INPUT = Constants.TYPE_TO_INPUT;

  public onChange = (newVal) => {};

  public form: FormGroup;

  constructor(private interview: WorkflowService, private fb: FormBuilder) {
    this.initializeOutput();
  }

  ngOnInit() {
    this.buildForm();
    this.subscribeToFormValueChanges();
  }

  writeValue(obj: any) {}

  setDisabledState(isDisabled: boolean) {}

  registerOnTouched(fn: any) {}

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
    this.onChange(this.outputData);
  }

  addCustomNumber(num: string) {
    let newObj = new NumberVariable(num, num);
    return newObj;
  }

  subscribeToFormValueChanges() {
    this.form.valueChanges.subscribe((v) => {
      let SelectedNumberVariable1 = v.SelectedNumberVariable1;
      let SelectedNumberVariable2 = v.SelectedNumberVariable2;
      let OperandId = v.OperandId;

      if (SelectedNumberVariable1 && !SelectedNumberVariable1?.id) {
        SelectedNumberVariable1 = this.addCustomNumber(SelectedNumberVariable1);
      }
      if (SelectedNumberVariable2 && !SelectedNumberVariable2?.id) {
        SelectedNumberVariable2 = this.addCustomNumber(SelectedNumberVariable2);
      }

      this.outputData.variable = SelectedNumberVariable1?.id;
      this.outputData.variablen = SelectedNumberVariable2?.id;
      this.outputData.joiningOperand = OperandId.code;

      if (this.displayMode1 && SelectedNumberVariable1?.itemChild) {
        this.outputData.childItem = true;
        this.outputData.parentName = SelectedNumberVariable1?.itemParent;
      } else {
        this.outputData.childItem = false;
        this.outputData.parentName = "";
      }
      this.onChange(this.outputData);
    });
  }

  initializeOutput() {
    this.outputData.joiningOperand = Constants.MATH_ADD;
  }

  //Adds new workflow variables to the options lists for
  // each dropdown.
  addNewNumberVariables(variables: NumberVariable[]) {
    //Clear out variable queues when loading a new Workflow
    this.numberVariables = variables;
    this.numberVariables2 = variables.filter((v) => {
      //Repeating child variables should
      //only show in top dropdown, and the
      //second dropdown should not be displayed when they are selected.
      if (!v.itemChild) {
        return true;
      }
    });
  }

  buildForm() {
    this.form = this.fb.group(
      {
        SelectedNumberVariable1: this.fb.control(null),
        OperandId: this.fb.control({ code: Constants.MATH_ADD }),
        SelectedNumberVariable2: this.fb.control(null),
      },
      {
        validators: this.validateForm(
          !this.displayMode1,
          this.displayFirstChildOnly
        ),
      }
    );
  }

  validateForm(
    firstElementHidden: boolean,
    displayFirstChildOnly: boolean
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let formGroup: FormGroup = <FormGroup>control;
      /*Two form modes to validate: DisplayMode1 and 2.*/
      if (
        firstElementHidden &&
        (!formGroup.controls["OperandId"].value ||
          !formGroup.controls["SelectedNumberVariable2"].value)
      ) {
        return { FormContainsNull: true };
      }
      /*If the first element is not hidden, there are two scenarios:
       * Either the second element is visible, and a value must be selected,
       * Or a Sum of* variable is selected, and a second variable must not be selected.*/

      if (
        !firstElementHidden &&
        RegExp(/^Sum of*/).test(
          formGroup.controls["SelectedNumberVariable1"].value?.text
        )
      ) {
        return null;
      }
      if (
        !firstElementHidden &&
        (!formGroup.controls["SelectedNumberVariable1"].value ||
          !formGroup.controls["OperandId"].value ||
          !formGroup.controls["SelectedNumberVariable2"].value)
      ) {
        return { FormContainsNull: true };
      }

      if (
        displayFirstChildOnly &&
        !formGroup.controls["SelectedNumberVariable1"].value
      ) {
        return { FormContainsNull: true };
      }
      return null;
    };
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.invalid) {
      return { FormInvalid: true };
    } else return null;
  }
}
