<body>
  <!-- section 2 - logo -->
  <div class="container-fluid text-center py-4">
    <img
      src="../../assets/img/gavel-formerly_violet_and_black_200_62.png"
      class="img-fluid"
      alt="Gavel Logo"
    />
  </div>
  <!-- section 3 - title and content, video, button -->
  <div class="container-fluid pt-4">
    <div class="text-center">
      <h3 style="font-size: 1.75rem">Welcome!</h3>
    </div>
    <p>Automate your template documents with Gavel.</p>
  </div>
  <div>
    <img
      class="card-img-top img-fluid w-100"
      src="../../assets/img/player.png"
    />
  </div>
  <div class="container-fluid pb-4">
    <div class="pt-2 text-center">
      <p>Login and get started!</p>
    </div>
    <div class="text-center pb-2 mb-4">
      <button
        [routerLink]="['/login']"
        class="btn btn-custom py-1"
        style="width: 100px"
      >
        Start
      </button>
    </div>
    <div class="text-center pt-0 mb-4">
      <a class="link-primary" routerLink="['/login']" (click)="skipSplash()"
        >Skip splash page next time</a
      >
    </div>
    <div class="text-center small p-2" style="background-color: #d4c5fc">
      Don't have a Gavel account?,<br />Get a free trial at
      <a href="https://www.Gavel.io/">Gavel.io</a>
    </div>
    <p class="text-center small">v{{ software_version }}</p>
    <br />
  </div>
  <app-footer></app-footer>
</body>
