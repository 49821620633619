import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { SettingsService } from "./settings.service";
import { Constants } from "./Constants";
import { LoginSettingsModel } from "../login/LoginSettingsModel";
import { FetchWorkflowVariableResult } from "./FetchWorkflowVariableResult";
import { FetchResult } from "./FetchResult";
import { PostResult } from "./PostResult";
import { rollbarFactory, RollbarService } from "./rollbarerrorhandler.service";
import Rollbar from "rollbar";

@Injectable({ providedIn: "root" })
export class ApiService {
  private loginSettings: LoginSettingsModel;

  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  Init() {
    if (this.settingsService.isAvailable()) {
      this.loginSettings = this.settingsService.getSettings(
        Constants.LOGIN_SETTINGS_ACTIVE_KEY
      );
      /*console.debug(
        `Login settings imported for ${this.loginSettings.subdomain}.${this.loginSettings.selectedDomain}`
      );*/
    }
  }

  public async FetchWorkflows(): Promise<FetchResult> {
    let __ = this;

    __.Init();

    //Build URL
    const url = `${__.Path}/api/playground?folder=questions`;

    return __.Fetch(url);
  }
  public async FetchWorkflowVariables(
    workflowName: string
  ): Promise<FetchWorkflowVariableResult> {
    let __ = this;

    __.Init();

    //Build URL
    const url = `${__.Path}/officeaddin?pgtypes=${workflowName}.yml`;

    return __.Fetch(url);
  }

  public get Path(): string {
    let loginSettings = this.loginSettings;
    let path =
      loginSettings?.selectedDomain == "custom"
        ? loginSettings?.customDomain
        : `${loginSettings?.subdomain}\.${loginSettings?.selectedDomain}`;
    if (path.startsWith("http://")) return path;
    return `https://${path}`;
  }

  public async UploadDocument(formData: FormData): Promise<PostResult> {
    let __ = this;

    const url = `${__.Path}/api/playground`;

    return this.Post(url, formData);
  }

  private async Post(url: string, formData: FormData) {
    let __ = this;
    let pResult = new PostResult();

    //Build headers
    const requestHeaders = new HttpHeaders({
      "X-API-KEY": __.loginSettings.APIKey,
    });

    console.debug(`ApiService posting ${url}`);
    await this.httpClient
      .post<Blob>(url, formData, {
        headers: requestHeaders,
        observe: "response",
      })
      .toPromise()
      .then(
        (data) => {
          if (data.status == 200 || data.status == 204) {
            console.debug(`post successful`);
            pResult.isError = false;
            pResult.value = data.body;
          } else {
            /*Unsuccessful request*/
            pResult.isError = true;
            pResult.errorDescription = `http ${data?.status} ${data?.statusText}`;
            this.rollbar.warn(`post unsuccessful ${pResult?.errorDescription}`);
          }
        },
        (err) => {
          /*Unsuccessful request*/
          pResult.isError = true;
          pResult.errorDescription = `http ${err?.status} ${err?.statusText}`;
          this.rollbar.warn(`post unsuccessful ${pResult?.errorDescription}`);
        }
      );

    return Promise.resolve(pResult);
  }

  private async Fetch(url: string): Promise<FetchResult> {
    let __ = this;
    let fResult = new FetchResult();

    //Build headers
    const requestHeaders = new HttpHeaders({
      "X-API-KEY": __.loginSettings.APIKey,
    });

    console.debug(`ApiService Fetching ${url}`);
    await this.httpClient
      .get<HttpResponse<object>>(url, {
        headers: requestHeaders,
        observe: "response",
      })
      .toPromise()
      .then(
        (data) => {
          if (data.status == 200) {
            console.debug(`fetch successful`);
            fResult.isError = false;
            fResult.value = data.body;
          } else {
            /*Unsuccessful request*/
            fResult.isError = true;
            fResult.errorDescription = `http ${data?.status} ${data?.statusText}`;
            this.rollbar.warning(
              `fetch of ${url} unsuccessful ${fResult?.errorDescription}`
            );
          }
        },
        (err) => {
          /*Unsuccessful request*/
          fResult.isError = true;
          fResult.errorDescription = `http ${err?.status} ${err?.statusText}`;
          this.rollbar.warning(
            `Exception thrown while fetching ${url} ${JSON.stringify(fResult)}`
          );
          return Promise.reject(fResult);
        }
      );

    return Promise.resolve(fResult);
  }
}
