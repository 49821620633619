import { DocumentSyntax, TableRowSyntax } from "./DocumentSyntax";
import { SyntaxContainer } from "../shared/Types";
import { RegexConstants } from "../shared/RegexConstants";
import select = Office.select;

export class DocumentScanner {
  /*Scans the document for potential Gavel
   * syntax, and returns an array of Word.Range & Word.Paragraph objects.
   */

  static async ScanDocument(
    context: Word.RequestContext
  ): Promise<DocumentSyntax> {
    let [documate_paragraphs, orphans] =
      await DocumentScanner.GetDocumateParagraphsFromDocument(context);

    let documate_ranges: SyntaxContainer[] =
      await DocumentScanner.GetAllDocumateSyntaxFromDocument(context);

    let ooxml: string = await DocumentScanner.GetBodyAsOOXML(context);

    let documate_tableRows = await DocumentScanner.GetAllTableRowStatements(
      context
    );

    let tableRows = await DocumentScanner.GetAllTableRowsWithSyntax(context);

    return new DocumentSyntax(
      documate_ranges,
      documate_paragraphs,
      ooxml,
      documate_tableRows,
      orphans,
      tableRows
    );
  }

  /*Performs a wildcard search using the Word API.
  The search function is *nowhere near* as powerful as regex,
  but it has a couple of similarities.
   */
  private static async GetAllDocumateSyntaxFromDocument(
    context: Word.RequestContext
  ): Promise<SyntaxContainer[]> {
    let doc_bodies = await this.GetDocumentAsBodyArray(context);
    let syntax: SyntaxContainer[] = [];

    for (const body of doc_bodies) {
      let ranges: Word.RangeCollection = body.search("[{]{1,}*[}]{1,}", {
        matchWildcards: true,
      });

      ranges.load("items, font, text");
      ranges.track();
      await context.sync();

      syntax.push(...ranges.items);
    }

    syntax.push(...(await this.GetAllContentControlsFromDocument(context)));
    syntax.forEach((range) => {
      range.track();
    });

    return syntax;
  }

  private static async GetAllContentControlsFromDocument(
    context: Word.RequestContext
  ): Promise<Word.Paragraph[]> {
    context.load(context.document, "contentControls");
    await context.sync();
    let contentControls = context.document.contentControls;
    let paragraphs = new Array<Word.Paragraph>();
    context.load(contentControls, "items, font, text");
    await context.sync();

    for await (const control of contentControls.items) {
      let paragraphCollection = control.paragraphs;
      context.load(paragraphCollection, "items,font,text");
      await context.sync();
      paragraphs.push(...paragraphCollection.items);
    }
    paragraphs.forEach((paragraph) => paragraph.track());
    return paragraphs;
  }

  /*Returns all paragraph objects in the document
   */
  private static async GetDocumateParagraphsFromDocument(
    context: Word.RequestContext
  ): Promise<[Word.Paragraph[], Word.Paragraph[]]> {
    let documate_paragraphs: Word.Paragraph[] = [];
    let orphans: Word.Paragraph[] = [];

    let bodies = await DocumentScanner.GetDocumentAsBodyArray(context);

    for (const body of bodies) {
      let paragraphCollection: Word.ParagraphCollection = body.paragraphs;

      // Queue a command to load the search results and get the property values.
      //We have to sync the context before we can
      //Use the properties we loaded in the previous call.
      context.load(paragraphCollection, "items, font, text");
      paragraphCollection.track();
      await context.sync();

      /*We search each paragraph object returned for Documate paragraph
     syntax, and also orphaned brackets. This makes searching for paragraph
     statements that do not appear on their own line much easier, and our wildcard
     based search does not detect missing brackets, so this is a good place to do that.
      */

      paragraphCollection.items.forEach((paragraph) => {
        if (DocumentScanner.ContainsOrphanedBracket(paragraph.text)) {
          paragraph.track();
          orphans.push(paragraph);
        }
        if (DocumentScanner.ContainsParagraphStatement(paragraph.text)) {
          paragraph.track();
          documate_paragraphs.push(paragraph);
        }
      });
    }

    return [documate_paragraphs, orphans];
  }

  private static ContainsOrphanedBracket(text: string): boolean {
    if (
      text.split(/%}/)?.length !== text.split(/{%/)?.length ||
      text.split(/}}/)?.length !== text.split(/{{/)?.length
    ) {
      return true;
    }
    return false;
  }

  private static ContainsParagraphStatement(text: string): boolean {
    if (/{%p/.test(text)) {
      return true;
    }
    return false;
  }

  private static async GetBodyAsOOXML(
    context: Word.RequestContext
  ): Promise<string> {
    let ooxml = context.document.body.getOoxml();
    await context.sync();

    let XMLReader = require("xml-reader");
    let ast = XMLReader.parseSync(ooxml.value);
    let xmlQuery = require("xml-query");
    let xq = xmlQuery(ast);
    let res = xq
      .find("mc:AlternateContent")
      ?.find("mc:Choice")
      ?.find("w:t")
      ?.text();
    return ooxml.value;
  }

  private static async GetAllTableRowStatements(
    context: Word.RequestContext
  ): Promise<Word.Range[]> {
    let ranges = context.document.body.search("([{])%tr*%([}])", {
      matchWildcards: true,
    });
    context.load(ranges, "item, font, text");
    ranges.track();
    await context.sync();
    ranges.items.forEach((range) => range.track());
    return ranges.items;
  }

  private static async GetAllTableRowsWithSyntax(
    context: Word.RequestContext
  ): Promise<TableRowSyntax[] | null> {
    if (Office.context.requirements.isSetSupported("WordApi", "1.3")) {
      let payload: TableRowSyntax[] = [];
      let tables: Word.Table[] = [];
      let rows: Word.TableRow[] = [];
      let cells: Word.TableCell[] = [];

      let tableCollection = context.document.body.tables;

      tableCollection.load("items");

      await context.sync();

      tableCollection.items.forEach((t) => {
        t.track();
        tables.push(t);
      });

      //For each table, check each cell. If the cell contains table row syntax,
      //Get the whole row for later processing.
      for (const t of tables) {
        context.load(t.rows);
        await context.sync();
        let rowCollection = t.rows;

        /*rowCollection.load("items");
          await context.sync();
*/
        rowCollection.items.forEach((r) => {
          r.track();
          rows.push(r);
        });
      }

      for (const r of rows) {
        console.info(`Cell count: ${r.cellCount}`);
        //r.load(["cells", "cellCount"]);
        /*await context.sync();*/

        r.context.load(r.cells);
        r.context.load(r.parentTable);
        await r.context.sync();

        /*let cellCollection = r.cells;

        cellCollection.items.forEach((c) => {
          c.track();
          cells.push(c);
        });*/

        for (const c of r.cells.items) {
          c.load("value");
          await context.sync();
          let text = c.value;
          if (RegexConstants.regexTableRow.test(text)) {
            r.track();
            let range = r.parentTable.getRange();
            range.track();
            range.load("text");
            await context.sync();
            payload.push(new TableRowSyntax(r, range));
            break;
          }
        }
      }

      return payload;
    } else {
      return null;
    }
  }

  private static async GetDocumentAsBodyArray(
    context: Word.RequestContext
  ): Promise<Word.Body[]> {
    let document_bodies: Word.Body[] = [];

    let sections = context.document.sections;
    context.load(sections, "items");
    sections.track();
    await context.sync();
    for (const section of sections.items) {
      document_bodies.push(
        ...[
          section.getHeader("Primary"),
          section.body,
          section.getHeader("FirstPage"),
          section.getHeader("EvenPages"),
          section.getFooter("Primary"),
          section.getFooter("FirstPage"),
          section.getFooter("EvenPages"),
        ]
      );
    }
    return document_bodies;
  }

  public static async GetForLoops(): Promise<string[]> {
    /*Get cursor position, and expand the range
     * to the beginning of the document. */
    let bodyBeforeCursor: Word.Range;

    await Word.run(async (context) => {
      if (context.document.body.getRange("Start").expandTo) {
        let body = context.document.body;
        body.load("paragraphs");
        await context.sync();

        let paragraphs = context.document.body.paragraphs;

        paragraphs.load("items");
        await context.sync();

        let bodyStart = paragraphs?.items[0]?.getRange("Start");

        let selection = context.document.getSelection();
        bodyStart.load("text");
        selection.load("text");
        await context.sync();

        bodyBeforeCursor = selection.expandTo(bodyStart);

        bodyBeforeCursor.load("text");

        await context.sync();
      }
    });
    /*Search the text for For loops that are open at the cursor position,
     * return the list of variable names.*/
    return Promise.resolve(this.OpenForLoops(bodyBeforeCursor.text));
  }

  private static OpenForLoops(body: string): string[] {
    let forloopregex = /{%p? for item in \w+ %}|{%p? endfor %}/g;
    let results = forloopregex.exec(body);
    let stack = [];
    while (results) {
      results?.forEach((r, i) => {
        if (/{%p? for item in (\w+) %}/.test(r)) {
          stack.push(r);
        } else if (/{%p? endfor %}/.test(r)) {
          stack.pop();
        }
      });
      results = forloopregex.exec(body);
    }

    let openLoops = [];
    stack.forEach((s) => {
      let loopName = /{%p? for item in (\w+) %}/.exec(s)[1];
      openLoops.push(loopName);
    });
    return openLoops;
  }
}
