<form [formGroup]="form">
  <div class="form-group form-group-isisnot">
    <div class="form-check form-check-inline form-check-inline-radio">
      <input
        class="form-check-input"
        type="radio"
        id="{{ uniqueId }}.and"
        value="and"
        formControlName="selectedValue"
        checked
      />
      <label
        (click)="onValueChange('and')"
        class="form-check-label"
        for="{{ uniqueId }}.and"
        >and</label
      >
    </div>

    <div class="form-check form-check-inline form-check-inline-radio">
      <input
        formControlName="selectedValue"
        class="form-check-input"
        type="radio"
        id="{{ uniqueId }}.or"
        value="or"
      />
      <label
        (click)="onValueChange('or')"
        class="form-check-label"
        for="{{ uniqueId }}.or"
        >or</label
      >
    </div>
  </div>
</form>
