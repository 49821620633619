<div xmlns="http://www.w3.org/1999/html">
  <app-interview></app-interview>
  <app-progress-bar [pbMode]="pbMode"></app-progress-bar>
</div>

<!-- section 2 - forms -->
<div class="container-fluid py-3">
  <h3>ADD VARIABLES</h3>
  <mat-accordion #accordion togglePosition="before">
    <mat-expansion-panel #simpleVarPanel [expanded]="false" hideToggle="true">
      <mat-expansion-panel-header
        [collapsedHeight]="'30px'"
        [expandedHeight]="'30px'"
      >
        <!--        <mat-panel-description>-->
        <!--        </mat-panel-description>-->
        <mat-panel-title>
          <mat-icon class="expansion-arrow" *ngIf="!simpleVarPanel.expanded"
            >keyboard_arrow_right
          </mat-icon>
          <mat-icon class="expansion-arrow" *ngIf="simpleVarPanel.expanded"
            >keyboard_arrow_down
          </mat-icon>
          Simple Variables
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-simple-variable></app-simple-variable>
    </mat-expansion-panel>

    <mat-expansion-panel
      #phraseVarPanel
      [expanded]="false"
      [disabled]="disableConditionals"
      hideToggle="true"
    >
      <mat-expansion-panel-header
        [collapsedHeight]="'30px'"
        [expandedHeight]="'30px'"
      >
        <mat-panel-title>
          <mat-icon class="expansion-arrow" *ngIf="!phraseVarPanel.expanded"
            >keyboard_arrow_right
          </mat-icon>
          <mat-icon class="expansion-arrow" *ngIf="phraseVarPanel.expanded"
            >keyboard_arrow_down
          </mat-icon>
          Show phrase when...
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-conditional [type]="conditionalTypes.Phrase"></app-conditional>
    </mat-expansion-panel>
    <mat-expansion-panel
      #paraVarPanel
      [expanded]="false"
      [disabled]="disableConditionals"
      hideToggle="true"
    >
      <mat-expansion-panel-header
        [collapsedHeight]="'30px'"
        [expandedHeight]="'30px'"
      >
        <mat-panel-title>
          <mat-icon class="expansion-arrow" *ngIf="!paraVarPanel.expanded"
            >keyboard_arrow_right
          </mat-icon>
          <mat-icon class="expansion-arrow" *ngIf="paraVarPanel.expanded"
            >keyboard_arrow_down
          </mat-icon>
          Show paragraph when...
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-conditional [type]="conditionalTypes.Paragraph"></app-conditional>
    </mat-expansion-panel>
    <mat-expansion-panel
      #numberVarPanel
      [expanded]="false"
      [disabled]="disableNumbers"
      hideToggle="true"
    >
      <mat-expansion-panel-header
        [collapsedHeight]="'30px'"
        [expandedHeight]="'30px'"
      >
        <mat-panel-title>
          <mat-icon class="expansion-arrow" *ngIf="!numberVarPanel.expanded"
            >keyboard_arrow_right
          </mat-icon>
          <mat-icon class="expansion-arrow" *ngIf="numberVarPanel.expanded"
            >keyboard_arrow_down
          </mat-icon>
          Numerical Calculation
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-calc-number></app-calc-number>
    </mat-expansion-panel>
    <mat-expansion-panel
      #dateVarPanel
      [expanded]="false"
      [disabled]="disableDates"
      hideToggle="true"
    >
      <mat-expansion-panel-header
        [collapsedHeight]="'30px'"
        [expandedHeight]="'30px'"
      >
        <mat-panel-title>
          <mat-icon class="expansion-arrow" *ngIf="!dateVarPanel.expanded"
            >keyboard_arrow_right
          </mat-icon>
          <mat-icon class="expansion-arrow" *ngIf="dateVarPanel.expanded"
            >keyboard_arrow_down
          </mat-icon>
          Date Calculation
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-calc-date></app-calc-date>
    </mat-expansion-panel>
    <mat-expansion-panel
      #objectVarPanel
      [expanded]="false"
      [disabled]="disableObjects"
      hideToggle="true"
    >
      <mat-expansion-panel-header
        [collapsedHeight]="'30px'"
        [expandedHeight]="'30px'"
      >
        <mat-panel-title>
          <mat-icon class="expansion-arrow" *ngIf="!objectVarPanel.expanded"
            >keyboard_arrow_right
          </mat-icon>
          <mat-icon class="expansion-arrow" *ngIf="objectVarPanel.expanded"
            >keyboard_arrow_down
          </mat-icon>
          Repeating Item
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-repeat-variable></app-repeat-variable>
    </mat-expansion-panel>
    <mat-expansion-panel
      #signatureVarPanel
      [expanded]="false"
      [disabled]="disableSignature"
      hideToggle="true"
    >
      <mat-expansion-panel-header
        [collapsedHeight]="'30px'"
        [expandedHeight]="'30px'"
      >
        <mat-panel-title>
          <mat-icon class="expansion-arrow" *ngIf="!signatureVarPanel.expanded"
            >keyboard_arrow_right
          </mat-icon>
          <mat-icon class="expansion-arrow" *ngIf="signatureVarPanel.expanded"
            >keyboard_arrow_down
          </mat-icon>
          Signatures
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-signature-variable></app-signature-variable>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div class="container-fluid pb-5">
  <app-syntax-check></app-syntax-check>
  <app-upload-doc *ngIf="!isIE11"></app-upload-doc>
</div>
<app-footer ShowLogoutButton="true"></app-footer>
<!--<div class="container-fluid footer-top fixed-bottom py-1 border-top bg-white">
  <div class="text-green">
    <img
      src="../../assets/img/documate-logo.png"
      class="img-fluid align-baseline"
      alt="Documate Logo"
    />
    Template Builder
    <mat-icon
      style="
        text-align: right;
        margin-right: 0px;
        padding-right: 0px;
        padding-left: 55px;
        cursor: pointer;
      "
      class="align-bottom logout"
      (click)="logout()"
      matTooltip="Logout"
      >exit_to_app
    </mat-icon>
  </div>
</div>-->
