import { Component, ViewChild } from "@angular/core";
import { ConditionalType } from "../conditional/helpers/ConditionalExpressionUtils";
import {
  WorkflowService,
  WorkflowServiceState,
} from "../shared/workflow.service";
import { SimpleVariable } from "../shared/variableTypes/SimpleVariable";
import { MatExpansionPanel } from "@angular/material/expansion";
import { FeatureCheck } from "../shared/FeatureCheck";
import { ActivatedRoute, Router } from "@angular/router";
import { ProgressBarMode } from "@angular/material/progress-bar";

/**
 * Container for all of the business sections of the add in.
 * The interview selector and the Progress bar are pretty much
 * self policing. The variable/syntax selection components are housed
 * inside of an expansion panel.
 *
 * This class contains all of the business logic for the expanding,
 * collapsing, disabling, as well as enabling the specific panels
 * on the variable change event.
 *
 * The logic here could probably be simplified quite a bit.
 */
@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.css"],
})
export class EditorComponent {
  pbMode: ProgressBarMode;

  /**
   * On creation this component collapses all expansion panels,
   * subscribes to each set of workflow Variables and calls a method
   * that sets the visibility or enabled status of each panel.
   * @param interview
   */
  constructor(private interview: WorkflowService, private router: Router) {
    this.interview.workflowVariablesSimple.subscribe((x) => {
      this.closeNonSimplePanels();
    });

    this.interview.workflowVariablesNumber.subscribe((x) => {
      this.setNumberPanelVisibility(x);
    });

    this.interview.workflowVariablesConditional.subscribe((x) => {
      this.setConditionalPanelVisibility(x);
    });

    this.interview.workflowVariablesDate.subscribe((x) => {
      this.setDatePanelVisibility(x);
    });

    this.interview.workflowVariablesRepeat.subscribe((x) => {
      this.setRepeatPanelVisibility(x);
    });

    this.interview.workflowVariablesSignature.subscribe((x) => {
      this.setSignaturePanelVisibility(x);
    });

    this.interview.serviceState.subscribe((value) => {
      if (value == WorkflowServiceState.BUSY) {
        this.closeNonSimplePanels();
        this.disableConditionals =
          this.disableDates =
          this.disableNumbers =
          this.disableObjects =
            true;

        this.pbMode = "indeterminate";
      } else {
        this.pbMode = "determinate";
      }
    });
  }

  /*Check if promises are available for the upload
   * document feature*/
  isIE11: boolean = FeatureCheck.isIE11();

  /**
   * A reference to the expansion panel for Simple Variables
   */
  @ViewChild("simpleVarPanel", { static: true })
  simpleVariablePanel: MatExpansionPanel;

  /**
   * A reference to the expansion panel for Conditional Phrase Variables
   */
  @ViewChild("phraseVarPanel", { static: true })
  phraseVariablePanel: MatExpansionPanel;

  /**
   * A reference to the expansion panel for Conditional Paragraph Variables
   */
  @ViewChild("paraVarPanel", { static: true })
  paraVariablePanel: MatExpansionPanel;

  /**
   * A reference to the expansion panel for Number Variables
   */
  @ViewChild("numberVarPanel", { static: true })
  numberVariablePanel: MatExpansionPanel;

  /**
   * A reference to the expansion panel for Date Variables
   */
  @ViewChild("dateVarPanel", { static: true })
  dateVariablePanel: MatExpansionPanel;

  /**
   * A reference to the expansion panel for Repeat/Object Variables
   */
  @ViewChild("objectVarPanel", { static: true })
  objectVariablePanel: MatExpansionPanel;

  /**
   * A reference to the expansion panel for Docusign/Signature Variables
   */
  @ViewChild("signatureVarPanel", { static: true })
  signatureVariablePanel: MatExpansionPanel;

  /**
   * Maps the ConditionalType enum so that it can be used
   * directly in the template. https://stackoverflow.com/questions/35923744/pass-enums-in-angular2-view-templates
   */
  conditionalTypes = ConditionalType;

  /**
   * Flag to disable the Conditional variables expansion panel
   */
  disableConditionals: boolean = true;

  /**
   * Flag to disable the Number variables expansion panel
   */
  disableNumbers: boolean = true;

  /**
   * Flag to disable the Dates variables expansion panel
   */
  disableDates: boolean = true;

  /**
   * Flag to disable the Repeat/Object variables expansion panel
   */
  disableObjects: boolean = true;

  /**
   * Flag to disable the Docusign/Signature variables expansion panel
   */
  disableSignature = true;

  /**
   * Closes every expansion panel except for the Simple
   * variables panel
   */
  closeNonSimplePanels(): void {
    this.paraVariablePanel?.close();
    this.phraseVariablePanel?.close();
    this.numberVariablePanel?.close();
    this.objectVariablePanel?.close();
    this.dateVariablePanel?.close();
  }

  /**
   * Sets the disableNumbers flag based on the length of
   * the array
   * @param x
   */
  setNumberPanelVisibility(x: SimpleVariable[]): void {
    this.disableNumbers = x.length <= 0;
  }

  /**
   * Sets the disableConditionals flag based on the length of
   * the array
   * @param x
   */
  setConditionalPanelVisibility(x: SimpleVariable[]): void {
    this.disableConditionals = x.length <= 0;
  }

  /**
   * Sets the disableDates flag based on the length of
   * the array
   * @param x
   */
  setDatePanelVisibility(x: SimpleVariable[]): void {
    this.disableDates = x.length <= 0;
  }

  /**
   * Sets the disableObjects flag based on the length of
   * the array
   * @param x
   */
  setRepeatPanelVisibility(x: SimpleVariable[]): void {
    this.disableObjects = x.length <= 0;
  }

  /**
   * Sets the disableSignature flag based on the length of
   * the array
   */
  setSignaturePanelVisibility(x: SimpleVariable[]): void {
    this.disableSignature =
      !this.interview.docusignConfig?.enabled && x.length <= 0;
  }
}
