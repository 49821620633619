import {
  ConditionalChild,
  ConditionalComparisonOption,
} from "../models/conditional-child.model";
import { VariableDataType } from "../../shared/variableTypes/VariableType";
import { ConditionalExpressionUtils } from "./ConditionalExpressionUtils";
import { RegexConstants } from "../../shared/RegexConstants";

export class ConditionalSingleSelectExpressionBuilder {
  public static Build(data: ConditionalChild): string {
    let expr;
    let var_expr = "";

    if (
      RegexConstants.REGEX_CONDITIONAL_BLANK.test(
        data.selectedMultipleChoice.toString()
      )
    ) {
      /*Set to proper blank syntax for Radio variables vs. Dropdown variables*/
      var_expr =
        data.selectedWorkflowVariable.variableDataType == VariableDataType.Radio
          ? `"None"`
          : `""`;
    } else if (
      data.selectedWorkflowVariable.variableDataType != VariableDataType.Date
    ) {
      var_expr = ConditionalExpressionUtils.VariableParser(
        data.selectedMultipleChoice.toString()
      );
    }

    if (data.optionGtLtEqValue == ConditionalComparisonOption.is) {
      expr = `${data.selectedWorkflowVariable.id} == ${var_expr}`;
    } else {
      expr = `${data.selectedWorkflowVariable.id} != ${var_expr}`;
    }

    return expr;
  }
}
