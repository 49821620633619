import { SimpleVariable } from "src/app/shared/variableTypes/SimpleVariable";
import { Parser } from "../AST/Parser";
import { DocumentSyntax } from "../DocumentSyntax";
import { ParagraphCheck } from "./ParagraphCheck";
import { SymbolTable } from "../AST/SymbolTable";
import { StatementMatcher } from "../StatementMatcher/StatementMatcher";
import {
  IdentifierNotFoundException,
  ParserException,
  VariableNotFoundException,
} from "../AST/ParserException";
import { Constants } from "../../shared/Constants";
import { ParenthesisCheck } from "./ParenthesisCheck";
import { TableCheck } from "./TableCheck";

export class ReportBuilder {
  private static report: Map<Word.Range | Word.Paragraph, string[]>;
  static BuildReport(
    documentSyntax: DocumentSyntax,
    interviewVariables: SimpleVariable[]
  ): Map<Word.Range | Word.Paragraph, string[]> {
    let expressions = documentSyntax.ranges;
    this.report = StatementMatcher.Match(documentSyntax);
    ParagraphCheck.Check(documentSyntax.paragraphs).forEach((v, k) => {
      this.report.set(k, v);
    });
    ParenthesisCheck.Check(documentSyntax.ranges).forEach((v, k) => {
      this.report.set(k, v);
    });
    if (Office.context.requirements.isSetSupported("WordApi", "1.3")) {
      TableCheck.Check(documentSyntax.tableRows).forEach((v, k) => {
        this.report.set(k, v);
      });
    }
    let symbolTable = new SymbolTable(interviewVariables);

    expressions.forEach((expression) => {
      try {
        Parser.Parse(expression.text, symbolTable);
      } catch (e) {
        let error_msg = "";
        if (
          e instanceof IdentifierNotFoundException ||
          e instanceof VariableNotFoundException
        ) {
          error_msg = `<li>${e.message}</li>`;
        } else {
          error_msg = `<li>${expression.text} is not valid syntax: ${<
            ParserException
          >e.message}</li>`;
        }
        ReportBuilder.AddErrorToReport(expression, error_msg);
      }
    });

    documentSyntax.orphans.forEach((orphan) => {
      this.AddErrorToReport(orphan, Constants.MISSING_BRACES_SYNTAX_ERROR);
    });

    //Untrack any key that does not have bad
    //syntax at this point, resource housekeeping.
    expressions.forEach((key) => {
      if (!this.report.has(key)) {
        key.untrack();
      }
    });

    return this.report;
  }

  private static AddErrorToReport(
    expresson: Word.Range | Word.Paragraph,
    error: string
  ) {
    if (this.report.get(expresson) != undefined) {
      this.report.get(expresson).push(error);
      return;
    } else {
      this.report.set(expresson, [error]);
    }
  }
}
