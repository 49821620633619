<div class="container-fluid pb-0 mb-5">
  <div class="text-center pb-2 mb-4">
    <button
      [disabled]="disableCheckSyntax"
      (click)="checkSyntax()"
      class="btn btn-syntax btn-light border btn-block py-2"
      [innerHTML]="buttonText"
    ></button>
    <hr />
    <form
      class="align-items-center"
      style="padding-left: 70px; padding-right: 70px"
      [formGroup]="form"
    >
      <button
        class="link-button"
        [ngClass]="{ 'disabled-button': disableHighlightSyntax }"
        (click)="highlightSyntax()"
        [disabled]="disableHighlightSyntax"
      >
        Highlight Syntax
      </button>
      <app-progress-bar
        class="flex align-items-center"
        [pbMode]="pbMode"
        [hidden]="!disableHighlightSyntax"
      ></app-progress-bar>
    </form>
    <!--TODO Put progress bar back!-->
    <!-- <mat-progress-bar
      class="btn-custom"
      [hidden]="false"
      [value]="SyntaxCheckProgress"
    ></mat-progress-bar>-->
    <!--<p class="text-green" [innerHTML]="SyntaxCheckStatus"></p>-->
  </div>
  <ng-container *ngIf="invalidSyntaxKeys.length > 0">
    <div
      [innerHTML]="selectedMalformedVariableError.join(' ')"
      class="alert notification-warning text-break"
    ></div>
    <div>
      <button
        class="btn btn-outline-dark nav-btn w-50"
        [disabled]="invalidSyntaxKeyIndex == 0"
        (click)="syntaxNavigate('prev')"
      >
        <mat-icon class="material-icons align-top">navigate_before</mat-icon
        >Prev
      </button>
      <button
        class="btn btn-outline-dark nav-btn w-50"
        [disabled]="invalidSyntaxKeyIndex == invalidSyntaxKeys.length - 1"
        (click)="syntaxNavigate('next')"
      >
        Next
        <mat-icon class="material-icons align-top">navigate_next</mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="displaySyntaxCheckPassed">
    <p class="alert alert-success text-center">
      <mat-icon class="material-icons syntax-check-passed">done</mat-icon> No
      errors found!
    </p></ng-container
  >
</div>
