<form [formGroup]="form">
  <div class="form-group">
    <app-conditional-comparison
      formControlName="OptionGtLtEqValue"
      displayValueOption="soloChild"
    >
    </app-conditional-comparison>
    <ng-container *ngIf="displayMode1">
      <div class="form-group">
        <input
          formControlName="InputGtLtEq"
          class="form-control form-control-sm hs-spin-edit"
          type="number"
          min="0"
          step="1"
          value="1"
        />
        <br />
      </div>
    </ng-container>

    <!-- Sub form for testing for the value of a repeating object member-->
    <ng-container *ngIf="displayMode2">
      <app-variable-dropdown
        tooltipPosition="top"
        formControlName="SelectedRepeatingMember"
        [appendTo]="'body'"
        [options]="selectedObjectVariableRepeatingMembers"
        [clearable]="false"
      >
      </app-variable-dropdown>
      <ng-container *ngIf="this.form.controls['SelectedRepeatingMember'].value">
        <ng-container *ngIf="displayMode4">
          <div class="row no-gutters">
            <div class="col-5">
              <input
                type="text"
                class="form-control form-control-sm disabled rounded-left"
                style="border-radius: 0; font-weight: bold"
                value="Is equal to:"
                disabled
              />
            </div>
            <div class="col-7">
              <input
                formControlName="InputChildMemberComparison"
                type="text"
                class="form-control form-control-sm rounded-right"
                style="border-radius: 0"
              />
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayMode5">
          <div class="row no-gutters">
            <div class="col-5">
              <input
                type="text"
                class="form-control form-control-sm disabled rounded-left"
                style="border-radius: 0; font-weight: bold"
                value="Is equal to:"
                disabled
              />
            </div>
            <div class="col-7">
              <input
                formControlName="InputChildNumberComparison"
                class="form-control form-control-sm hs-spin-edit"
                type="number"
                min="0"
                step="1"
                value="1"
              />
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayMode3">
          <input
            type="text"
            class="form-control form-control-sm disabled"
            style="border-radius: 2px; font-weight: bold"
            value="Is equal to:"
            disabled
          />

          <app-variable-dropdown
            class="mt-3"
            formControlName="InputChildComplexMemberComparison"
            [appendTo]="'body'"
            [options]="getSelectedRepeatingMemberOptions()"
            [clearable]="false"
          ></app-variable-dropdown> </ng-container
      ></ng-container>
    </ng-container>
    <!-- End Sub form for testing for the value of a repeating object member-->
  </div>
</form>
