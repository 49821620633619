export enum ComplexVariableType {
  Simple,
  Complex,
}

export enum VariableDataType {
  Text,
  Checkboxes,
  Email,
  Boolean,
  Dropdown,
  Date,
  Number,
  File,
  Area,
  Integer,
  Note,
  Button,
  Object,
  Object_from_file,
  Radio,
  SimpleConditional,
  Signature,
  Docusign,
}
