import { Component } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import {
  ConditionalChild,
  ConditionalComparisonOption,
} from "../../models/conditional-child.model";

@Component({
  selector: "app-conditional-number",
  templateUrl: "./conditional-number.component.html",
  styleUrls: ["./conditional-number.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConditionalNumberComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ConditionalNumberComponent,
    },
  ],
})
export class ConditionalNumberComponent
  implements ControlValueAccessor, Validator
{
  public onChange = (newValue) => {};
  public form: FormGroup;

  private conditionalChild: ConditionalChild = new ConditionalChild();

  constructor(private fb: FormBuilder) {
    this.buildForm();
    this.subscribeToFormChanges();
    this.initializeOutput();
  }

  subscribeToFormChanges() {
    this.form.controls["OptionGtLtEq"].valueChanges.subscribe((newValue) => {
      this.conditionalChild.optionGtLtEqValue = newValue;
      this.onChange(this.conditionalChild);
    });

    this.form.controls["inputComparisonNumber"].valueChanges.subscribe(
      (newValue) => {
        //Null, negative, and zero check
        if (!newValue || newValue < 1) {
          this.form.controls["inputComparisonNumber"].setValue(1);
        }
        this.conditionalChild.inputGtLtEq = newValue;
        this.onChange(this.conditionalChild);
      }
    );
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
    this.onChange(this.conditionalChild);
  }

  initializeOutput() {
    this.conditionalChild.inputGtLtEq = 1;
    this.conditionalChild.optionGtLtEqValue = ConditionalComparisonOption.gt;
    this.onChange(this.conditionalChild);
  }

  registerOnTouched(fn: any) {}
  setDisabledState(isDisabled: boolean) {}
  writeValue(obj: any) {}

  buildForm() {
    this.form = this.fb.group({
      OptionGtLtEq: new FormControl(">"),
      inputComparisonNumber: new FormControl(1),
    });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (
      this.form.controls["OptionGtLtEq"].value &&
      this.form.controls["inputComparisonNumber"].value
    )
      return null;
    else {
      return { FormContainsNull: true };
    }
  }
}
