<!-- section 1 - logo -->
<div class="container-fluid text-center py-4">
  <img
    src="../../assets/img/gavel-formerly_violet_and_black_200_62.png"
    class="img-fluid"
    alt="Gavel Logo"
  />
</div>

<!-- section 2 - forms -->
<form [formGroup]="loginFormGroup" (ngSubmit)="attemptLogin()">
  <div class="container-fluid py-2">
    <div class="form-group">
      <label>Site</label>
      <p-dropdown
        formControlName="selectedDomain"
        [appendTo]="'body'"
        [options]="loginViewModel.domainChoices"
        [showClear]=""false
      ></p-dropdown>
    </div>

    <div class="form-group">
      <label>Client Name</label>
      <ng-container
        *ngIf="
          loginFormGroup.controls['selectedDomain']?.value != 'custom'
        "
      >
        <!-- Terrible id for the input field below. TODO -->
        <div class="ui-inputgroup ui-grid">
          <p-autoComplete
            class="ui-fluid"
            formControlName="subdomain"
            [suggestions]="this.subDomainSuggestions"
            (completeMethod)="search($event)"
            [dropdown]="this.subDomainSuggestions?.length > 1"
            dropdownMode="current"
            (onSelect)="refreshSettings($event)"
            (onDropdownClick)="handleDropdown($event)"
          >
          </p-autoComplete>

          <input
            formControlName="lblSelectedDomain"
            type="text"
            class="ui-inputgroup-addon ui-grid-col-6"
            style="border-radius: 0"
          />
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          loginFormGroup.controls['selectedDomain']?.value == 'custom'
        "
      >
        <p-autoComplete
          class="ui-fluid"
          formControlName="customDomain"
          [suggestions]="this.subDomainSuggestions"
          (completeMethod)="search($event)"
          [dropdown]="this.subDomainSuggestions?.length > 1"
          dropdownMode="current"
          (onSelect)="refreshSettings($event)"
          (onDropdownClick)="handleDropdown($event)"
        >
        </p-autoComplete>
      </ng-container>
    </div>
    <div class="form-group">
      <label>Api Key</label>
      <mat-icon
        (click)="showApiKeyHelpMsg()"
        class="material-icons md-18"
        style="cursor: pointer"
      >
        help_outline
      </mat-icon>

      <input
        formControlName="APIKey"
        type="text"
        name="number"
        class="form-control form-control-sm"
      />
      <mat-hint [innerHTML]="apiKeyHelpMsg"></mat-hint>
    </div>
  </div>

  <div class="container-fluid pb-5">
    <div class="text-center pb-2 mb-4">
      <button
        [disabled]="!isFormValid()"
        type="submit"
        id="id-submit-btn"
        class="btn btn-custom py-1"
        style="width: 100px"
      >
        Sign in
      </button>
    </div>
    <app-notification></app-notification>
  </div>
</form>

<app-footer></app-footer>
