import Rollbar from "rollbar";
import { SelectItem } from "primeng";
import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { WorkflowService } from "../shared/workflow.service";
import { SimpleVariable } from "../shared/variableTypes/SimpleVariable";
import { SimpleFormattingOptions } from "../shared/SimpleFormattingOptions";
import { DocusignExpressionBuilder } from "./DocusignExpressionBuilder";
import { Utils } from "../shared/Utils";
import { RollbarService } from "../shared/rollbarerrorhandler.service";
import { DocusignField } from "../shared/variableTypes/DocusignField";
import { VariableDataType } from "../shared/variableTypes/VariableType";
import { SimpleExpressionBuilder } from "../simple/SimpleExpressionBuilder";

const ALLOWED_ROLES_FOR_ANCHORS = ["signers", "editors"];
const ROLE_TO_DESCRIPTION = {
  signers: "Signer",
  carbonCopies: "Receives copy",
  certifiedDeliveries: "Needs to view",
  editors: "Editor",
};

export interface DocusignTextOrVariable {
  value: string;
  is_variable: boolean;
  is_repeating_item: boolean;
}

export interface DocusignRecipient {
  email: DocusignTextOrVariable;
  name: DocusignTextOrVariable;
  role: string;
  routingOrder: number;
}

export interface DocusignSettings {
  includeUploads: boolean;
}

export interface DocusignConfig {
  documents: string[];
  recipients: DocusignRecipient[];
  settings: DocusignSettings;
  enabled: boolean;
}

@Component({
  selector: "app-signature-variable",
  templateUrl: "./signature.component.html",
})
export class SignatureComponent {
  simpleVariables: SimpleVariable[];
  availableFormats: SimpleFormattingOptions[];
  availableRecipients: SelectItem[];

  selectedVariable: SimpleVariable;
  selectedFormatId: SimpleFormattingOptions;
  selectedRecipientValue: { routingOrder: number };

  @ViewChild("insertBtn") insertBtn: ElementRef<HTMLButtonElement>;

  public selectedVariableControl: FormControl = new FormControl();

  public readonly VariableDataType = VariableDataType;

  constructor(
    private interview: WorkflowService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.selectedVariableControl.setValue(this.selectedVariable);
    this.subscribeToWorkflowVariables();
    this.subscribeToSelectedVariable();
  }

  subscribeToSelectedVariable() {
    this.selectedVariableControl.valueChanges.subscribe(
      (newVar: SimpleVariable) => {
        this.selectedVariable = newVar;
        this.updateFormattingOptions();
        this.updateRecipientOptions();
      }
    );
  }

  subscribeToWorkflowVariables() {
    this.interview.workflowVariablesSignature.subscribe((x) => {
      this.simpleVariables = x.length > 0 ? x : [];
      if (
        this.interview.docusignConfig?.enabled &&
        !this.simpleVariables.some(
          (variable) => variable instanceof DocusignField
        )
      ) {
        this.simpleVariables.unshift(
          new DocusignField("docusign_config", "Docusign Field")
        );
      }
    });
  }

  updateFormattingOptions() {
    this.availableFormats = this.selectedVariable?.simpleFormatOptions;
    this.selectedFormatId = this.availableFormats?.[0];
  }

  updateRecipientOptions() {
    if (this.selectedVariable?.variableDataType === VariableDataType.Docusign) {
      this.availableRecipients = this.interview.docusignConfig?.recipients.map(
        (recipient) => ({
          label: `${recipient.routingOrder} - ${recipient.name.value} | ${ROLE_TO_DESCRIPTION[recipient.role]
            }`,
          disabled: !ALLOWED_ROLES_FOR_ANCHORS.includes(recipient.role),
          value: {
            routingOrder: recipient.routingOrder,
          },
        })
      );
      this.selectedRecipientValue = this.availableRecipients?.[0].value;
    }
  }

  async run() {
    let expr: string;

    if (this.selectedVariable?.variableDataType === VariableDataType.Docusign) {
      const selectedRecipient = this.interview.docusignConfig?.recipients.find(
        (recipient) =>
          recipient.routingOrder === this.selectedRecipientValue.routingOrder
      );
      if (!selectedRecipient) {
        console.error("selectedRecipient not found", this.selectedRecipientValue);
        return;
      }
      expr = DocusignExpressionBuilder.BuildSyntax(
        selectedRecipient,
        this.selectedFormatId.code
      );
    } else {
      expr = SimpleExpressionBuilder.BuildSyntax(
        this.selectedVariable.id,
        this.selectedFormatId.code
      );
    }

    return Word.run(async (context) => {
      const range = context.document.getSelection();
      range.load("font");

      await context.sync();
      const fontName = range.font.name;
      const fontSize = range.font.size;

      range.insertHtml(expr, Word.InsertLocation.replace);

      range.font.name = fontName;
      range.font.size = fontSize;

      await context.sync();
    })
      .catch((error) => {
        if (error instanceof OfficeExtension.Error) {
          this.rollbar.warn("Error during insert simple syntax", error);
        }
      })
      .then(() => {
        Utils.blurButton(this.insertBtn);
      });
  }
}
