import { Component, OnInit } from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

@Component({
  selector: "app-conditional-child-join",
  templateUrl: "./conditional-child-join.component.html",
  styleUrls: ["./conditional-child-join.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConditionalChildJoinComponent,
      multi: true,
    },
  ],
})
export class ConditionalChildJoinComponent implements ControlValueAccessor {
  uniqueId: string = this.constructor.name + new Date().getMilliseconds();
  isDisabled: boolean = false;
  onChange = (newValue) => {};
  form = this.fb.group({ selectedValue: new FormControl("and") });

  constructor(private fb: FormBuilder) {
    this.form.controls["selectedValue"].valueChanges.subscribe((newValue) => {
      this.onChange(newValue);
    });
    this.initializeOutput();
  }
  writeValue(obj: any) {}

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
    this.onChange(this.form.controls["selectedValue"].value);
  }

  registerOnTouched(fn: any) {}

  setDisabledState(isDisabled: boolean) {}

  onValueChange(newValue: string) {
    this.form.controls["selectedValue"].setValue(newValue);
  }

  initializeOutput() {
    this.form.controls["selectedValue"].setValue("and");
  }
}
