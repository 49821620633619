import { RepeatComponentForm } from "./RepeatComponentForm";
import { RepeatListType } from "./RepeatListDropdown";
import { RepeatChildForm } from "./RepeatChildForm";
import { RepeatComponent } from "./repeat.component";
import { DocumentScanner } from "../syntax/DocumentScanner";

export class RepeatVariableSyntaxBuilder {
  static BuildSyntax(
    data: RepeatComponentForm,
    openForLoops?: string[]
  ): string {
    return this.BuildExpression(data, false, openForLoops);
  }
  private static BuildExpression(
    data: RepeatComponentForm,
    nested?: boolean,
    openForLoops?: string[]
  ) {
    //First set information inside brackets {{ }}
    switch (data.listType) {
      case RepeatListType.List:
        return RepeatVariableSyntaxBuilder.BuildListExpression(
          data,
          nested,
          openForLoops
        );

      case RepeatListType.MultiLine:
        return RepeatVariableSyntaxBuilder.BuildMultiLineExpression(
          data,
          nested,
          openForLoops
        );

      case RepeatListType.Comma:
        return RepeatVariableSyntaxBuilder.BuildCommaListExpression(data);

      case RepeatListType.Custom:
        return RepeatVariableSyntaxBuilder.BuildCustomListExpression(data);

      case RepeatListType.Oxford:
        return RepeatVariableSyntaxBuilder.BuildCommaListOxfordExpression(data);

      case RepeatListType.Semicolon:
        return RepeatVariableSyntaxBuilder.BuildSemicolonListExpression(data);

      case RepeatListType.Table:
        return RepeatVariableSyntaxBuilder.BuildTableExpression(data);

      case RepeatListType.NextItem:
        return RepeatVariableSyntaxBuilder.BuildNextItemListExpression(data);

      case RepeatListType.ItemNumber:
        return RepeatVariableSyntaxBuilder.BuildItemNumberExpression(data);

      case RepeatListType.GenerateMultiple:
        return RepeatVariableSyntaxBuilder.BuildGenerateMultipleExpression(
          data
        );

      default:
        break;
    }
  }

  static BuildGenerateMultipleExpression(data: RepeatComponentForm): string {
    let expression = "";
    data.listChildren.forEach((value) => {
      expression =
        expression +
        `{{ <span style=\"background-color:yellow\">${data.listVariable}[i].${value.id}</span> }} `;
    });

    return expression;
  }

  //Insert Refer to next item List
  static BuildNextItemListExpression: (data: RepeatComponentForm) => string =
    function BuildNextItemListExpression(data: RepeatComponentForm): string {
      let childLists = this.BuildChildNextItemListExpression(data);

      let parentExpression =
        '<p>{{ <span style="background-color:yellow">nextitemlist($listVariable, "</span><span style="background-color:#C0C0C0">If notCHILDLIST1, thenCHILDLIST2</span><span style="background-color:yellow">"'
          .replace("$listVariable", data.listVariable)
          .replace("CHILDLIST1", childLists[0])
          .replace("CHILDLIST2", childLists[1]);

      let parentEndExpression = ")</span> }}</p>";

      parentExpression = parentExpression.concat(parentEndExpression);
      return parentExpression;
    };

  static BuildChildNextItemListExpression: (
    data: RepeatComponentForm
  ) => string[] = function BuildChildNextItemListExpression(
    data: RepeatComponentForm
  ): string[] {
    let child1 = " &lt;$CHILD&gt;".replace("$CHILD", data.listChildren[0].id);
    let child2 = " &lt;next.$CHILD&gt;".replace(
      "$CHILD",
      data.listChildren[0].id
    );

    let x = 1;
    while (x <= data.listChildren.length - 1) {
      let childName = data.listChildren[x].id;
      child1 = child1.concat(" &lt;$CHILD&gt;".replace("$CHILD", childName));
      child2 = child2.concat(
        " &lt;next.$CHILD&gt;".replace("$CHILD", childName)
      );

      x++;
    }

    return [child1, child2];
  };

  //Insert Custom List
  //{{ customseparatorlist(itemname, "<itemattribute>", 'separator', 'finalseparator') }}
  static BuildCustomListExpression: (data: RepeatComponentForm) => string =
    function BuildCustomListExpression(data: RepeatComponentForm): string {
      let itemname: string = data.listVariable;
      let itemattributes: string = "";
      let separator = ",";
      let finalSeparator: string = data.listSeparator;

      for (let i = 0; i < data.listChildren.length; i++) {
        let child = this.BuildChildCommaListExpression(data.listChildren[i].id);
        itemattributes = itemattributes.concat(child);
        //Add a comma between the values
        if (i + 1 != data.listChildren.length) {
          itemattributes = itemattributes.concat(",");
        }
      }

      let expression = `<p>{{ <span style="background-color:yellow">customseparatorlist(${itemname}, "</span><span style="background-color:#C0C0C0">${itemattributes}</span><span style=\"background-color:yellow\">", '${separator}', '${finalSeparator}')</span> }}</p>`;

      return expression;
    };

  //Insert Semicolon List
  static BuildSemicolonListExpression: (data: RepeatComponentForm) => string =
    function BuildSemicolonListExpression(data: RepeatComponentForm): string {
      let expr = "";

      let parentExpression =
        '<p>{{ <span style="background-color:yellow">semicolonlist($listVariable, "</span><span style="background-color:#C0C0C0">'.replace(
          "$listVariable",
          data.listVariable
        );

      let parentEndExpression = ")</span> }}</p>";
      let i = 0;

      while (i < data.listChildren.length) {
        parentExpression = parentExpression.concat(
          this.BuildChildCommaListExpression(data.listChildren[i].id)
        );
        i++;
        if (i >= data.listChildren.length) {
          parentExpression = parentExpression.concat(
            '</span><span style="background-color:yellow">"'
          );
        } else {
          parentExpression = parentExpression.concat(", ");
        }
      }

      parentExpression = parentExpression.concat(parentEndExpression);
      return parentExpression;
    };

  //Insert Comma List Oxford
  static BuildCommaListOxfordExpression: (data: RepeatComponentForm) => string =
    function BuildCommaListOxfordExpression(data: RepeatComponentForm): string {
      let expr = "";

      let parentExpression =
        '<p>{{ <span style="background-color:yellow">commalistoxford($listVariable, "</span><span style="background-color:#C0C0C0">'.replace(
          "$listVariable",
          data.listVariable
        );

      let parentEndExpression = ")</span> }}</p>";
      let i = 0;

      while (i < data.listChildren.length) {
        parentExpression = parentExpression.concat(
          this.BuildChildCommaListExpression(data.listChildren[i].id)
        );
        i++;
        if (i >= data.listChildren.length) {
          parentExpression = parentExpression.concat(
            '</span><span style="background-color:yellow">"'
          );
        } else {
          parentExpression = parentExpression.concat(", ");
        }
      }

      parentExpression = parentExpression.concat(parentEndExpression);
      return parentExpression;
    };

  //Insert Comma List
  static BuildCommaListExpression: (data: RepeatComponentForm) => string =
    function BuildCommaListExpression(data: RepeatComponentForm): string {
      let expr = "";

      let parentExpression =
        '<p>{{ <span style="background-color:yellow">commalist($listVariable, "</span><span style="background-color:#C0C0C0">'.replace(
          "$listVariable",
          data.listVariable
        );

      let parentEndExpression = ")</span> }}</p>";
      let i = 0;

      while (i < data.listChildren.length) {
        parentExpression = parentExpression.concat(
          this.BuildChildCommaListExpression(data.listChildren[i].id)
        );
        i++;
        if (i >= data.listChildren.length) {
          parentExpression = parentExpression.concat(
            '</span><span style="background-color:yellow">"'
          );
        } else {
          parentExpression = parentExpression.concat(", ");
        }
      }

      parentExpression = parentExpression.concat(parentEndExpression);
      return parentExpression;
    };

  static BuildTableExpression(data: RepeatComponentForm): string {
    let end_expr =
      '{%tr <span style="background-color:yellow">endfor</span> %}';

    let value_cells = new Array();
    let html_expr =
      '<table style="border: 1px solid black;"><tr style="border: 1px solid black;">';
    let variables = data.listChildren;
    let expression =
      '{%tr <span style="background-color:yellow">for item in ' +
      data.listVariable +
      "</span> %}";

    for (var n in variables) {
      var h_cell =
        '<th style="border: 1px solid black;">' + variables[n].text + "</th>";
      var v_cell =
        '<td style="border: 1px solid black;">' +
        '{{ <span style="background-color:yellow">item.' +
        variables[n].id +
        "</span> }}</td>";

      html_expr = html_expr + h_cell;
      value_cells.push(v_cell);
    }

    html_expr =
      html_expr +
      '</tr><tr style="border: 1px solid black;"><td  colspan="' +
      variables.length +
      '">' +
      expression +
      '</td></tr style="border: 1px solid black;"><tr colspan="' +
      variables.length +
      '">';

    for (var v in value_cells) {
      html_expr = html_expr + value_cells[v];
    }

    html_expr =
      html_expr +
      '</tr><tr style="border: 1px solid black;"><td  colspan="' +
      variables.length +
      '">' +
      end_expr +
      "</tr></table>";

    return html_expr;
  }

  //Insert MultiLine List
  static BuildMultiLineExpression(
    data: RepeatComponentForm,
    nested?: boolean,
    openForLoops?: string[]
  ): string {
    let expr = "";
    let itemType = nested ? "nesteditem" : "item";
    let parentExpression = "";
    let parentEndExpression = "";
    let listVariableName = data.listVariable;
    let insideParentLoop: boolean = false;

    openForLoops?.forEach((v) => {
      if (v == data.listVariable) insideParentLoop = true;
    });
    if (!insideParentLoop) {
      if (nested) {
        listVariableName = `item.${data.listVariable}`;
      }
      parentExpression = `<p>{%p <span style="background-color:yellow">for ${itemType} in ${listVariableName}</span> %}</p>`;
      parentEndExpression =
        '<p>{%p <span style="background-color:yellow">endfor</span> %}</p>';
    }

    data.listChildren.forEach((child) => {
      let childexpr = itemType + "." + child.id;
      parentExpression = parentExpression.concat(
        this.BuildMultiLineChildExpression(childexpr)
      );
    });

    data.nestedChildren.forEach((n) => {
      let nestedexpr = this.BuildExpression(n, true);
      parentExpression = parentExpression.concat(nestedexpr);
    });

    parentExpression = parentExpression.concat(parentEndExpression);
    return parentExpression;
  }

  //Insert List
  static BuildListExpression(
    data: RepeatComponentForm,
    nested?: boolean,
    openForLoops?: string[]
  ): string {
    let expr = "";
    let itemType = nested ? "nesteditem" : "item";
    let parentExpression = "";
    let parentEndExpression = "";
    let listVariableName = data.listVariable;
    let insideParentLoop: boolean = false;

    openForLoops?.forEach((v) => {
      if (v == data.listVariable) insideParentLoop = true;
    });
    if (!insideParentLoop) {
      if (nested) {
        listVariableName = `item.${data.listVariable}`;
      }
      parentExpression = `{% <span style="background-color:yellow">for ${itemType} in ${listVariableName}</span> %}`;
      parentEndExpression =
        '{% <span style="background-color:yellow">endfor</span> %}';
    }
    data.listChildren.forEach((child) => {
      let childexpr = itemType + "." + child.id;
      parentExpression = parentExpression.concat(
        this.BuildChildListExpression(childexpr)
      );
    });

    data.nestedChildren.forEach((n) => {
      let nestedexpr = this.BuildExpression(n, true);
      parentExpression = parentExpression.concat(nestedexpr);
    });

    parentExpression = parentExpression.concat(parentEndExpression);

    return parentExpression;
  }

  static BuildChildCommaListExpression(child: string): string {
    return `&lt;${child}&gt;`;
  }

  static BuildChildListExpression(child: string): string {
    return `{{ <span style="background-color:yellow">${child}</span> }}`;
  }

  static BuildMultiLineChildExpression(child: string): string {
    return `{{ <span style="background-color:yellow">${child}</span> }}<br/>`;
  }

  private static BuildItemNumberExpression(data: RepeatComponentForm) {
    let expression = "";
    data.listChildren.forEach((value) => {
      let memberName = value.id;
      expression =
        expression +
        "{{ $PARENT[$INDEX].$CHILD }} "
          .replace("$PARENT", data.listVariable)
          .replace("$INDEX", (data.itemNumber - 1).toString())
          .replace("$CHILD", memberName);
    });

    return expression;
  }
}
