<form [formGroup]="form">
  <div class="form-group">
    <div class="d-table w-100">
      <div class="d-table-cell" style="width: 90px">
        <label class="pr-2">Format as</label>
      </div>
      <div class="d-table-cell">
        <p-dropdown
          formControlName="formatOption"
          [appendTo]="'body'"
          [options]="formatOptions"
          optionLabel="text"
          [showClear]="false"
        ></p-dropdown>
      </div>
    </div>
  </div>
  <ng-container *ngIf="displayDecimalOption()">
    <div class="form-group">
      <div class="row no-gutters">
        <div class="col-10">
          <input
            id="client-name-append"
            type="text"
            class="form-control form-control-sm disabled rounded-left"
            style="border-radius: 0"
            value="No. of decimal places:"
            disabled
          />
        </div>
        <div class="col-2">
          <input
            formControlName="decimalPlaces"
            id="id-simple-variable-decimal-places"
            type="number"
            class="form-control form-control-sm rounded-right"
            style="border-radius: 0"
          />
        </div>
      </div>
    </div>
  </ng-container>
  <label style="color: gray"
    >Calculate below
    <mat-icon
      [matTooltip]="CALCULATE_TOOLTIP"
      class="material-icons md-18"
      style="cursor: pointer"
    >
      help_outline
    </mat-icon></label
  >

  <hr />
  <div
    formArrayName="numberChildren"
    *ngFor="
      let child of numberChildren.controls;
      let i = index;
      first as isFirst
    "
  >
    <number-child
      [formControl]="child"
      [header]="isFirst"
      [displayFirstChildOnly]="displayFirstChildOnly"
      [workflowVariables]="workflowVariables"
      [workflowNumberVariables]="workflowNumberVariables"
    ></number-child>
  </div>

  <div class="form-row form-group" [hidden]="hideAddRemove()">
    <button
      (click)="addNumberChild()"
      type="button"
      class="form-inline form-inline-check btn btn-link"
      style="font-size: 13px"
    >
      <i class="fas fa-plus text-dark"></i> Add Number
    </button>
    <button
      (click)="removeNumberChild()"
      [hidden]="numberChildren.length < 2"
      type="button"
      class="form-inline form-inline-check btn btn-link"
      style="font-size: 13px"
    >
      <i class="fas fa-times text-dark"></i>Remove
    </button>
  </div>
  <div class="form-group">
    <button
      #insertBtn
      (click)="insertCalculation()"
      class="btn btn-custom py-1"
      [disabled]="form.invalid"
    >
      Insert Calculation
    </button>
  </div>
</form>
