import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { SimpleVariable } from "./SimpleVariable";
import { VariableDataType } from "./VariableType";
import { SymbolTableEntryScope } from "../../syntax/AST/SymbolTableHelper";

export class DateVariable extends SimpleVariable {
  public simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "default",
      text: "Standard (Month Day, Year)",
    },
    {
      code: "mmddyy",
      text: "mm/dd/yy",
    },
    {
      code: "mmddyyyy",
      text: "mm/dd/yyyy",
    },
    {
      code: "ddmmyyyy",
      text: "dd/mm/yyyy",
    },
    {
      code: "monthyear",
      text: "Month__, Year",
    },
    {
      code: "mmddyyyy_noslash",
      text: "MMddyyyy",
    },
    {
      code: "doddom",
      text: "The DD day of Month, Year",
    },
    {
      code: "full",
      text: "Day, Month DD, Year",
    },
    {
      code: "daytext",
      text: "Day (e.g., Third)",
    },
    {
      code: "dayordinal",
      text: "Day (e.g., 3rd)",
    },
    {
      code: "day1digit",
      text: "Day (e.g., 3)",
    },
    {
      code: "day2digit",
      text: "Day (e.g., 03)",
    },

    {
      code: "monthtext",
      text: "Month (e.g., March)",
    },
    {
      code: "month1digit",
      text: "Month (e.g., 3)",
    },
    {
      code: "month2digit",
      text: "Month (e.g., 03)",
    },
    {
      code: "weekday",
      text: "Weekday (e.g., Thursday)",
    },
    {
      code: "year4digit",
      text: "Year (e.g., 2023)",
    },
    {
      code: "yeartext",
      text: "Year (e.g., two thousand twenty-three)",
    },
  ];
  label: string;

  constructor(
    handle: string,
    display: string,
    label?: string,
    parent?: string
  ) {
    super(handle, display, VariableDataType.Date, null, parent);

    this.label = label;
    this.symbolTableScopes = [SymbolTableEntryScope.Date];
  }
}
