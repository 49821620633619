import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import {} from "office-js";

/* global console, document, Office */

if (environment.production) {
  enableProdMode();
}

Office.initialize = function () {
  console.info("Office is initialized!");

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((e) => {
      throw new Error("Could not bootstrap module: " + e);
    });

  console.info("Appmodule is bootstrapped");

  if (!environment.production) {
    OfficeExtension.config.extendedErrorLogging = true;
  }
};
