import { DocusignRecipient, DocusignTextOrVariable } from "./signature.component";

export class DocusignExpressionBuilder {
  static getTextOrVariable = (textOrVariable: DocusignTextOrVariable, inAnchor: boolean) => {
    let value = textOrVariable.value;
    if (textOrVariable.is_repeating_item) {
      const repeatingItem = textOrVariable.value.split("[i].");
      value = `item.${repeatingItem[1]}`;
    }
    // The output is already inside a jinja template
    if (inAnchor) {
      return textOrVariable.is_variable ? value : `'${value}'`;
    }
    return textOrVariable.is_variable ? `{{ ${value} }}` : value;
  }

  static getAnchor = (recipient: DocusignRecipient, formatOption: string) =>
    `<span>&nbsp;<span style="color:white">{</span>{ <span style="background-color:yellow">
            generate_docusign_anchor('${formatOption}', ${DocusignExpressionBuilder.getTextOrVariable(recipient.email, true)})
        </span>
     }<span style="color:white">}</span>&nbsp;</span>`

  static BuildSyntax(recipient: DocusignRecipient, formatOption: string) {
    const repeatingItemAttribute = recipient.name.is_repeating_item ? recipient.name
      : recipient.email.is_repeating_item ? recipient.email : null;
    if (!repeatingItemAttribute) {
      return DocusignExpressionBuilder.getAnchor(recipient, formatOption);
    }
    const repeatingItem = repeatingItemAttribute.value.split("[i].");
    const repeatingItemDataName = repeatingItem[0];

    return `
        <table style="border: 1px solid black;">
            <tr style="border: 1px solid black;">
                <td colspan="2">{%tr <span style="background-color:yellow">for item in ${repeatingItemDataName}</span> %}</td>
            </tr>
            <tr>
              <td style="border: 1px solid black;">
                ${DocusignExpressionBuilder.getTextOrVariable(recipient.name, false)}
              </td>
              <td style="border: 1px solid black;">
                ${DocusignExpressionBuilder.getAnchor(recipient, formatOption)}
              </td>
            </tr>
            <tr style="border: 1px solid black;">
                <td colspan="2">{%tr <span style="background-color:yellow">endfor</span> %}</td>
            </tr>
        </table>
    `;
  }

}
