import {
  DateOutputData,
  DateOperation,
  DateDisplayValue,
} from "./DateOutputData";

export class DateSyntaxBuilder {
  static BuildSyntax: (data: DateOutputData) => string = function BuildSyntax(
    data: DateOutputData
  ): string {
    let expr = "";

    let openingBracket: string = "<p>{{ ";
    let closingBracket: string = " }}</p>";

    switch (data.dateOperation) {
      case DateOperation.Length:
        expr = DateSyntaxBuilder.BuildLengthExpression(data);
        break;

      case DateOperation.Difference:
        expr = DateSyntaxBuilder.BuildDifferenceExpression(data);
        break;
    }

    //Then set highlighting and formatting options and finally wrap in {{ }}
    let text_expression =
      '<span style="background-color:yellow">$expression</span>'.replace(
        "$expression",
        expr
      );

    expr = openingBracket + text_expression + closingBracket;

    return expr;
  };

  static BuildLengthExpression(data: DateOutputData): string {
    let expr = "";
    let displayType = DateSyntaxBuilder.getDisplayTypeAsString(
      data.displayValue
    );

    expr = "(date_difference(starting=$DATE1, ending=$DATE2).$DISPLAYTYPE|int)"
      .replace("$DATE1", data.dateValue1)
      .replace("$DATE2", data.dateValue2)
      .replace("$DISPLAYTYPE", displayType);

    return expr;
  }

  static BuildDifferenceExpression: (data: DateOutputData) => string =
    function BuildDifferenceExpression(data: DateOutputData): string {
      let expr = "";
      let displayType = DateSyntaxBuilder.getDisplayTypeAsString(
        data.displayValue
      );

      expr = "format_date( ($DATE1) $OP date_interval($DISPLAYTYPE=$COUNT) )"
        .replace("$DATE1", data.dateValue1)
        .replace("$DISPLAYTYPE", displayType)
        .replace("$COUNT", data.dateDiffQuantity.toString())
        .replace("$OP", data.mathOperation);

      return expr;
    };

  private static getDisplayTypeAsString(dType: DateDisplayValue): string {
    let displayType = "";

    switch (dType) {
      case DateDisplayValue.Days:
        displayType = "days";
        break;

      case DateDisplayValue.Weeks:
        displayType = "weeks";
        break;

      // case DateDisplayValue.Months:
      //   displayType = "months";
      //   break;

      case DateDisplayValue.Years:
        displayType = "years";
        break;
    }
    return displayType;
  }
}
