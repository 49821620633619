import { Constants } from "../../shared/Constants";
import { RegexConstants } from "../../shared/RegexConstants";
import { SyntaxContainer } from "../../shared/Types";

export class ParenthesisCheck {
  static Check(ranges: SyntaxContainer[]): Map<SyntaxContainer, string[]> {
    let bad: Map<SyntaxContainer, string[]> = new Map<
      Word.Paragraph,
      string[]
    >();
    ranges.forEach((range) => {
      let stack: number[] = [];
      //Check that each line has a balanced set of parenthesis
      if (RegexConstants.regexParenthesisTest.test(range.text)) {
        for (let i = 0; i < range.text.length; i++) {
          if (range.text[i] == "(") {
            //push onto stack
            stack.push(1);
          } else if (range.text[i] == ")") {
            //attempt to pop from stack
            //add error if stack empty
            if (stack.length < 1) {
              bad.set(range, [Constants.PARENTHESIS_SYNTAX_ERROR]);
            } else {
              stack.pop();
            }
          }
        }
        //If there is are any parenthesis left on the stack
        //The closing parenthesis is missing from the syntax
        if (stack.length > 0) {
          bad.set(range, [Constants.PARENTHESIS_SYNTAX_ERROR]);
        }
      }
    });

    return bad;
  }
}
