import * as lexing from "lexing";
import { LexerRules } from "./LexerRules";
import { TokenType } from "./TokenType";

export class Lexer {
  private _rules = LexerRules.rules;
  private _input: lexing.StringIterator;
  private _tokenScanner: lexing.Token<string>[];
  private position: number = 0;

  constructor(input: string) {
    this._input = new lexing.StringIterator(input);
    this._tokenScanner = [];
    let tokenizer: lexing.TokenIterable<string> = new lexing.Tokenizer(
      this._rules
    ).map(this._input);

    let token: lexing.Token<string>;

    do {
      //Copy tokens into array
      token = tokenizer.next();
      if (Array.isArray(token)) {
        token.forEach((t) => {
          this._tokenScanner.push(t);
        });
      } else this._tokenScanner.push(token);
    } while (token.name != TokenType.EOF);
  }

  ReadNext(): lexing.Token<string> {
    if (this.position + 1 != this._tokenScanner.length)
      return this._tokenScanner[this.position++];
    else {
      return lexing.Token<string>("EOF", null);
    }
  }

  Peek(): lexing.Token<string> {
    return this._tokenScanner[this.position];
  }

  get Position() {
    return this.position;
  }
}
