<form [formGroup]="form">
  <div class="form-group">
    <div class="d-table w-100">
      <div class="d-table-cell" style="width: 90px">
        <label class="pr-2">Format as</label>
      </div>
      <div class="d-table-cell">
        <p-dropdown
          formControlName="selectListType"
          [appendTo]="'body'"
          optionLabel="text"
          [options]="repeatListDropdown"
          [showClear]="false"
        ></p-dropdown>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Insert a repeating field</label>
    <app-variable-dropdown
      formControlName="L0Selected"
      [options]="L0SelectItems"
      [clearable]="false"
      [appendTo]="'body'"
    >
    </app-variable-dropdown>
  </div>

  <div class="form-group">
    <label>Attributes to include</label>
    <app-variable-dropdown
      formControlName="L1Selected"
      [options]="L1SelectItems"
      [clearable]="false"
      (add)="onL1Add($any($event))"
      (remove)="onL1Remove($any($event))"
      [multiple]="true"
      [appendTo]="'body'"
      [pTooltip]="form.controls['L1Selected'].value?.text"
    ></app-variable-dropdown>
  </div>

  <ng-container
    formArrayName="RepeatChildForms"
    *ngFor="let x of RepeatChildForms.controls; index as i; first as isFirst"
  >
    <app-repeat-child
      [Description]="L1ChildObjectsSelected[i].Description"
      [formControlName]="i"
      [L2Children]="L1ChildObjectsSelected[i].Items"
      [Parent]="L1ChildObjectsSelected[i].Parent"
      [listType]="this.formData.listType"
    ></app-repeat-child>
  </ng-container>
  <ng-container *ngIf="displayMode1()">
    <div class="form-group">
      <div class="row no-gutters">
        <div class="col-8">
          <input
            type="text"
            class="form-control form-control-sm disabled rounded-left"
            style="border-radius: 0"
            value="Select Item Number:"
            disabled
          />
        </div>
        <div class="col-4">
          <input
            formControlName="itemNumber"
            type="number"
            [min]="1"
            class="form-control form-control-sm rounded-right"
            style="border-radius: 0"
            (change)="minItemNumberCheck()"
          />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      this.form.controls['selectListType'].value.code == repeatListTypes.Custom
    "
  >
    <div>
      <label for="id-custom-separator">Separated by a</label>
      <input
        formControlName="listSeparator"
        id="id-custom-separator"
        class="form-control"
        type="text"
      />
    </div>
  </ng-container>

  <div class="form-group">
    <span
      matTooltip="You must select at least two members when inserting a 'refer to next item' list"
      [matTooltipDisabled]="!disableInsertBtn"
    >
      <button
        #insertBtn
        [disabled]="disableInsertBtn || form.invalid"
        (click)="insertField()"
        type="button"
        class="btn btn-custom py-1"
      >
        Insert Field
      </button></span
    >
  </div>
</form>
