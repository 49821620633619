import { SimpleVariable } from "../shared/variableTypes/SimpleVariable";
import { RepeatListType } from "./RepeatListDropdown";

export class RepeatComponentForm {
  public listVariable: string;
  public listChildren: SimpleVariable[] = [];
  public listType: RepeatListType = RepeatListType.List;
  public listSeparator: string = ",";
  public itemNumber: number = 1;
  public nestedChildren: RepeatComponentForm[] =
    new Array<RepeatComponentForm>();
}
