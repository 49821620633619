import { FunctionSignature } from "../../shared/Types";

export enum SymbolTableEntryType {
  Variable = "Variable",
  Function = "Function",
  Filter = "Filter",
}

export enum SymbolTableEntryScope {
  Simple = "Simple",
  Date = "Date",
  Number = "Number",
  Text = "Text",
  Object = "Object",
  Multi = "Multi",
}
export abstract class SymbolTableEntry {
  _identifierName: string;
  _entryType: SymbolTableEntryType;
  _scopes: SymbolTableEntryScope[];

  protected constructor(
    name: string,
    type: SymbolTableEntryType,
    scopes?: SymbolTableEntryScope[]
  ) {
    this._identifierName = name;
    this._entryType = type;
    this._scopes = scopes;
  }
}

export class VariableSymbolTableEntry extends SymbolTableEntry {
  constructor(name: string, scopes?: SymbolTableEntryScope[]) {
    super(name, SymbolTableEntryType.Variable, scopes);
  }
}

export class FunctionSymbolTableEntry extends SymbolTableEntry {
  _signature: FunctionSignature; //Reserved for future use
  constructor(
    name: string,
    scope?: SymbolTableEntryScope,
    signature?: FunctionSignature
  ) {
    super(name, SymbolTableEntryType.Function, [scope]);
    this._signature = signature;
  }
}

export class FilterSymbolTableEntry extends SymbolTableEntry {
  constructor(name: string, scope?: SymbolTableEntryScope) {
    super(name, SymbolTableEntryType.Filter, [scope]);
  }
}
