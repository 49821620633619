<div class="container-fluid py-2 bg-dark text-light">
  <div class="form-group">
    <label><h3>SELECT WORKFLOW</h3></label>
    <button
      #refreshBtn
      type="button"
      class="refresh-btn btn btn-custom btn-default form-control"
      (click)="refreshWorkflowList()"
    >
      <mat-icon class="material-icons refresh-img">refresh</mat-icon>
    </button>
    <p-dropdown
      [virtualScroll]="true"
      [options]="interviews"
      optionLabel="text"
      [appendTo]="'body'"
      [showClear]="false"
      [formControl]="selectedWorkflow"
      filter="true"
    >
    </p-dropdown>
  </div>
</div>
