export enum TokenType {
  EOF = "EOF",
  NUMBER = "NUMBER",
  VARIABLE = "VARIABLE",
  STMT_OPEN = "STMT_OPEN",
  EXPR_OPEN = "EXPR_OPEN",
  EXPR_CLOSE = "EXPR_CLOSE",
  STMT_CLOSE = "STMT_CLOSE",
  PAREN_OPEN = "PAREN_OPEN",
  PAREN_CLOSE = "PAREN_CLOSE",
  LOGIC_OP = "LOGIC_OP",
  FILTER_OP = "FILTER_OP",
  COND_IF = "CTRL_IF",
  END_COND = "END_COND",
  CTRL_FOR = "CTRL_FOR",
  END_CTRL = "END_CTRL",
  CTRL_IN = "CTRL_IN",
  MATH_OP = "MATH_OP",
  COMP_OP = "COMP_OP",
  STRING = "STRING",
  PUNCTUATION = "PUNCTUATION",
  ARG_SEPARATOR = "ARG_SEPARATOR",
  NOT = "NOT",
  IDENTIFIER = "IDENTIFIER",
  DATE_ARG = "DATE_ARG",
  BOOLEAN = "BOOLEAN",
  BEGIN_SET = "BEGIN_SET",
  END_SET = "END_SET",
  COND_OPEN = "COND_OPEN",
  COND_CLOSE = "COND_END",
  DOT_OP = "DOT_OP",
  ELSE_COND = "ELSE_COND",
  PROPERTY = "PROPERTY",
}
