import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { VariableDataType } from "./VariableType";
import { ComplexVariable } from "./ComplexVariable";
import { DropdownOption } from "../Types";

export class SimpleConditionalVariable extends ComplexVariable {
  public simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "blank",
      text: "Blank",
    },
  ];
  public complexMembers: DropdownOption[] = [{ code: "Blank", text: "Blank" }];
  label: string;

  constructor(handle: string, display: string, label: string) {
    super(handle, display, VariableDataType.SimpleConditional);

    this.label = label;
  }
}
