import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { SimpleVariable } from "../shared/variableTypes/SimpleVariable";
import { WorkflowService } from "../shared/workflow.service";
import { DateOperation, DateOutputData } from "./DateOutputData";
import { DateSyntaxBuilder } from "./DateSyntaxBuilder";
import { Utils } from "../shared/Utils";
import { RollbarService } from "../shared/rollbarerrorhandler.service";
import Rollbar from "rollbar";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from "@angular/forms";
import { DropdownOption } from "../shared/Types";

@Component({
  selector: "app-calc-date",
  templateUrl: "./date.component.html",
  styleUrls: ["./date.component.css"],
})
export class DateComponent {
  dateVariables: SimpleVariable[];
  dateOperations: DropdownOption[] = [
    { code: 0, text: "Length of time between two dates" },
    { code: 1, text: "Add or subtract time from date" },
  ];
  dateDisplayTypes: DropdownOption[] = [
    { code: 0, text: "Days to/from", altText: "Days" },
    { code: 1, text: "Weeks to/from", altText: "Weeks" },
    { code: 2, text: "Years to/from", altText: "Years" },
  ];

  form: FormGroup;
  outputData: DateOutputData = new DateOutputData();

  @ViewChild("insertBtn") insertBtn: ElementRef<HTMLButtonElement>;

  constructor(
    private fb: FormBuilder,
    private interview: WorkflowService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.buildForm();
    this.subscribeToFormValueChanges();
    this.subscribeToWorkflowVariables();
  }

  buildForm() {
    this.form = this.fb.group(
      {
        dateDisplayType: this.fb.control(this.dateDisplayTypes[0]),
        dateCompare1: this.fb.control(null),
        dateCompare2: this.fb.control(null),
        diffAddDate: this.fb.control(null),
        datediff_defaultChoice: this.fb.control("+"),
        dateOperation: this.fb.control(this.dateOperations[0]),
        dateAddQuantity: this.fb.control(1),
      },
      { validators: this.validateForm }
    );
  }

  subscribeToFormValueChanges() {
    this.form.controls["dateAddQuantity"].valueChanges.subscribe((newValue) => {
      //Null, negative, and zero check
      if (!newValue || newValue < 1) {
        this.form.controls["dateAddQuantity"].setValue(1);
      }
    });
  }

  subscribeToWorkflowVariables() {
    //Filter out variables that are not of the complex type
    this.interview.workflowVariablesDate.subscribe((x) => {
      this.dateVariables = <SimpleVariable[]>x;
    });
  }

  updateOutputData() {
    switch (this.form.controls["dateOperation"].value.code) {
      case DateOperation.Length:
        this.outputData.dateValue1 =
          this.form.controls["dateCompare1"].value.id;
        this.outputData.dateValue2 =
          this.form.controls["dateCompare2"].value.id;
        this.outputData.displayValue =
          this.form.controls["dateDisplayType"].value.code;
        this.outputData.dateOperation = DateOperation.Length;
        break;

      case DateOperation.Difference:
        this.outputData.dateValue1 =
          this.form.controls["diffAddDate"].value?.id;
        this.outputData.dateDiffQuantity =
          this.form.controls["dateAddQuantity"].value;
        this.outputData.mathOperation =
          this.form.controls["datediff_defaultChoice"].value;
        this.outputData.displayValue =
          this.form.controls["dateDisplayType"].value?.code;
        this.outputData.dateOperation = DateOperation.Difference;
    }
  }

  insertDateCalculation() {
    this.updateOutputData();
    let expr = DateSyntaxBuilder.BuildSyntax(this.outputData);

    return Word.run(async (context) => {
      // insert a paragraph at the end of the document.
      let range = context.document.getSelection();
      range.load("font");

      await context.sync();
      let fontName = range.font.name;
      let fontSize = range.font.size;

      range.insertHtml(expr, Word.InsertLocation.replace);

      range.font.name = fontName;
      range.font.size = fontSize;

      await context.sync();
    })
      .catch((error) => {
        if (error instanceof OfficeExtension.Error) {
          this.rollbar.warn("Error during insert Date syntax", error);
        }
      })
      .finally(() => {
        Utils.blurButton(this.insertBtn);
      });
  }

  validateForm(control: AbstractControl): ValidationErrors | null {
    let formGroup: FormGroup = <FormGroup>control;

    if (
      formGroup.controls["dateOperation"].value.code == 0 &&
      (formGroup.controls["dateCompare1"].value == undefined ||
        formGroup.controls["dateCompare2"].value == undefined)
    ) {
      return { FormContainsNull: true };
    } else if (
      formGroup.controls["dateOperation"].value.code == 1 &&
      formGroup.controls["diffAddDate"].value == undefined
    ) {
      return { FormContainsNull: true };
    }
    return null;
  }
}
