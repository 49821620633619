import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { SplashComponent } from "./splash/splash.component";
import { LoginComponent } from "./login/login.component";

import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { WorkflowSelectComponent } from "./interview/workflow-select.component";
import { EditorComponent } from "./editor/editor.component";
import { WorkflowService } from "./shared/workflow.service";
import { SettingsService } from "./shared/settings.service";
import {
  RollbarErrorHandler,
  rollbarFactory,
  RollbarService,
} from "./shared/rollbarerrorhandler.service";
import { SimpleComponent } from "./simple/simple.component";
import { ConditionalParentComponent } from "./conditional/conditional-parent/conditional-parent.component";
import { DateComponent } from "./date/date.component";
import { NumberComponent } from "./number/number.component";
import { RepeatComponent } from "./repeat/repeat.component";
import { SyntaxComponent } from "./syntax/syntax.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ConditionalChildComponent } from "./conditional/conditional-child/conditional-child.component";
import { NumberChildComponent } from "./number/number-child/number-child.component";
import { ProgressBarComponent } from "./progress-bar/progress-bar.component";
import { NumberPickerModule } from "ng-number-picker";
import { UploadFileComponent } from "./uploaddoc/UploadFile.component";
import { ConditionalChildJoinComponent } from "./conditional/conditional-child/conditional-child-join/conditional-child-join.component";
import { ConditionalTrueFalseComponent } from "./conditional/conditional-child/conditional-true-false/conditional-true-false.component";
import { ConditionalChildComparisonComponent } from "./conditional/conditional-child/conditional-child-comparison/conditional-child-comparison.component";
import { AuthorizationService } from "./shared/authorization.service";
import { ApiService } from "./shared/api.service";
import { AutoCompleteModule } from "primeng/autocomplete";
import { RepeatChildComponent } from "./repeat/repeat-child/repeat-child.component";
import { ConditionalRepeatComponent } from "./conditional/conditional-child/conditional-repeat/conditional-repeat.component";
import { ConditionalDateComponent } from "./conditional/conditional-child/conditional-date/conditional-date.component";
import { ConditionalNumberComponent } from "./conditional/conditional-child/conditional-number/conditional-number.component";
import { MatRadioModule } from "@angular/material/radio";
import { ConditionalMultiChoiceComponent } from "./conditional/conditional-child/conditional-multi-choice/conditional-multi-choice.component";
import { NotificationComponent } from "./notification/notification.component";
import { FooterComponent } from "./footer/footer.component";
import { NotificationService } from "./shared/notification.service";
import { MultiSelectModule } from "primeng/multiselect";
import { DropdownModule } from "primeng/dropdown";
import { CheckboxModule } from "primeng/checkbox";
import { TooltipModule } from "primeng/tooltip";
import { VariableDropdownComponent } from "./shared/variable-dropdown/variable-dropdown.component";
import { SignatureComponent } from "./signature/signature.component";

@NgModule({
  declarations: [
    AppComponent,
    SplashComponent,
    LoginComponent,
    WorkflowSelectComponent,
    EditorComponent,
    SimpleComponent,
    ConditionalParentComponent,
    DateComponent,
    NumberComponent,
    RepeatComponent,
    SyntaxComponent,
    ConditionalChildComponent,
    NumberChildComponent,
    ProgressBarComponent,
    UploadFileComponent,
    ConditionalChildJoinComponent,
    ConditionalTrueFalseComponent,
    ConditionalChildComparisonComponent,
    ConditionalRepeatComponent,
    ConditionalDateComponent,
    ConditionalNumberComponent,
    ConditionalMultiChoiceComponent,
    RepeatChildComponent,
    NotificationComponent,
    FooterComponent,
    VariableDropdownComponent,
    SignatureComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatIconModule,
    MatFormFieldModule,
    MatProgressBarModule,
    NumberPickerModule,
    AutoCompleteModule,
    MatRadioModule,
    TooltipModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
  ],
  exports: [],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    WorkflowService,
    SettingsService,
    AuthorizationService,
    ApiService,
    NotificationService,

    /*{ provide: Console, useClass: Rollbar },*/
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
