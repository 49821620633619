import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { VariableDataType } from "./VariableType";
import { ComplexVariable } from "./ComplexVariable";
import { DropdownOption } from "../Types";

export class DropdownVariable extends ComplexVariable {
  public complexMembers: DropdownOption[] = [];

  public simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "default",
      text: "Standard",
    },
    {
      code: "upper",
      text: "Uppercase",
    },
    {
      code: "first",
      text: "First Letter Capital",
    },
  ];

  label: string;
  constructor(
    handle: string,
    display: string,
    label: string,
    complexMembers: string[],
    parent?: string
  ) {
    super(handle, display, VariableDataType.Dropdown, parent);

    this.label = label;
    complexMembers.forEach((m) => {
      this.addComplexMember(m);
    });
    this.addComplexMember("Blank");
  }
}
