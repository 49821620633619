import { FormControl, FormGroup } from "@angular/forms";
import { LoginSettingsModel } from "./LoginSettingsModel";

export class LoginViewModel {
  public readonly domainChoices: {label: string, value: string}[] = [
    {label: "gavel.io", value: "gavel.io"},
    {label: "documate.org", value: "documate.org"},
    {label: "helpselflegal.com", value: "helpselflegal.com"},
    {label: "custom", value: "custom"},
  ];

  private readonly _formGroup: FormGroup = new FormGroup({
    /**
     * Holds the currently selected value from the
     * serverOptions array. It is first read from saved login settings if any exists,
     * and is written back to them on a successful login.
     */
    selectedDomain: new FormControl(this.domainChoices[0].value),

    /**
     * Stores the current value for the
     * subdomain the user will be logging into.
     */
    subdomain: new FormControl(""),
    lblSelectedDomain: new FormControl(`.${this.domainChoices[0].value}`),
    customDomain: new FormControl(),
    APIKey: new FormControl(),
  });

  public FormGroup() {
    return this._formGroup;
  }

  public get LoginPath(): string {
    let loginSettings = this.Settings;
    let path =
      loginSettings?.selectedDomain == "custom"
        ? loginSettings?.customDomain
        : `${loginSettings?.subdomain}\.${loginSettings?.selectedDomain}`;
    if (path.startsWith("http://")) return path;
    return `https://${path}`;
  }

  constructor() {
    this._formGroup.controls["lblSelectedDomain"].disable();

    this._formGroup.controls["selectedDomain"].valueChanges.subscribe(
      (newValue) => {
        if (newValue) this.onSelectedDomainChange(newValue);
        else this.onSelectedDomainChange(newValue);
      }
    );
  }

  public onSelectedDomainChange(newDomain: string) {
    if (newDomain)
      if (newDomain != "custom")
        this._formGroup.controls["lblSelectedDomain"].setValue(`.${newDomain}`);
  }

  public get Settings(): LoginSettingsModel {
    return new LoginSettingsModel(
      this._formGroup.controls["selectedDomain"].value,
      this._formGroup.controls["subdomain"].value,
      this._formGroup.controls["customDomain"].value,
      this._formGroup.controls["APIKey"].value
    );
  }

  public set Settings(loginSettings: LoginSettingsModel) {
    this._formGroup.controls["selectedDomain"].setValue(
      loginSettings.selectedDomain
    );

    if (loginSettings.selectedDomain == "custom") {
      this._formGroup.controls["customDomain"].setValue(
        loginSettings.customDomain
      );
    } else {
      if (loginSettings.selectedDomain.toLowerCase() == "documate.org") {
        //Rebrand switch
        loginSettings.selectedDomain = "gavel.io";
        this._formGroup.controls["selectedDomain"].setValue("gavel.io");
      }
      this._formGroup.controls["subdomain"].setValue(loginSettings.subdomain);
    }

    this._formGroup.controls["APIKey"].setValue(loginSettings.APIKey);
  }
}
