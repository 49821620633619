<form [formGroup]="form">
  <div class="form-group">
    <app-conditional-comparison
      formControlName="OptionGtLtEq"
      [displayValueOption]="false"
    >
    </app-conditional-comparison>
    <input
      formControlName="inputComparisonNumber"
      class="form-control form-control-sm hs-spin-edit"
      type="number"
      min="0"
      step="1"
      value="1"
    />
    <br />
  </div>
</form>
