import {
  ConditionalChild,
  ConditionalComparisonOption,
} from "../models/conditional-child.model";
import { VariableDataType } from "../../shared/variableTypes/VariableType";
import { ConditionalNumberExpressionBuilder } from "./ConditionalNumberExpressionBuilder";
import { ConditionalMultiSelectExpressionBuilder } from "./ConditionalMultiSelectExpressionBuilder";
import { ConditionalSingleSelectExpressionBuilder } from "./ConditionalSingleSelectExpressionBuilder";
import { ConditionalSimpleExpressionBuilder } from "./ConditionalSimpleExpressionBuilder";
import { ConditionalBooleanExpressionBuilder } from "./ConditionalBooleanExpressionBuilder";
import { RegexConstants } from "../../shared/RegexConstants";

export class ConditionalObjectExpressionBuilder {
  public static BuildObjectValueExpression(data: ConditionalChild): string {
    let expression: string;

    let clone = require("lodash.clone");

    let child = new ConditionalChild();

    child.selectedWorkflowVariable = clone(data.selectedMultipleChoice);

    child.selectedWorkflowVariable.id =
      "item." + child.selectedWorkflowVariable.id;

    child.optionGtLtEqValue = ConditionalComparisonOption.is;
    child.inputGtLtEq = data.inputChildMemberComparison;

    if (RegexConstants.REGEX_MULTICHOICE_OR_CLIO.test(child.inputGtLtEq)) {
      child.selectedMultipleChoice = data.inputChildMemberComparison;
      return ConditionalMultiSelectExpressionBuilder.Build(child);
    }

    switch (child.selectedWorkflowVariable.variableDataType) {
      case VariableDataType.Boolean:
        child.selectedMultipleChoice = data.inputChildMemberComparison;
        expression = ConditionalBooleanExpressionBuilder.Build(child);
        break;

      case VariableDataType.Checkboxes:
        child.selectedMultipleChoice = data.inputChildMemberComparison;
        expression = ConditionalMultiSelectExpressionBuilder.Build(child);
        break;

      case VariableDataType.SimpleConditional:
        expression = ConditionalSimpleExpressionBuilder.Build(child);
        break;

      case VariableDataType.Dropdown:
      case VariableDataType.Text:
        child.selectedMultipleChoice = child.inputGtLtEq;
        expression = ConditionalSingleSelectExpressionBuilder.Build(child);
        break;

      case VariableDataType.Date:
      case VariableDataType.Radio:
        child.selectedMultipleChoice = data.inputChildMemberComparison;
        expression = ConditionalSingleSelectExpressionBuilder.Build(child);
        break;

      case VariableDataType.Number:
        child.optionGtLtEqValue = ConditionalComparisonOption.eq;
        expression = ConditionalNumberExpressionBuilder.Build(child);
        break;
    }

    return expression;
  }

  public static buildObjectNumberExpression(data: ConditionalChild): string {
    let expr;

    let clone = require("lodash.clone");

    let child = new ConditionalChild();

    child.selectedWorkflowVariable = clone(data.selectedWorkflowVariable);

    child.selectedWorkflowVariable.id = `${child.selectedWorkflowVariable.id}.number()`;
    child.inputGtLtEq = data.inputGtLtEq;
    child.optionGtLtEqValue = data.optionGtLtEqValue;

    return ConditionalNumberExpressionBuilder.Build(child);
  }
}
