import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { WorkflowService } from "../shared/workflow.service";
import { Utils } from "../shared/Utils";
import { SettingsService } from "../shared/settings.service";
import { Constants } from "../shared/Constants";
import { LoginSettingsModel } from "../login/LoginSettingsModel";
import { ActivatedRoute } from "@angular/router";
import { RollbarService } from "../shared/rollbarerrorhandler.service";
import Rollbar from "rollbar";
import { DropdownOption } from "../shared/Types";
import { FormControl } from "@angular/forms";

/**
 * Contains the view and logic for selecting interviews/workflows.
 * Refreshing interviews for changes is handled here also.
 */
@Component({
  selector: "app-interview",
  templateUrl: "./workflow-select.component.html",
  styleUrls: ["./workflow-select.component.css"],
})
export class WorkflowSelectComponent {
  /**
   * Holds list the current list of interviews.
   */
  public interviews: DropdownOption[] = [];
  /**
   * Stores the currently selected interview.
   */
  public selectedWorkflow: FormControl = new FormControl();

  /**
   * A reference to the refresh button in the template.
   */
  @ViewChild("refreshBtn") refreshBtn: ElementRef<HTMLButtonElement>;

  /**
   * Grabs the interview workflow list from the InterviewService on creation.
   * @param workflowService
   * @param settings
   * @param route
   * @param rollbar
   */
  constructor(
    private workflowService: WorkflowService,
    private settings: SettingsService,
    private route: ActivatedRoute,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.interviews = Utils.StringArrayToCodeArray(
      this.workflowService.WorkflowList
    );
    this.selectedWorkflow.setValue(this.interviews[0]);
    this.subscribeToSelectedWorkflowChange();

    this.route.queryParams.subscribe((params) => {
      if (params?.selectedWorkflow)
        this.selectedWorkflow.setValue({
          code: params.selectedWorkflow,
          text: params.selectedWorkflow,
        });
    });
  }

  /**
   * Fired on change in dropdown. Calls out to the InterviewService
   * to download a new list of variables for the newly selected workflow.
   */
  subscribeToSelectedWorkflowChange() {
    this.selectedWorkflow.valueChanges.subscribe((v) => {
      this.workflowService.FetchWorkflowVariables(v.code);
    });
  }

  /**
   * Fired on click of refresh button.
   */
  refreshWorkflowList() {
    let __ = this;
    let savedSettings: LoginSettingsModel = this.settings.getSettings(
      Constants.LOGIN_SETTINGS_ACTIVE_KEY
    );

    this.workflowService.FetchWorkflows().then(() => {
      __.interviews = Utils.StringArrayToCodeArray(
        __.workflowService.WorkflowList
      );
      __.workflowService.FetchWorkflowVariables(__.selectedWorkflow.value.code);
    });

    Utils.blurButton(this.refreshBtn);
  }
}
