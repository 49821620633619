import * as lexing from "lexing";
import { TokenType } from "./TokenType";
import { RegexConstants } from "../../shared/RegexConstants";

export class LexerRules {
  // It will use the first rule with a matching regex, so go from more specific
  // to more catch-all. The ^ anchor before every regex is required!
  static rules: lexing.RegexRule<string>[] = [
    [
      /^$/,
      function (match) {
        return lexing.Token(TokenType.EOF, null);
      },
    ],
    [
      /^[\x00-\x1F\s]+/,
      function (match) {
        return null; // ignore whitespace
      },
    ],
    [
      /^\[\[/,
      function (match) {
        return lexing.Token(TokenType.COND_OPEN, match[0]);
      },
    ],
    [
      /^\]\]/,
      function (match) {
        return lexing.Token(TokenType.COND_CLOSE, match[0]);
      },
    ],
    [
      /^({%\s|{%p\s|{%tr\s)/,
      function (match) {
        return lexing.Token(TokenType.STMT_OPEN, match[0]);
      },
    ],
    [
      /^{{/,
      function (match) {
        return lexing.Token(TokenType.EXPR_OPEN, match[0]);
      },
    ],
    [
      /^}}/,
      function (match) {
        return lexing.Token(TokenType.EXPR_CLOSE, match[0]);
      },
    ],
    [
      /^%}/,
      function (match) {
        return lexing.Token(TokenType.STMT_CLOSE, match[0]);
      },
    ],
    [
      /^\(/,
      function (match) {
        return lexing.Token(TokenType.PAREN_OPEN, match[0]);
      },
    ],
    [
      /^\)/,
      function (match) {
        return lexing.Token(TokenType.PAREN_CLOSE, match[0]);
      },
    ],

    [
      /^(and|or)\b/,
      function (match) {
        return lexing.Token(TokenType.LOGIC_OP, match[0]);
      },
    ],
    [
      /^not\b/,
      function (match) {
        return lexing.Token(TokenType.NOT, match[0]);
      },
    ],

    [
      /^(\||\.)/,
      function (match) {
        return lexing.Token(TokenType.FILTER_OP, match[0]);
      },
    ],

    [
      /^for\b/,
      function (match) {
        return lexing.Token(TokenType.CTRL_FOR, match[0]);
      },
    ],
    [
      /^endfor\b/,
      function (match) {
        return lexing.Token(TokenType.END_CTRL, match[0]);
      },
    ],
    [
      /^endif\b/,
      function (match) {
        return lexing.Token(TokenType.END_COND, match[0]);
      },
    ],
    [
      /^else\b/,
      function (match) {
        return lexing.Token(TokenType.ELSE_COND, match[0]);
      },
    ],
    [
      /^(if|elif)\b/,
      function (match) {
        return lexing.Token(TokenType.COND_IF, match[0]);
      },
    ],

    [
      /^endset\b/,
      function (match) {
        return lexing.Token(TokenType.END_SET, match[0]);
      },
    ],
    [
      /^set\b/,
      function (match) {
        return lexing.Token(TokenType.BEGIN_SET, match[0]);
      },
    ],
    [
      /^[item|nesteditem]\.(\w+)/,
      function (match) {
        return lexing.Token(TokenType.IDENTIFIER, match[1]);
      },
    ],

    [
      /^(item|nesteditem)\b/,
      function (match) {
        return lexing.Token(TokenType.IDENTIFIER, match[0]);
      },
    ],
    [
      /^in\b/,
      function (match) {
        return lexing.Token(TokenType.CTRL_IN, match[0]);
      },
    ],
    [
      /^(\/\/|\*\*|\+|\-|\*|%|\/)/,
      function (match) {
        return lexing.Token(TokenType.MATH_OP, match[0]);
      },
    ],
    [
      /^[-+]?([0-9]*\.[0-9]+|[0-9]+)(e[0-9]+)?/,
      function (match) {
        return lexing.Token(TokenType.NUMBER, match[0]);
      },
    ],

    [
      /^(==|!=|>=|<=|<|>)/,
      function (match) {
        return lexing.Token(TokenType.COMP_OP, match[0]);
      },
    ],
    [
      /^True\b/,
      function (match) {
        return lexing.Token(TokenType.BOOLEAN, match[1]);
      },
    ],
    [
      /^False\b/,
      function (match) {
        return lexing.Token(TokenType.BOOLEAN, match[1]);
      },
    ],
    [
      /^attribute=[‘'"“’”](\w+)[‘'"“’”]/,
      function (match) {
        return lexing.Token(TokenType.IDENTIFIER, match[1]);
      },
    ],
    [
      /^starting=(\w+)/,
      function (match) {
        return lexing.Token(TokenType.IDENTIFIER, match[1]);
      },
    ],
    [
      /^ending=(\w+)/,
      function (match) {
        return lexing.Token(TokenType.IDENTIFIER, match[1]);
      },
    ],
    [
      /*TODO Test this: Seems like we should be sending
       *  match[1] and not match[0]*/
      /^(days|weeks|years)=(\d+)/,
      function (match) {
        return lexing.Token(TokenType.DATE_ARG, match[0]);
      },
    ],
    [
      /^format=[‘'"“’”][^‘'"“’”]+[‘'"“’”]/,
      function (match) {
        return lexing.Token(TokenType.DATE_ARG, match[0]);
      },
    ],
    [
      /^use_word=([tT]rue|[fF]alse)/,
      function (match) {
        return lexing.Token(TokenType.DATE_ARG, match[0]);
      },
    ],
    [
      RegexConstants.regexClioIdentifier,
      function (match) {
        return lexing.Token(TokenType.IDENTIFIER, match[0]);
      },
    ],
    [
      /^(\w+)\.true_values\(\)\.number\(\)/,
      function (match) {
        return lexing.Token(TokenType.NUMBER, match[1]);
      },
    ],

    [
      /^(\w+)\.true_values\(\)/,
      function (match) {
        return lexing.Token(TokenType.IDENTIFIER, match[1]);
      },
    ],
    [
      /^(\w+)\[(\d+|i)\]\.\w+/,
      function (match) {
        return lexing.Token(TokenType.IDENTIFIER, match[1]);
      },
    ],

    [
      /^(\w+)\[([‘'"“’”]([^‘'"“’”]*)[‘'"“’”]|\s*\w+\s*)(\s*\+\s*[‘'"“’”]([^‘'"“’”]*)[‘'"“’”]|\s*\+\s*\w+\s*)*\]/,
      function (match) {
        return lexing.Token(TokenType.IDENTIFIER, match[1]);
      },
    ],
    [
      /^(\w+)/,
      function (match) {
        return lexing.Token(TokenType.IDENTIFIER, match[0]);
      },
    ],
    [
      /^[‘]((<[^\s<>]*>|[^<>’])*)[’]/,
      function (match) {
        return lexing.Token(TokenType.STRING, match[1]);
      },
    ],
    [
      /^[']((<[^\s<>]*>|[^<>'])*)[']/,
      function (match) {
        return lexing.Token(TokenType.STRING, match[1]);
      },
    ],
    [
      /^["]((<[^\s<>]*>|[^<>"])*)["]/,
      function (match) {
        return lexing.Token(TokenType.STRING, match[1]);
      },
    ],
    [
      /^[“]((<[^\s<>]*>|[^<>”])*)[”]/,
      function (match) {
        return lexing.Token(TokenType.STRING, match[1]);
      },
    ],
    [
      /^,/,
      function (match) {
        return lexing.Token(TokenType.ARG_SEPARATOR, match[0]);
      },
    ],
    [
      /^./,
      function (match) {
        return lexing.Token(TokenType.PUNCTUATION, match[0]);
      },
    ],
  ];
}
