import { Inject, Injectable } from "@angular/core";
import { Constants } from "./Constants";
import Rollbar from "rollbar";
import { RollbarService } from "./rollbarerrorhandler.service";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  isAvailable(): boolean {
    try {
      if (typeof Storage !== "undefined") {
        localStorage.setItem("ping", "pong");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.rollbar.warn(
        "SettingsService: Exception thrown while running isAvailable",
        error
      );

      return false;
    }
  }

  getSettings(settingKey: string): any {
    try {
      if (typeof Storage !== "undefined") {
        let setting = localStorage.getItem(settingKey);
        if (setting == null) {
          return Constants.SETTING_KEY_NOT_FOUND;
        }
        return JSON.parse(setting);
      } else {
        return Constants.SETTING_KEY_NOT_FOUND;
      }
    } catch (error) {
      this.rollbar.warn(
        `Exception thrown in getSettings while getting ${settingKey}: ${error}`
      );
      return Constants.SETTING_KEY_NOT_FOUND;
    }
  }

  setSettings(settingKey: string, settingValue: object) {
    try {
      if (typeof Storage !== "undefined") {
        localStorage.setItem(settingKey, JSON.stringify(settingValue));
      }
    } catch (error) {
      this.rollbar.warn(
        `Exception thrown in setSettings while setting ${settingKey} to ${settingValue}: ${error}`
      );
    }
  }

  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}
}
