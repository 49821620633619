import { Component } from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { ConditionalChild } from "../../models/conditional-child.model";

@Component({
  selector: "app-conditional-true-false",
  templateUrl: "./conditional-true-false.component.html",
  styleUrls: ["./conditional-true-false.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConditionalTrueFalseComponent,
      multi: true,
    },
  ],
})
export class ConditionalTrueFalseComponent implements ControlValueAccessor {
  public uniqueID: string = "isisnot" + new Date().getMilliseconds();
  public onChange = (newValue) => {};
  public form: FormGroup = this.fb.group({
    inputBoolean: new FormControl("IS"),
  });
  private conditionalChild: ConditionalChild = new ConditionalChild();

  constructor(private fb: FormBuilder) {
    this.form.controls["inputBoolean"].valueChanges.subscribe((newValue) => {
      this.conditionalChild.optionGtLtEqValue = newValue;
      this.onChange(this.conditionalChild);
    });

    this.onChange(this.conditionalChild);
  }

  registerOnTouched(fn: any) {}

  setDisabledState(isDisabled: boolean) {}

  writeValue(obj: any) {}

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  onComparisonBooleanChange(newValue: string) {
    this.form.controls["inputBoolean"].setValue(newValue);
  }
}
