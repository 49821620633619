import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NumberChildOutputData } from "./number-child/NumberChildOutputData";
import { CalculationSyntaxBuilder } from "./CalculationSyntaxBuilder";
import { Utils } from "../shared/Utils";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RollbarService } from "../shared/rollbarerrorhandler.service";
import Rollbar from "rollbar";
import { DropdownOption } from "../shared/Types";
import { WorkflowService } from "../shared/workflow.service";
import { SimpleVariable } from "../shared/variableTypes/SimpleVariable";
import { NumberVariable } from "../shared/variableTypes/NumberVariable";
import { Constants } from "../shared/Constants";

@Component({
  selector: "app-calc-number",
  templateUrl: "./number.component.html",
  styleUrls: ["./number.component.css"],
})
export class NumberComponent implements OnInit {
  displayFirstChildOnly: boolean = false;
  form: FormGroup;
  formatOptions: DropdownOption[] = [
    { code: 0, text: "Number" },
    { code: 1, text: "Money" },
    { code: 2, text: "Decimal" },
    { code: 3, text: "Decimal with Commas" },
    { code: 4, text: "Word" },
    { code: 5, text: "Ordinal Number" },
  ];

  workflowVariables: Map<string, SimpleVariable> = new Map<
    string,
    SimpleVariable
  >();

  workflowNumberVariables: NumberVariable[] = [];
  @ViewChild("insertBtn") insertBtn: ElementRef<HTMLButtonElement>;

  CALCULATE_TOOLTIP = Constants.CALCULATE_TOOLTIP;

  constructor(
    private interview: WorkflowService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}
  ngOnInit() {
    this.buildForm();
    this.subscribeToFormValueChanges();
    this.subscribeToWorkflowVariables();
  }

  get numberChildren(): FormArray {
    return this.form.get("numberChildren") as FormArray;
  }

  addNumberChild() {
    this.numberChildren.push(this.fb.control(null));
    this.cd.detectChanges();
  }

  removeNumberChild() {
    this.numberChildren.removeAt(this.numberChildren.length - 1);
  }

  displayDecimalOption(): boolean {
    const formatOption = this.form.controls.formatOption.value.code;
    if (formatOption == 2 || formatOption == 3) return true;
    else {
      return false;
    }
  }

  //Test to see if a variable has a parent
  hasParent(variableID: string): boolean {
    let parent = this.workflowVariables.get(variableID);
    if (parent?.itemParent) {
      return true;
    }
    return false;
  }

  hideAddRemove() {
    //Hide add and remove buttons when the first dropdown
    //Has a "Sum of repeating item" variable selected.
    if (this.numberChildren.controls[0].value?.childItem) {
      return true;
    }
    return false;
  }
  /*TODO Consider capitalizing Method names, and updating naming conventions for fields
   *  and properties as well. Low priority ofc.*/
  buildForm() {
    this.form = this.fb.group({
      formatOption: this.fb.control({ code: 0 }),
      decimalPlaces: this.fb.control(2),
      numberChildren: this.fb.array(
        [this.fb.control(null)],
        [Validators.required]
      ),
    });
    this.cd.detectChanges();
  }

  subscribeToFormValueChanges() {
    this.form.controls["numberChildren"].valueChanges.subscribe((values) => {
      this.displayFirstChildOnly = values[0]?.childItem;
    });
  }

  subscribeToWorkflowVariables() {
    /*Store and subscribe to all variables*/
    this.interview.workflowVariablesMapById.subscribe((e) => {
      this.workflowVariables = e;
    });

    this.interview.workflowVariablesNumber.subscribe((x) => {
      let y = x.filter((v) => {
        //We want top level variables
        if (!v.itemParent) return true;
        //We don't want nested items
        if (!this.hasParent(v.itemParent)) return true;
        return false;
      });
      this.workflowNumberVariables = <NumberVariable[]>y;
    });
  }

  async insertCalculation() {
    let numberChildOutputs: NumberChildOutputData[] = [];
    this.numberChildren.controls.forEach((child) => {
      numberChildOutputs.push(child.value);
    });
    let expr = CalculationSyntaxBuilder.BuildSyntax(
      numberChildOutputs,
      this.form.controls["formatOption"].value.code,
      this.form.controls["decimalPlaces"].value
    );

    return (
      Word.run(async (context) => {
        // insert a paragraph at the end of the document.
        let range = context.document.getSelection();
        let font = range.font;

        range = range.insertHtml(expr, Word.InsertLocation.replace);
        range.load("font");
        range.font.set(font);

        console.debug("syncing....");
        await range.context.sync();

        console.debug("done.");
      })
        /* TODO RichAPI.Error seems to be a result of the document being in read only mode.
            Allowing editing resolved that problem. We should consider moving the
             insert functionality to a single class/service, and displaying a message to
              the user on the UI when this happens. */
        .catch((error) => {
          if (error instanceof OfficeExtension.Error) {
            this.rollbar.warn("Error during insert Number syntax", error);
          }
        })
        .then(() => {
          Utils.blurButton(this.insertBtn);
        })
    );
  }
}
