import {
  ConditionalChild,
  ConditionalComparisonOption,
} from "../models/conditional-child.model";
import { RegexConstants } from "../../shared/RegexConstants";

export class ConditionalSimpleExpressionBuilder {
  public static Build(data: ConditionalChild): string {
    let expr = "";
    if (
      data.optionGtLtEqValue == ConditionalComparisonOption.is &&
      RegexConstants.REGEX_MULTICHOICE_OR_CLIO.test(
        data.selectedWorkflowVariable.id
      ) &&
      RegexConstants.REGEX_CONDITIONAL_BLANK.test(
        data.selectedMultipleChoice.toString()
      )
    ) {
      //WA-119
      expr = `not ${data.selectedWorkflowVariable.id}`;
    } else if (
      data.optionGtLtEqValue == ConditionalComparisonOption.is &&
      RegexConstants.REGEX_CONDITIONAL_BLANK.test(
        data.selectedMultipleChoice.toString()
      )
    ) {
      expr = `${data.selectedWorkflowVariable.id} == ""`;
    } else if (
      data.optionGtLtEqValue == ConditionalComparisonOption.is ||
      (RegexConstants.REGEX_MULTICHOICE_OR_CLIO.test(
        data.selectedWorkflowVariable.id
      ) &&
        RegexConstants.REGEX_CONDITIONAL_BLANK.test(
          data.selectedMultipleChoice.toString()
        ) &&
        data.optionGtLtEqValue == "false")
    ) {
      expr = data.selectedWorkflowVariable.id;
    } else {
      expr = data.selectedWorkflowVariable.id + ' != ""';
    }

    return expr;
  }
}
