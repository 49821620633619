import {
  ConditionalChild,
  ConditionalComparisonOption,
} from "../models/conditional-child.model";

export class ConditionalExpressionUtils {
  public static BuildPreamble(
    condType: ConditionalType,
    data: ConditionalChild,
    fontFamily: string,
    fontSize: string
  ): string {
    if (data.optionGtLtEqValue == ConditionalComparisonOption.Value) {
      if (condType == ConditionalType.Phrase) {
        return `{% <span style="background-color:#00FFFF">for item in ${data.selectedWorkflowVariable.id} if `;
      } else {
        //ConditionalType.Paragraph
        return `{%p <span style="background-color:#00FF00;font-family: ${fontFamily};font-size: ${fontSize}pt">for item in ${data.selectedWorkflowVariable.id} if `;
      }
    }

    if (condType == ConditionalType.Phrase) {
      return '{% <span style="background-color:#00FFFF">if ';
    } else {
      return `{%p <span style="background-color:#00FF00;font-family: ${fontFamily};font-size: ${fontSize}pt">if `;
    }
  }
  public static VariableParser(expression: string = ""): string {
    let variable_tokens = [];
    let all_tokens = [];
    let regexVariableTest = /\${\s*(\w+)\s*}/g;
    let arr;
    let new_expression = "";
    /*Extract all variable tokens.
    They appear in the form ${ var }, we only
    want 'var'.
     */
    while ((arr = regexVariableTest.exec(expression)) != null) {
      variable_tokens.push(arr[1]);
    }

    /*Split the expression into variables
    and non variables, and get rid of empty
    strings in the result.
     */
    all_tokens = expression
      .split(regexVariableTest)
      .filter((value) => value.length > 0);

    all_tokens.forEach((value, index) => {
      let q = /"/.test(value) ? `'` : `"`;

      if (variable_tokens.find((variable) => value === variable)) {
        /*token is a variable*/
        new_expression = new_expression.concat(value);
      } else if (index == 0 && index == all_tokens.length - 1) {
        /*These else conditions are for inserting text that is not
        a variable. This depends on the substring's position
        within the expression.
         */
        new_expression = new_expression.concat(`${q}${value}${q}`);
      } else if (index == 0) {
        new_expression = new_expression.concat(`${q}${value}${q} + `);
      } else if (index == all_tokens.length - 1) {
        new_expression = new_expression.concat(` + ${q}${value}${q}`);
      } else {
        new_expression = new_expression.concat(` + ${q}${value}${q} + `);
      }
    });
    return new_expression;
  }
  public static BuildEndCap(
    condType: ConditionalType,
    comparator: string,
    fontFamily: string,
    fontSize: string
  ): string {
    let end = "endif";

    if (comparator == "value") {
      end = "endfor";
    }

    if (condType == ConditionalType.Phrase) {
      return `{% <span style="background-color:#00FFFF;">${end}</span> %}`;
    } else {
      return `{%p <span style="background-color:#00FF00;font-family: ${fontFamily};font-size: ${fontSize}pt">${end}</span> %}`;
    }
  }
}

/**
 * Generates the closing {% endif %} of the conditional.
 * How this is done depends on whether there is a phrase
 * or a paragraph is being inserted, and there is highlighting
 * syntax inserted as well.
 * @param condType
 * @param comparator
 * @constructor
 */
export class ConditionalExpression {
  openingExpression: string;
  closingExpression: string;
  constructor(openingExpression: string, closingExpression: string) {
    this.openingExpression = openingExpression;
    this.closingExpression = closingExpression;
  }
}

export enum ConditionalType {
  Phrase,
  Paragraph,
}
