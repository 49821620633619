import {
  BuiltInDateFilters,
  BuiltInDateFunctions,
  BuiltInFilters,
  BuiltInFunctions,
  BuiltInNumberFilters,
  BuiltInNumberFunctions,
  BuiltInTextFunctions,
  BuiltInVariables,
} from "./BuiltInKeywords";
import {
  FilterSymbolTableEntry,
  FunctionSymbolTableEntry,
  SymbolTableEntry,
  SymbolTableEntryScope,
  VariableSymbolTableEntry,
} from "./SymbolTableHelper";
import { SimpleVariable } from "../../shared/variableTypes/SimpleVariable";
import { ISymbolTableEntry } from "./ISymbolTableEntry";

export class SymbolTable {
  private _symbolTable: Map<string, SymbolTableEntry>;
  private _argumentScopeMap: Map<string, SymbolTableEntryScope[]> = new Map<
    string,
    SymbolTableEntryScope[]
  >([
    ["today", [SymbolTableEntryScope.Date]],
    ["format_date", [SymbolTableEntryScope.Date]],
    ["date_difference", [SymbolTableEntryScope.Date]],
    ["next_business_day", [SymbolTableEntryScope.Date]],
    ["prior_business_day", [SymbolTableEntryScope.Date]],
    ["date_interval", [SymbolTableEntryScope.Date]],
    ["last_day_of_month", [SymbolTableEntryScope.Date]],
    ["relative_date_difference", [SymbolTableEntryScope.Date]],
    ["day", [SymbolTableEntryScope.Date]],
    ["week", [SymbolTableEntryScope.Date]],
    ["month", [SymbolTableEntryScope.Date]],
    ["year", [SymbolTableEntryScope.Date]],
    ["days", [SymbolTableEntryScope.Date]],
    ["weeks", [SymbolTableEntryScope.Date]],
    ["months", [SymbolTableEntryScope.Date]],
    ["years", [SymbolTableEntryScope.Date]],
    ["float", [SymbolTableEntryScope.Number]],
    ["int", [SymbolTableEntryScope.Number]],
    ["sum", [SymbolTableEntryScope.Number]],
    ["number", [SymbolTableEntryScope.Number, SymbolTableEntryScope.Object]],
    ["round", [SymbolTableEntryScope.Number]],
    ["numbers_to_words", [SymbolTableEntryScope.Number]],
    ["Decimals0Commas", [SymbolTableEntryScope.Number]],
    ["Decimals0NoCommas", [SymbolTableEntryScope.Number]],
    ["Decimals2Commas", [SymbolTableEntryScope.Number]],
    ["number_iterator", [SymbolTableEntryScope.Number]],
    ["number_iterator_ordinal", [SymbolTableEntryScope.Number]],
    ["number_iterator_numeric", [SymbolTableEntryScope.Number]],
    ["format_number_eu", [SymbolTableEntryScope.Number]],
    ["number_custom", [SymbolTableEntryScope.Number]],
    ["ordinal_number", [SymbolTableEntryScope.Number]],
    ["format_decimal", [SymbolTableEntryScope.Number]],
    [
      "repeating_item_sum",
      [SymbolTableEntryScope.Number, SymbolTableEntryScope.Object],
    ],
    ["true_values", [SymbolTableEntryScope.Multi]],
    [">", [SymbolTableEntryScope.Number]],
    [">=", [SymbolTableEntryScope.Number]],
    ["<", [SymbolTableEntryScope.Number]],
    ["-", [SymbolTableEntryScope.Number]],
    ["*", [SymbolTableEntryScope.Number]],
    ["/", [SymbolTableEntryScope.Number]],
    ["<=", [SymbolTableEntryScope.Number]],
    [
      "==",
      [
        SymbolTableEntryScope.Number,
        SymbolTableEntryScope.Text,
        SymbolTableEntryScope.Date,
      ],
    ],
    [
      "+",
      [
        SymbolTableEntryScope.Number,
        SymbolTableEntryScope.Text,
        SymbolTableEntryScope.Date,
      ],
    ],
    ["upper", [SymbolTableEntryScope.Text]],
    ["lower", [SymbolTableEntryScope.Text]],
    ["title_case", [SymbolTableEntryScope.Text]],
    ["capitalize", [SymbolTableEntryScope.Text]],
    ["indefinite_article", [SymbolTableEntryScope.Text]],
    ["quantity_noun", [SymbolTableEntryScope.Text]],
    ["fix_punctuation", [SymbolTableEntryScope.Text]],
    ["manual_line_breaks", [SymbolTableEntryScope.Text]],
    ["verb_present", [SymbolTableEntryScope.Text]],
    ["replace", [SymbolTableEntryScope.Text]],
    ["currency", [SymbolTableEntryScope.Number]],
    ["commalist", [SymbolTableEntryScope.Object, SymbolTableEntryScope.Text]],
  ]);

  private _argumentCountMap: Map<string, number> = new Map<string, number>([
    ["commalist", 2],
    ["commalistoxford", 2],
    ["semicolonlist", 2],
  ]);
  constructor(variables: SimpleVariable[]) {
    this._symbolTable = new Map<string, SymbolTableEntry>();
    this.AddBuiltInFunctions();
    this.AddBuiltInDateFunctions();
    this.AddBuiltInNumberFunctions();
    this.AddBuiltInVariables();
    this.AddBuiltInFilters();
    this.AddBuiltInDateFilters();
    this.AddBuiltInNumberFilters();
    this.AddBuiltInTextFunctions();

    variables?.forEach((variable) => {
      this._symbolTable.set(
        variable.id,
        new VariableSymbolTableEntry(variable.id, variable.symbolTableScopes)
      );
    });
  }

  private AddBuiltInFilters() {
    BuiltInFilters.forEach((name) => {
      this._symbolTable.set(name, new FilterSymbolTableEntry(name));
    });
  }

  private AddBuiltInDateFilters() {
    BuiltInDateFilters.forEach((name) => {
      this._symbolTable.set(
        name,
        new FilterSymbolTableEntry(name, SymbolTableEntryScope.Date)
      );
    });
  }

  private AddBuiltInNumberFilters() {
    BuiltInNumberFilters.forEach((name) => {
      this._symbolTable.set(
        name,
        new FilterSymbolTableEntry(name, SymbolTableEntryScope.Number)
      );
    });
  }

  private AddBuiltInFunctions() {
    BuiltInFunctions.forEach((name) => {
      this._symbolTable.set(name, new FunctionSymbolTableEntry(name));
    });
  }

  private AddBuiltInTextFunctions() {
    BuiltInTextFunctions.forEach((name) => {
      this._symbolTable.set(
        name,
        new FunctionSymbolTableEntry(name, SymbolTableEntryScope.Text)
      );
    });
  }

  private AddBuiltInNumberFunctions() {
    BuiltInNumberFunctions.forEach((name) => {
      this._symbolTable.set(
        name,
        new FunctionSymbolTableEntry(name, SymbolTableEntryScope.Number)
      );
    });
  }

  private AddBuiltInDateFunctions() {
    BuiltInDateFunctions.forEach((name) => {
      this._symbolTable.set(
        name,
        new FunctionSymbolTableEntry(name, SymbolTableEntryScope.Date)
      );
    });
  }

  private AddBuiltInVariables() {
    BuiltInVariables.forEach((entry) => {
      this.AddSymbol(entry);
    });
  }

  AddSymbol(entry: ISymbolTableEntry) {
    if (!this.Lookup(entry.id)) {
      this._symbolTable.set(
        entry.id,
        new VariableSymbolTableEntry(entry.id, entry.scopes)
      );
    }
  }

  Lookup(identifier: string): SymbolTableEntry {
    return this._symbolTable.get(identifier);
  }

  ArgumentScopeMap(identifier: string): SymbolTableEntryScope[] {
    return this._argumentScopeMap.get(identifier);
  }

  ArgumentCountMap(identifier: string): number {
    return this._argumentCountMap.get(identifier);
  }
}
