import { Component, Inject } from "@angular/core";
import { SettingsService } from "./shared/settings.service";
import { Router } from "@angular/router";
import {
  rollbarFactory,
  RollbarService,
} from "./shared/rollbarerrorhandler.service";
import Rollbar from "rollbar";
import { environment } from "../environments/environment";

/**
 * Bootstrapped component
 */
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  /**
   * Software version of the add-in. Manipulated
   * in external prebuild scripts and derived from
   * package.json
   */

  /**
   * "Title" of the add-in. Not used in the project to date.
   */
  title = "gavel-addin";

  constructor(
    private settingsService: SettingsService,
    private router: Router,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    rollbar.info(`Gavel Word add-in version ${environment.appVersion}`);
    if (
      this.settingsService.isAvailable() &&
      this.settingsService.getSettings("SplashPage")?.SkipSplash == true
    ) {
      this.router.navigate(["/login"]).catch((err) => {
        this.rollbar.error(`Error navigating to login: ${JSON.stringify(err)}`);
      });
    } else {
      console.warn(`Settings unavailable or SplashPage not skipped`);
      this.router.navigate(["/splash"]).catch((err) => {
        this.rollbar.error(
          `Error navigating to splash: ${JSON.stringify(err)}`
        );
      });
    }
  }
}
