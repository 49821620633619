import { ElementRef } from "@angular/core";
import { VariableDataType } from "./variableTypes/VariableType";
import { DropdownOption } from "./Types";

export class Utils {
  static blurButton(button: ElementRef<HTMLButtonElement>) {
    setTimeout(() => {
      button.nativeElement.blur();
    }, 500);
  }
  //Converts ASCII \x00-\x1F to a space
  static sanitizeString(expression: string) {
    return expression.replace(/[\x00-\x1F]/g, "");
  }

  static isComplexDataType(x: VariableDataType): boolean {
    switch (x) {
      case VariableDataType.Boolean:
      case VariableDataType.Checkboxes:
      case VariableDataType.Dropdown:
      case VariableDataType.Object:
      case VariableDataType.Radio:
      case VariableDataType.SimpleConditional:
        return true;
      default:
        return false;
    }
  }

  static StringArrayToCodeArray(arr: string[]): DropdownOption[] {
    return arr.map((v) => {
      return { code: v, text: v };
    });
  }
}
