<form [formGroup]="form">
  <div
    formArrayName="children"
    *ngFor="let child of children.controls; let i = index; first as isFirst"
  >
    <conditional-child
      [formControl]="child"
      [header]="isFirst"
      [soloChild]="isSoloChild()"
      [workflowVariablesConditional]="workflowVariablesConditional"
    ></conditional-child>
  </div>
</form>
<div class="form-row form-group">
  <ng-container *ngIf="showAdd()">
    <button
      (click)="addConditionalChild()"
      type="button"
      class="form-inline form-inline-check btn btn-link"
      style="font-size: 13px"
    >
      <i class="fas fa-plus text-dark"></i> Add Condition
    </button>
  </ng-container>
  <ng-container *ngIf="showRemove()">
    <button
      (click)="removeConditionalChild()"
      type="button"
      class="form-inline form-inline-check btn btn-link"
      style="font-size: 13px"
    >
      <i class="fas fa-times text-dark"></i>Remove
    </button>
  </ng-container>
</div>
<div class="form-group">
  <button
    #insertBtn
    (click)="insertConditionalSyntax()"
    class="btn btn-custom py-1"
    [disabled]="form?.invalid"
  >
    Insert Condition
  </button>
</div>
