import { Constants } from "../../shared/Constants";
import { RegexConstants } from "../../shared/RegexConstants";
import { TableRowSyntax } from "../DocumentSyntax";

export class TableCheck {
  static Check(tableRowSyntaxes: TableRowSyntax[]): Map<Word.Range, string[]> {
    let bad: Map<Word.Range, string[]> = new Map<Word.Range, string[]>();

    for (const tableRowSyntax of tableRowSyntaxes) {
      let row = tableRowSyntax.row;
      let cells = row.cells;
      if (row.cellCount > 1) {
        bad.set(row.parentTable.getRange(), [Constants.TABLE_ROW_SYNTAX_ERROR]);
      } else {
        for (const cell of cells.items) {
          let text = cell.value;
          if (
            RegexConstants.regexTableRow.test(text) &&
            !RegexConstants.regexTableRowExclusive.test(text)
          ) {
            bad.set(tableRowSyntax.range, [Constants.TABLE_ROW_SYNTAX_ERROR]);
            break;
          }
        }
      }
    }

    return bad;
  }
}
