import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ObjectComplexVariable } from "../shared/variableTypes/ObjectComplexVariable";
import { SimpleVariable } from "../shared/variableTypes/SimpleVariable";
import { WorkflowService } from "../shared/workflow.service";
import { RepeatComponentForm } from "./RepeatComponentForm";
import { RepeatVariableSyntaxBuilder } from "./RepeatVariableSyntaxBuilder";
import { Utils } from "../shared/Utils";
import { VariableDataType } from "../shared/variableTypes/VariableType";
import { RepeatListDropdown, RepeatListType } from "./RepeatListDropdown";
import {
  AbstractControl,
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { RepeatChildInputConfig } from "./RepeatChildInputConfig";
import { DocumentScanner } from "../syntax/DocumentScanner";
import { RollbarService } from "../shared/rollbarerrorhandler.service";
import Rollbar from "rollbar";
import { DropdownOption } from "../shared/Types";

@Component({
  selector: "app-repeat-variable",
  templateUrl: "./repeat.component.html",
  styleUrls: ["./repeat.component.css"],
})
export class RepeatComponent {
  @ViewChild("insertBtn") insertBtn: ElementRef<HTMLButtonElement>;

  repeatListDropdown: DropdownOption[] = RepeatListDropdown;
  repeatListTypes = RepeatListType;

  L0SelectItems: ObjectComplexVariable[] = [];
  L1ChildObjectsSelected: RepeatChildInputConfig[] = [];
  L1SelectItems: SimpleVariable[] = [];

  repeatChildrenFormData: RepeatComponentForm[] = [];

  workflowVariablesChildren: SimpleVariable[] = [];

  disableInsertBtn: boolean = false;
  disableInsertBtnHoverMsg: string = "";

  formData: RepeatComponentForm = new RepeatComponentForm();

  public form: FormGroup;
  constructor(
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private interview: WorkflowService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.buildForm();
    this.subscribeToFormValueChanges();
    this.subscribeToWorkflowVariables();

    this.form.controls["itemNumber"].setValue(1);
  }

  buildForm() {
    this.form = this.fb.group(
      {
        selectListType: this.fb.control(RepeatListDropdown[0], [
          Validators.required,
        ]),
        L0Selected: this.fb.control(null, [Validators.required]),
        L1Selected: this.fb.control(null, [Validators.required]),
        RepeatChildForms: this.fb.array([]),
        itemNumber: this.fb.control(1),
        listSeparator: this.fb.control(null),
      },
      { validators: this.validateForm }
    );
  }

  subscribeToFormValueChanges() {
    this.subscribeToL0Change();
    this.subscribeToItemNumberChange();
    this.subscribeToListTypeChange();
  }
  L1ClearAndReset(v: ObjectComplexVariable) {
    if (!v) {
      return;
    }
    this.RepeatChildForms.clear();
    this.L1ChildObjectsSelected = [];
    this.form.controls["L1Selected"].setValue([]);
    this.L1SelectItems = this.getRepeatingChildren(v.id);
  }

  async insertField() {
    this.buildRepeatComponentForm();
    let openLoops = await DocumentScanner.GetForLoops();
    let expr = RepeatVariableSyntaxBuilder.BuildSyntax(
      this.formData,
      openLoops
    );

    return Word.run(async (context) => {
      let range = context.document.getSelection();
      range.load("font");

      await context.sync();
      let fontName = range.font.name;
      let fontSize = range.font.size;

      range.insertHtml(expr, Word.InsertLocation.replace);

      range.font.name = fontName;
      range.font.size = fontSize;

      await context.sync();
    })
      .catch((error) => {
        if (error instanceof OfficeExtension.Error) {
          this.rollbar.warn("Error during insert repeat syntax", error);
        }
      })
      .finally(() => {
        Utils.blurButton(this.insertBtn);
      });
  }

  displayMode1(): boolean {
    let mode1: boolean =
      this.formData.listType == this.repeatListTypes.ItemNumber;

    return mode1;
  }

  buildRepeatComponentForm() {
    this.formData.itemNumber = this.form.controls["itemNumber"].value;
    this.formData.listChildren = this.form.controls["L1Selected"].value.filter(
      (v) => {
        return (<SimpleVariable>v).variableDataType != VariableDataType.Object;
      }
    );
    this.formData.listType = this.form.controls["selectListType"].value.code;
    this.formData.listSeparator = this.form.controls["listSeparator"].value;
    this.formData.listVariable = this.form.controls["L0Selected"].value.id;

    this.formData.nestedChildren = [];
    this.RepeatChildForms.controls.forEach((v, i) => {
      let repeatComponentForm: RepeatComponentForm = v.value;
      this.formData.nestedChildren.push(repeatComponentForm);
    });
  }

  generateDescription(x: SimpleVariable): string {
    let selectedL0 = this.form.controls["L0Selected"].value;
    return `Attributes of ${selectedL0.text}.${x.text} to include`;
  }
  getRepeatingChildren(parent: string): SimpleVariable[] {
    return this.workflowVariablesChildren.filter((c) => {
      return c.itemParent == parent;
    });
  }

  minItemNumberCheck() {
    if (this.formData.itemNumber < 1) {
      this.formData.itemNumber = 1;
    }
  }

  get RepeatChildForms(): FormArray {
    return this.form.get("RepeatChildForms") as FormArray;
  }

  subscribeToL0Change() {
    this.form.controls["L0Selected"].valueChanges.subscribe((v) => {
      this.L1ClearAndReset(v);
    });
  }

  subscribeToItemNumberChange() {
    this.form.controls["itemNumber"].valueChanges.subscribe((v) => {
      this.formData.itemNumber = v;
    });
  }

  subscribeToListTypeChange() {
    //To trigger change detection for the repeat child listType input
    this.form.controls["selectListType"].valueChanges.subscribe(
      (newValue: DropdownOption) => {
        this.formData.listType = <RepeatListType>newValue.code;
      }
    );
  }
  onL1Add(newL1: SimpleVariable) {
    if (newL1.variableDataType == VariableDataType.Object) {
      let config = new RepeatChildInputConfig();
      config.Items = this.getRepeatingChildren(newL1.id);
      config.Parent = newL1;
      config.Description = this.generateDescription(newL1);

      this.L1ChildObjectsSelected.push(config);
      this.RepeatChildForms.push(this.fb.control(null));
      this.cd.detectChanges();
    }
  }

  onL1Remove(oldL1: any) {
    if (oldL1.variableDataType == VariableDataType.Object) {
      let target: number = -1;
      this.L1ChildObjectsSelected.forEach((v, i) => {
        if (v.Parent == oldL1) {
          target = i;
        }
      });
      if (target >= 0) {
        this.L1ChildObjectsSelected = this.L1ChildObjectsSelected.filter(
          (v, i) => {
            return i != target;
          }
        );
        this.RepeatChildForms.removeAt(target);
      }
    }
  }

  subscribeToWorkflowVariables() {
    //Filter out variables that are not of the complex type
    this.interview.workflowVariablesRepeat.subscribe((x) => {
      this.L0SelectItems = <ObjectComplexVariable[]>x;
    });

    this.interview.workflowVariablesRepeatChildren.subscribe((x) => {
      this.workflowVariablesChildren = [];
      this.workflowVariablesChildren = x;
    });
  }

  validateForm(control: AbstractControl): ValidationErrors | null {
    let formGroup: FormGroup = <FormGroup>control;

    if (
      formGroup.controls["selectListType"].value == RepeatListType.ItemNumber &&
      !formGroup.controls["itemNumber"].value
    ) {
      return { NoItemNumber: true };
    } else if (
      formGroup.controls["selectListType"].value == RepeatListType.Custom &&
      !formGroup.controls["listSeparator"].value
    ) {
      return { NoListSeparator: true };
    }

    return null;
  }
}
