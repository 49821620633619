import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { SimpleVariable } from "./SimpleVariable";
import { VariableDataType } from "./VariableType";

export class TextAreaVariable extends SimpleVariable {
  public simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "single",
      text: "Single Line",
    },
    {
      code: "multi",
      text: "Multi Line",
    },
    {
      code: "title",
      text: "Title Case",
    },
  ];
  label: string;
  constructor(handle: string, display: string, label: string, parent?: string) {
    super(handle, display, VariableDataType.Area, null, parent);

    this.label = label;
  }
}
