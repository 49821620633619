<form [formGroup]="form">
  <div class="form-group">
    <ng-container *ngIf="displayMode1">
      <app-conditional-true-false
        formControlName="InputBoolean"
      ></app-conditional-true-false
    ></ng-container>
    <ng-container *ngIf="!displayMode1"
      ><app-conditional-comparison
        formControlName="OptionGtLtEq"
        [displayValueOption]="false"
      >
      </app-conditional-comparison
    ></ng-container>
    <app-variable-dropdown
      formControlName="selectedDateComparisonVariable"
      [appendTo]="'body'"
      [options]="ngDateVariables"
      [clearable]="false"
    >
    </app-variable-dropdown>
    <br />
  </div>
</form>
