import { Component, OnInit } from "@angular/core";
import {
  NotificationModel,
  NotificationService,
  NotificationType,
} from "../shared/notification.service";
import { Constants } from "../shared/Constants";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
})
export class NotificationComponent implements OnInit {
  notification: NotificationModel = new NotificationModel("Login and get started!",NotificationType.Info)
  css: string;

  constructor(private notifications: NotificationService) {
    notifications.currentNotification.subscribe((msg) => {
      this.notification = msg;
      switch (msg.Type) {
        case NotificationType.Error:
          this.css = Constants.NOTIFICATION_CSS_ERROR;
          break;
        case NotificationType.Info:
          this.css = Constants.NOTIFICATION_CSS_INFO;
          break;
        case NotificationType.Warning:
          this.css = Constants.NOTIFICATION_CSS_WARNING;
      }
    });
  }

  ngOnInit(): void {}
}
