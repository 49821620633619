import { SimpleVariable } from "../variableTypes/SimpleVariable";
import {
  ComplexVariableType,
  VariableDataType,
} from "../variableTypes/VariableType";

export class FilterConditionalVariables {
  public static Filter(variable: SimpleVariable): boolean {
    if (variable.complexVarType == ComplexVariableType.Complex) {
      return true;
    }
    if (
      variable.variableDataType == VariableDataType.Number ||
      variable.variableDataType == VariableDataType.Date
    ) {
      return true;
    }

    return false;
  }
}
