import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  @Input("ShowLogoutButton") showLogout: boolean = false;
  constructor(private router: Router) {}

  ngOnInit(): void {}
  /**
   * Returns user to login page
   */
  logout() {
    this.router.navigate(["/login"], { queryParams: { noAutoLogin: true } });
  }
}
