import { Constants } from "../../shared/Constants";

export abstract class ParserException extends Error {}

export class VariableNotFoundException extends ParserException {
  public variable: string;
  constructor(variable: string) {
    super(`'${variable}' ${Constants.VARIABLE_OR_FUNCTION_NOT_IN_WORKFLOW}`);
    this.variable = variable;
  }
}

export class UnexpectedTokenException extends ParserException {
  public token: string;
  constructor(token: string) {
    super(`'${token}' ${Constants.SYNTAX_INVALID}`);
    this.token = token;
  }
}

export class UnexpectedConditionalTokenException extends ParserException {
  public token: string;
  constructor(token: string) {
    super(Constants.SYNTAX_COND_INVALID);
    this.token = token;
  }
}

export class UnexpectedExpressionTokenException extends ParserException {
  public token: string;
  constructor(token: string) {
    super(Constants.SYNTAX_EXPR_INVALID);
    this.token = token;
  }
}

export class IdentifierNotFoundException extends ParserException {
  public identifier: string;
  constructor(identifier: string) {
    super(`'${identifier}' ${Constants.VARIABLE_OR_FUNCTION_NOT_IN_WORKFLOW}`);
    this.identifier = identifier;
  }
}

export class MismatchDateScopeException extends ParserException {
  public identifier: string;
  constructor(identifier: string) {
    super(`'${identifier}' ${Constants.MISMATCH_DATE_SCOPE}`);
    this.identifier = identifier;
  }
}

export class MismatchNumberScopeException extends ParserException {
  public identifier: string;
  constructor(identifier: string) {
    super(`'${identifier}' ${Constants.MISMATCH_NUMBER_SCOPE}`);
    this.identifier = identifier;
  }
}

export class MismatchTextScopeException extends ParserException {
  public identifier: string;
  constructor(identifier: string) {
    super(`'${identifier}' ${Constants.MISMATCH_TEXT_SCOPE}`);
    this.identifier = identifier;
  }
}

export class PercentSignInLogicException extends ParserException {
  constructor() {
    super(Constants.PERCENT_SIGN_IN_LOGIC);
  }
}

export class NewlineInLogicException extends ParserException {
  constructor() {
    super(Constants.NEWLINE_IN_LOGIC);
  }
}

export class MismatchArgumentCountException extends ParserException {
  public identifier: string;
  constructor(identifier: string) {
    super(`'${identifier}' ${Constants.FUNCTION_MISSING_REQUIRED_COMPONENT}`);
    this.identifier = identifier;
  }
}
