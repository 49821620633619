import { SimpleVariable } from "../variableTypes/SimpleVariable";
import {
  ComplexVariableType,
  VariableDataType,
} from "../variableTypes/VariableType";

export class FilterSimpleVariables {
  public static Filter(variable: SimpleVariable): boolean {
    if (
      variable.complexVarType == ComplexVariableType.Complex &&
      variable.variableDataType == VariableDataType.Object
    ) {
      return false;
    } else if (variable.variableDataType == VariableDataType.Object_from_file) {
      return false;
    } else if (
      variable.variableDataType == VariableDataType.SimpleConditional
    ) {
      return false;
    } else if (variable.id.indexOf("[i]") != -1) {
      return false;
    } else {
      return true;
    }
  }
}
