import { Injectable } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  public currentNotification: Subject<NotificationModel> =
    new Subject<NotificationModel>();
  constructor() {}

  public newNotification(msg: string, type: NotificationType) {
    this.currentNotification.next(new NotificationModel(msg, type));
  }
}

export class NotificationModel {
  constructor(msg: string, type: NotificationType) {
    this.Message = msg;
    this.Type = type;
  }

  Message: string;
  Type: NotificationType;
}
export enum NotificationType {
  Info,
  Warning,
  Error,
}
