import {
  ArgumentOperatorASTNode,
  ASTNode,
  BinaryOperatorASTNode,
  BooleanASTNode,
  ComparisonBinaryOperatorASTNode,
  ControlBinaryOperatorASTNode,
  ControlOperatorASTNode,
  DateArgumentASTNode,
  ElseConditionalASTNode,
  EndConditionalASTNode,
  EndControlASTNode,
  EndSetASTNode,
  FilterASTNode,
  FilterBinaryOperatorASTNode,
  FunctionASTNode,
  LogicBinaryOperatorASTNode,
  MathBinaryOperatorASTNode,
  NotOperatorASTNode,
  NumberASTNode,
  PropertyASTNode,
  StringASTNode,
  UnaryOperatorASTNode,
  VariableASTNode,
} from "./ASTNode";
import { Lexer } from "./Lexer";
import { FunctionSignature, Token } from "../../shared/Types";
import { TokenType } from "./TokenType";
import { SymbolTable } from "./SymbolTable";
import {
  SymbolTableEntry,
  SymbolTableEntryScope,
  SymbolTableEntryType,
} from "./SymbolTableHelper";
import {
  IdentifierNotFoundException,
  MismatchArgumentCountException,
  MismatchDateScopeException,
  MismatchNumberScopeException,
  MismatchTextScopeException,
  NewlineInLogicException,
  PercentSignInLogicException,
  UnexpectedConditionalTokenException,
  UnexpectedExpressionTokenException,
  UnexpectedTokenException,
} from "./ParserException";

//STATEMENT: CONTROL |CONDITIONAL | END CONTROL | END CONDITIONAL
//CONTROL: FOR ITEM IN VARIABLE [CONDITIONAL]?
//CONDITIONAL: IF EXPRESSION
//EXPRESSION: [NOT]? TERM [[MATH_OP|COMP_OP|LOGIC_OP] TERM]*
//TERM: FACTOR [FILTER_OP FILTER_KEYWRD]*
//FACTOR: STRING | VARIABLE | NUMBER | (SUBEXPRESSION) | FUNCTION | IDENTIFIER
export class Parser {
  private static symbolTable: SymbolTable;
  private static variableStack: ASTNode[] = [];

  public static Parse(expression: string, table: SymbolTable): ASTNode {
    this.symbolTable = table;
    let lexer = new Lexer(expression);
    let open = lexer.ReadNext();
    let ast: ASTNode;
    //empty variableStack
    this.variableStack = [];

    switch (open.name) {
      case TokenType.EXPR_OPEN:
        ast = this.ParseExpression(lexer);
        try {
          this.Expect(lexer, TokenType.EXPR_CLOSE);
        } catch (e) {
          throw new UnexpectedExpressionTokenException(lexer.Peek().value);
        }
        lexer.ReadNext();
        this.ValidateVariables();
        this.IllegalCharCheckForExpression(ast);
        return ast;
      case TokenType.STMT_OPEN:
        ast = this.ParseStatement(lexer);
        try {
          this.Expect(lexer, TokenType.STMT_CLOSE);
        } catch (e) {
          throw new UnexpectedConditionalTokenException(lexer.Peek().value);
        }
        lexer.ReadNext();
        this.ValidateVariables();
        this.IllegalCharCheckForStatement(ast);
        return ast;

      default:
        /*Unexpected opening token, throw special error*/
        if (lexer.Peek().name == TokenType.COND_IF) {
          throw new UnexpectedConditionalTokenException(open.value);
        }
        throw new UnexpectedExpressionTokenException(open.value);
    }
  }

  //STATEMENT: {%[p|tr]? CONTROL |CONDITIONAL | END CONTROL | END CONDITIONAL %}
  private static ParseStatement(lexer: Lexer): ASTNode {
    let token = lexer.Peek();
    switch (token.name) {
      case TokenType.BEGIN_SET:
        return this.ParseSet(lexer);
      case TokenType.CTRL_FOR:
        return this.ParseControl(lexer);
      case TokenType.COND_IF:
        return this.ParseConditional(lexer);
      case TokenType.END_SET:
        return new EndSetASTNode(lexer.ReadNext());
      case TokenType.END_COND:
        return new EndConditionalASTNode(lexer.ReadNext());
      case TokenType.END_CTRL:
        return new EndControlASTNode(lexer.ReadNext());
      case TokenType.ELSE_COND:
        return new ElseConditionalASTNode(lexer.ReadNext());
      default:
        throw new UnexpectedTokenException(token.value);
    }
  }

  //EXPRESSION:  TERM [[MATH_OP|COMP_OP|LOGIC_OP] TERM]*
  private static ParseExpression(lexer: Lexer): ASTNode {
    let lookahead = lexer.Peek();
    let left: ASTNode;
    let op: Token;

    if (lookahead.name == TokenType.NOT) {
      op = lexer.ReadNext();
      left = this.ParseTerm(lexer);
      return new LogicBinaryOperatorASTNode(op, left, null);
    } else {
      left = this.ParseTerm(lexer);
    }

    lookahead = lexer.Peek();

    if (lookahead.name == TokenType.EXPR_CLOSE) {
      return left;
    }

    while (
      lookahead.name == TokenType.MATH_OP ||
      lookahead.name == TokenType.COMP_OP ||
      lookahead.name == TokenType.LOGIC_OP
    ) {
      op = lexer.ReadNext();
      let right = this.ParseTerm(lexer);
      left = this.CreateBinaryOperator(op, left, right);

      lookahead = lexer.Peek();
    }
    if (
      (<ComparisonBinaryOperatorASTNode>left)._token.name ==
        TokenType.COMP_OP ||
      (<ComparisonBinaryOperatorASTNode>left)._token.name == TokenType.MATH_OP
    ) {
      this.CheckArgs((<ComparisonBinaryOperatorASTNode>left)._token.name, [
        (<ComparisonBinaryOperatorASTNode>left).Left,
      ]);
    }
    return left;
  }
  //TERM: [NOT]? FACTOR [FILTER_OP FILTER_KEYWRD]* [CTRL_IN VARIABLE]
  private static ParseTerm(lexer: Lexer): ASTNode {
    let lookahead = lexer.Peek();
    let left: ASTNode;
    let op: Token;

    if (lookahead.name == TokenType.NOT) {
      op = lexer.ReadNext();
      left = this.ParseFactor(lexer);
      return this.CreateUnaryOperator(op, left);
    } else {
      left = this.ParseFactor(lexer);
    }

    lookahead = lexer.Peek();
    while (lookahead.name == TokenType.FILTER_OP) {
      let op = lexer.ReadNext();
      let right = this.ParseFilter(lexer);
      left = this.CreateBinaryOperator(op, left, right);

      //Arg check to make sure variable is the right type, WA-502
      this.CheckArgs(right._token.value, [(<BinaryOperatorASTNode>left).Left]);
      lookahead = lexer.Peek();
    }

    //{{ if "text" in variablename }}
    if (lookahead.value == "in") {
      let op = lexer.ReadNext(); //Accept the "in"
      let right = this.ParseVariable(lexer);
      left = this.CreateBinaryOperator(op, left, right);
    }
    return left;
  }

  //FACTOR: STRING | NUMBER | IDENTIFIER | (SUBEXPRESSION) | BOOLEAN
  private static ParseFactor(lexer: Lexer): ASTNode {
    let lookahead = lexer.Peek();

    switch (lookahead.name) {
      case TokenType.BOOLEAN:
        return this.ParseBoolean(lexer);
      case TokenType.STRING:
        return this.ParseString(lexer);
      case TokenType.NUMBER:
        return this.ParseNumber(lexer);
      case TokenType.IDENTIFIER:
        return this.ParseIdentifier(lexer);
      case TokenType.PAREN_OPEN:
        lexer.ReadNext(); //Accept PAREN_OPEN
        let node = this.ParseExpression(lexer);
        this.Expect(lexer, TokenType.PAREN_CLOSE);
        lexer.ReadNext(); //Accept PAREN_CLOSE
        return node;

      default:
        throw new UnexpectedTokenException(lookahead.value);
    }
  }

  //VARIABLE | FUNCTION | FILTER
  private static ParseIdentifier(lexer: Lexer): ASTNode {
    let lookahead = lexer.Peek();

    switch (this.symbolTable.Lookup(lookahead.value)?._entryType) {
      case SymbolTableEntryType.Variable:
        return Parser.ParseVariable(lexer);
      case SymbolTableEntryType.Function:
        return Parser.ParseFunction(lexer);
      case SymbolTableEntryType.Filter:
        return Parser.ParseFilter(lexer);
      default:
        return Parser.ParseVariable(lexer); //yuk
      //throw new IdentifierNotFoundException(lookahead.value);
    }
  }

  //VARIABLE.PROPERTY
  /*private static ParseProperty(lexer: Lexer, variableNode: ASTNode): ASTNode {
    let lookahead = lexer.Peek();

    let property = this.symbolTable.Lookup(lookahead.value);
    let variable = this.symbolTable.Lookup(variableNode._token.value);
    if (
      property?._scope == SymbolTableEntryScope.Date &&
      variable?._scope !== SymbolTableEntryScope.Date
    ) {
      throw new MismatchDateScopeException(variableNode._token.value);
    }

    let left = new PropertyASTNode(lexer.ReadNext());

    if (lexer.Peek().name == TokenType.PAREN_OPEN) {
      lexer.ReadNext(); //Accept '('
      this.Expect(lexer, TokenType.PAREN_CLOSE);
      lexer.ReadNext();
    }
    return left;
  }*/

  //Filters now support parameters
  private static ParseFilter(lexer: Lexer): ASTNode {
    let op = lexer.ReadNext();
    let left: ASTNode[];
    if (lexer.Peek().name == TokenType.PAREN_OPEN) {
      //Filter might have argument, grab argument if so,
      //close paren if not
      lexer.ReadNext(); //Accept paren

      if (lexer.Peek().name != TokenType.PAREN_CLOSE) {
        let args = this.ParseArgs(lexer);
      } else {
        this.Expect(lexer, TokenType.PAREN_CLOSE);
      }
      lexer.ReadNext();
    }
    return new FilterASTNode(op);
  }
  private static ParseNumber(lexer: Lexer): ASTNode {
    Parser.Expect(lexer, TokenType.NUMBER);
    return new NumberASTNode(lexer.ReadNext());
  }

  private static ParseBoolean(lexer: Lexer): ASTNode {
    Parser.Expect(lexer, TokenType.BOOLEAN);
    return new BooleanASTNode(lexer.ReadNext());
  }

  private static ParseString(lexer: Lexer): ASTNode {
    Parser.Expect(lexer, TokenType.STRING);
    return new StringASTNode(lexer.ReadNext());
  }

  private static ParseVariable(lexer: Lexer): ASTNode {
    /*try {
      Parser.SymbolTableLookup(lexer, SymbolTableEntryType.Variable);
    } catch (e) {
      throw new VariableNotFoundException(lexer.ReadNext().value);
    }*/

    let left: ASTNode = new VariableASTNode(lexer.ReadNext());
    this.variableStack.push(left);

    while (lexer.Peek().name == TokenType.FILTER_OP) {
      let token = lexer.ReadNext(); //Accept filter op
      let right: ASTNode = this.ParseFilter(lexer);

      //Check variable and filter for matching scopes
      this.CheckArgs(right._token.value, [left]);

      let leftSymbol = this.symbolTable.Lookup(left._token.value);
      let rightSymbol = this.symbolTable.Lookup(right._token.value);

      /* if (
        rightSymbol?._scope == SymbolTableEntryScope.Date &&
        leftSymbol?._scope !== SymbolTableEntryScope.Date
      ) {
        throw new MismatchDateScopeException(left._token.value);
      }*/
      left = new FilterBinaryOperatorASTNode(token, left, right);
    }
    return left;
  }
  private static ParseFunction(lexer: Lexer) {
    let fn = this.SymbolTableLookup(lexer, SymbolTableEntryType.Function);

    //Test for function arguments
    let func = lexer.ReadNext(); //Accept function name
    this.Expect(lexer, TokenType.PAREN_OPEN);
    lexer.ReadNext(); //Accept open paren

    //function without args
    if (lexer.Peek().name == TokenType.PAREN_CLOSE) {
      //Function doesn't have arguments, that's okay
      //like today(), accept and return function node
      lexer.ReadNext(); //Accept close paren
      return new FunctionASTNode(func);
    }

    //Function with args
    let args = this.ParseArgs(lexer, fn);

    //Check arguments to make sure scope matches
    //what's expected
    this.CheckArgs(func.value, args);

    this.Expect(lexer, TokenType.PAREN_CLOSE);
    lexer.ReadNext(); //Accept ')'

    if (lexer.Peek().name == TokenType.FILTER_OP) {
      lexer.ReadNext(); //Accept '|'
      let filter = Parser.ParseFilter(lexer);

      /*      if (
        this.symbolTable.Lookup(filter._token.value)?._scope ==
          SymbolTableEntryScope.Date &&
        fn._scope !== SymbolTableEntryScope.Date
      ) {
        throw new MismatchDateScopeException(filter._token.value);
      }*/
    }

    return new FunctionASTNode(func, args);
  }

  private static CheckArgs(name: string, args: ASTNode[]) {
    //Check that the function was provided the correct number
    //of arguments
    if (
      this.symbolTable.ArgumentCountMap(name) != args.length &&
      this.symbolTable.ArgumentCountMap(name) != undefined
    ) {
      throw new MismatchArgumentCountException(name);
    }

    let validScopes: SymbolTableEntryScope[] =
      Parser.symbolTable.ArgumentScopeMap(name);

    if (validScopes == null) {
      return;
    }
    /*Check each argument to see if it is of type identifier
     * (and *not* another function)
     * If so, confirm it is actually in the symbol table,
     * and is the correct type.*/
    args.forEach((arg) => {
      //Test for function parameter typing
      if (
        arg._token.name == TokenType.IDENTIFIER &&
        this.symbolTable.Lookup(arg._token.value)?._entryType !=
          SymbolTableEntryType.Function
      ) {
        let argScopes = Parser.symbolTable.Lookup(arg._token.value)?._scopes;
        let foundScope = false;
        for (let scope in argScopes) {
          if (validScopes.indexOf(argScopes[scope]) != -1) {
            foundScope = true;
          }
        }
        if (!foundScope) {
          Parser.ThrowMismatchScopeException(validScopes[0], arg._token.value);
        }
      }
    });
  }

  private static isArgCountEqual(
    functionSignature: FunctionSignature,
    args: ASTNode[]
  ): boolean {
    if (functionSignature.length != args.length) {
      return false;
    }
  }

  private static isParameterTypeEqual(
    functionSignature: FunctionSignature,
    args: ASTNode[]
  ): boolean {
    for (let j = 0; j < functionSignature.length; j++) {
      if (args[j]._token.name != functionSignature[j]) {
        return false;
      }
      //If a parameter is a variable, make sure the variable is in the symbol table, throwing an error if it is not
      if (args[j]._token.name == TokenType.IDENTIFIER) {
        let symbolTableEntry = Parser.symbolTable.Lookup(args[j]._token.value);
        if (symbolTableEntry == undefined) {
          throw new IdentifierNotFoundException(args[j]._token.name);
        }

        //Todo check scope?
        //let argScope = Parser.symbolTable.Lookup(args[j]._token.value)?._scope;
      }
    }
    return true;
  }

  private static ThrowMismatchScopeException(
    _scope: SymbolTableEntryScope,
    variable: string
  ) {
    switch (_scope) {
      case SymbolTableEntryScope.Date:
        throw new MismatchDateScopeException(variable);
      case SymbolTableEntryScope.Number:
        throw new MismatchNumberScopeException(variable);
      case SymbolTableEntryScope.Text:
        throw new MismatchTextScopeException(variable);
    }
  }

  private static ParseArgs(
    lexer: Lexer,
    symbolTableEntry?: SymbolTableEntry
  ): Array<ASTNode> {
    let args: Array<ASTNode> = [];

    /*if (lexer.Peek().name == TokenType.IDENTIFIER) {
      //Test for matching scope type, right now
      //limited to Date variables
      if (
        symbolTableEntry?._scope === SymbolTableEntryScope.Date &&
        this.symbolTable.Lookup(lexer.Peek().value)?._entryType ==
          SymbolTableEntryType.Variable &&
        this.symbolTable.Lookup(lexer.Peek().value)?._scope !==
          SymbolTableEntryScope.Date
      ) {
        throw new MismatchDateScopeException(lexer.Peek().value);
      }
    }*/

    //Peek the next token, it coule be a date argument and not an expression.
    //Date arguments are special and right now
    //I only see them appear in functions, cuz arg

    switch (lexer.Peek().name) {
      case TokenType.DATE_ARG: {
        args.push(new DateArgumentASTNode(lexer.ReadNext()));
        break;
      }

      case TokenType.COND_OPEN: {
        lexer.ReadNext();
        args.push(this.ParseConditional(lexer));
        this.Expect(lexer, TokenType.COND_CLOSE);
        lexer.ReadNext();
        break;
      }

      case TokenType.PAREN_OPEN: {
        /*It could also be a second function, WA-351*/
        lexer.ReadNext();
        args.push(this.ParseIdentifier(lexer));
        this.Expect(lexer, TokenType.PAREN_CLOSE);
        lexer.ReadNext();
        break;
      }

      default:
        args.push(this.ParseExpression(lexer));
    }

    while (lexer.Peek().name == TokenType.ARG_SEPARATOR) {
      lexer.ReadNext(); //Accept ','

      /*      if (lexer.Peek().name == TokenType.IDENTIFIER) {
        //Test for matching scope type, right now
        //limited to Date variables
        this.CheckArgs(lexer.Peek().name, )
        if (

          symbolTableEntry?._scope === SymbolTableEntryScope.Date &&
          this.symbolTable.Lookup(lexer.Peek().value)?._scope !==
            SymbolTableEntryScope.Date
        ) {
          throw new MismatchDateScopeException(lexer.Peek().value);
        }
      }*/

      if (lexer.Peek().name == TokenType.DATE_ARG) {
        args.push(new DateArgumentASTNode(lexer.ReadNext()));
      } else if (lexer.Peek().name == TokenType.COND_OPEN) {
        lexer.ReadNext();
        args.push(this.ParseConditional(lexer));
        this.Expect(lexer, TokenType.COND_CLOSE);
        lexer.ReadNext();
      } else {
        args.push(this.ParseExpression(lexer));
      }
    }

    return args;
  }
  private static CreateBinaryOperator(
    op: Token,
    left: ASTNode,
    right: ASTNode
  ): ASTNode {
    switch (op.name) {
      case TokenType.MATH_OP:
        this.CheckArgs(op.value, [left, right]);
        return new MathBinaryOperatorASTNode(op, left, right);

      case TokenType.COMP_OP:
        this.CheckArgs(op.value, [left, right]);
        return new ComparisonBinaryOperatorASTNode(op, left, right);

      case TokenType.LOGIC_OP:
        return new LogicBinaryOperatorASTNode(op, left, right);

      case TokenType.FILTER_OP:
        return new FilterBinaryOperatorASTNode(op, left, right);

      case TokenType.CTRL_IN:
        return new ControlBinaryOperatorASTNode(op, left, right);

      default:
        throw new UnexpectedTokenException(op.value);
    }
  }

  private static CreateUnaryOperator(op: Token, left: ASTNode): ASTNode {
    switch (op.name) {
      case TokenType.NOT:
        return new NotOperatorASTNode(op, left);

      default:
        throw new UnexpectedTokenException(op.value);
    }
  }
  private static Expect(lexer: Lexer, expected: TokenType) {
    if (lexer.Peek().name != expected) {
      throw new UnexpectedTokenException(lexer.Peek().value);
    }
  }

  //Todo: Make option so this does not take lexer as parameter
  private static SymbolTableLookup(
    lexer: Lexer,
    expected: SymbolTableEntryType
  ): SymbolTableEntry {
    let token = lexer.Peek().value;
    let symbolTableEntry = this.symbolTable.Lookup(token);
    if (symbolTableEntry?._entryType != expected) {
      throw new IdentifierNotFoundException(token);
    }
    return symbolTableEntry;
  }

  //SET: EXPRESSION
  private static ParseSet(lexer): ASTNode {
    let token = lexer.ReadNext(); //Accept "set"
    return this.ParseExpression(lexer);
  }

  //CONTROL: FOR INDENTIFIER [, IDENTIFIER] IN (IDENTIFIER | (SUBEXPRESSION)) [CONDITIONAL]
  private static ParseControl(lexer: Lexer): ASTNode {
    let token = lexer.ReadNext(); //Accept "for"
    let left: ASTNode;
    let right: ASTNode;
    let in_left: ASTNode;
    let in_right: ASTNode;

    if (lexer.Peek().name == TokenType.IDENTIFIER) {
      left = this.ParseIdentifier(lexer);
      while (lexer.Peek().name == TokenType.ARG_SEPARATOR) {
        let tok = lexer.ReadNext(); //Accept ','
        this.Expect(lexer, TokenType.IDENTIFIER);
        left = new ArgumentOperatorASTNode(
          tok,
          left,
          this.ParseVariable(lexer)
        );
      }
    }

    this.Expect(lexer, TokenType.CTRL_IN);
    let in_token = lexer.ReadNext(); //Accept "in"

    //Subexpression support inside control statement
    if (lexer.Peek().name == TokenType.PAREN_OPEN) {
      in_left = this.ParseExpression(lexer);
    } else {
      in_left = this.ParseIdentifier(lexer);
    }
    if (lexer.Peek().name != TokenType.STMT_CLOSE) {
      in_right = this.ParseConditional(lexer);
      right = new ControlOperatorASTNode(in_token, in_left, in_right);
    } else {
      right = new ControlOperatorASTNode(in_token, in_left);
    }
    return new ControlOperatorASTNode(token, left, right);
  }

  //CONDITIONAL: IF [NOT]? EXPRESSION
  private static ParseConditional(lexer: Lexer): ASTNode {
    this.Expect(lexer, TokenType.COND_IF);
    lexer.ReadNext(); //Accept "if"
    if (lexer.Peek().value == "not") {
      lexer.ReadNext(); //Accept "not"
    }
    return this.ParseExpression(lexer);
  }

  private static ValidateVariables() {
    Parser.variableStack.forEach((node) => {
      let token = node._token.value;
      console.debug(`entryType: ${this.symbolTable.Lookup(token)?._entryType}`);
      if (
        this.symbolTable.Lookup(token)?._entryType !=
        SymbolTableEntryType.Variable
      ) {
        throw new IdentifierNotFoundException(token);
      }
    });
  }

  private static IllegalCharCheckForStatement(node: ASTNode) {
    /*Traverse the tree and
    look for string nodes that contain illegal
    characters
     */
    if (node.hasLeft()) {
      this.IllegalCharCheckForStatement((<UnaryOperatorASTNode>node).Left);
    }

    if (node.hasRight()) {
      this.IllegalCharCheckForStatement((<BinaryOperatorASTNode>node).Right);
    }
    if (node._token.name == "STRING") {
      if (node._token.value.indexOf("%") != -1) {
        throw new PercentSignInLogicException();
      }
    }
  }

  private static IllegalCharCheckForExpression(node: ASTNode) {
    /*Traverse the tree and
    look for string nodes that contain illegal
    characters
     */
    if (node.hasLeft()) {
      this.IllegalCharCheckForExpression((<UnaryOperatorASTNode>node).Left);
    }

    if (node.hasRight()) {
      this.IllegalCharCheckForExpression((<BinaryOperatorASTNode>node).Right);
    }
    if (node._token.name == "STRING") {
      let reg_newline = RegExp("\v");
      if (reg_newline.test(node._token.value)) {
        throw new NewlineInLogicException();
      }
    }
  }
}
