import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoginResult } from "../login/LoginResult";
import { FetchResult } from "./FetchResult";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  constructor(private __httpClient: HttpClient) {}

  async Login(path: string, apiKey: string): Promise<FetchResult> {
    const url: string = path + "/api/playground?folder=questions";
    const requestHeaders = new HttpHeaders({ "X-API-KEY": apiKey });
    let loginResult = new FetchResult();

    await this.__httpClient
      .get<string[]>(url, { headers: requestHeaders, observe: "response" })
      .toPromise()
      .then(
        (httpResponse: HttpResponse<string[]>) => {
          let response = httpResponse.status;

          if (response == 200) {
            loginResult.isError = false;
            loginResult.value = <string[]>httpResponse.body;
          } else {
            loginResult.isError = true;
            loginResult.errorDescription = `${httpResponse.status} ${httpResponse.statusText}`;
          }
        },
        (httpResponse: HttpResponse<string[]>) => {
          let response = httpResponse.status;

          loginResult.isError = true;
          loginResult.errorDescription = `${httpResponse.status} ${httpResponse.statusText}`;
        }
      );
    if (!loginResult?.isError) {
      return Promise.resolve(loginResult);
    }
    return Promise.reject(loginResult);
  }
}
