import { SimpleFormattingOptions } from "../SimpleFormattingOptions";
import { SimpleVariable } from "./SimpleVariable";
import { VariableDataType } from "./VariableType";

export class FileObjectVariable extends SimpleVariable {
  public simpleFormatOptions: SimpleFormattingOptions[] = [
    {
      code: "default",
      text: "Standard",
    },
  ];

  label: string;
  constructor(handle: string, display: string, label: string, parent?: string) {
    super(handle, display, VariableDataType.Object_from_file, null, parent);
    this.label = label;
  }
}
